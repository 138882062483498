import React, { useEffect } from 'react'
import Selectors from './components/Selectors'
import EggsWarehouseTable from './components/EggsWarehouseTable'
import useEggsWarehouseStore from './store/eggs_warehouse.store'
import PageMeta from '../../components/PageMeta'
import { setBreadCrumbsStore } from '../../components/breadcrumbs/store/breadcrumb.store'
import { eggsWarehouseBreadCrumb, homeBreadCrumb } from '../../components/breadcrumbs/data/breadcrumbs.data'
import EggsWarehouseFilters from './components/EggsWarehouseFilters'

export default function EggsWarehouse() {

  
  const counts = useEggsWarehouseStore(state => state.formatted)
  const onSubmit = useEggsWarehouseStore(state => state.setSubmit)
  const clear = useEggsWarehouseStore(state => state.clearFilter)
  
  const { projects_ids , departments_ids , homes_ids } = counts



  useEffect(()=>{
    setBreadCrumbsStore([
        homeBreadCrumb,
        eggsWarehouseBreadCrumb,
    ])
} , [])


  return (
    <div className='h-full'>
      <PageMeta title={"مستودع البيض"} />

      <div className="page">
        <EggsWarehouseFilters />
      </div>

     <div className="flex-1">
      <EggsWarehouseTable />
     </div>
     {/* <div className="min-w-[300px]  px-2  bg-white max-h-full overflow-y-auto hide-scrollbar relative py-4">
        <div className="text-center">
          <h3 className='text-[#fcaa30] text-2xl '>البيانات المستخدمة</h3>
          <hr />
        </div>
        <Selectors />
      <div className="bg-gradient-to-t flex  from-[#fff] justify-center  to-[#fff]/80  absolute bottom-0 w-full ">
        <button 
          onClick={onSubmit}
          className=' shadow-lg bg-[#40a9f4] text-white text-center justify-center  
          flex items-center px-2 py-2 w-[120px] text-[14px]  rounded-md  my-3 ml-3'>
          اختر البيانات
          ({projects_ids.length + departments_ids.length + homes_ids.length})
        </button>
        <button 
          onClick={clear}
          className=' shadow-lg bg-[#e46960] text-white text-center justify-center   ml-3
          flex items-center px-2 py-2 w-[120px] text-[14px]  rounded-md  my-3'>
              احذف الاختيارات
        </button>

      </div>
     </div> */}
    </div>
  )
}
