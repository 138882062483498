import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { _date, PageMeta, QuickView, Signboard, TableHeader, useFetch } from "../../../../config/imports";
import ReportsCrud from "../reports_crud";
import { reports_api } from "../../../../config/apis";
import { Table } from "../../../../layout/table";
import Helpers from "../../../../helpers/helpers";

export default function WeightDailyReportForProductionHome(props){

    const  createRef = useRef()

    const {id} = useParams();

    const api = reports_api.home.weights.read(id)

    const  { data  ,  alert , paginate , setters , setRefresh }  = useFetch(api);

    const table = [
        {name : "#"  , text : "#" , size : 1  },
        {name : "date"  , text : "تاريخ الاضاءة" , size : 4 , custom : d => _date(d?.date).dmy() },
        {name : "bird_age"  , text : "عمر الطائر" , size : 4  },
        {name : "average_weight"  , text : "متوسط الوزن" , size : 4 , custom : (d) => d.average_weight?.toFixed(2) },
        {name : "strain_average_weight"  , text : "متوسط الوزن بالكتالوج" , size : 4 , custom : (d) => d.strain_average_weight?.toFixed(2)  },
        {name : "samples"  , text : "عدد العينات" , size : 4 , custom : (d) => d.samples?.length },
    ]

    const buttons =  [
        {
            title: " اضافة تقرير اوزان  جديد",
            onclick: () => createRef.current.toggle()
        }
    ]


    return(
        <div className="report-container">
            <PageMeta title={'تقارير الاضاءة اليوميه'} />
            <Signboard {...alert}/>
            <QuickView ref={createRef}><ReportsCrud.Weights.Create   setRefresh={setRefresh} /></QuickView>
            <TableHeader  buttons={buttons}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />
            <Table data={data} table={table}  />
        </div>
    )
}
