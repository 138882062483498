import { useEffect, useState } from "react";
import { _date, createFormHandler, EditForm, InputDate, InputText, InputTextArea, PageMeta, setInputsToDefault, Signboard } from "../../../config/imports";
import { apis } from "../../../config/apis";
import TotalPriceInput from "../shared/TotalPriceInput";
import OperatorsInput from "../shared/OperatorsInput";
import InvoiceId from "../shared/InvoiceId";
import useInvoicesApi from "./useInvoicesApi";
import InputTax from "../shared/InputTax";
import InputFeedGroup from "../shared/InputFeedGroup";
import useInvoiceItemsStore from "../stores/invoice-items.store";
import InputEnableTax from "../components/InputEnableTax";
export default function BuyWarehouseFeedInvoice(props){

    const resetItems = useInvoiceItemsStore(state => state.resetItems)

    useEffect(() => {
        resetItems()
    }, [])
    
    const { project , department, setMainData, toggle , setRefresh, invoiceFor, stockName , stockId } = props

    // const [Alert , setters]= useAlert()


    const [data , setData] = useState({
        // home_id,
        invNumber : "",
        date : new Date().toISOString(),
        total_price : 0,
        isChecked : false,
        total_quantity_from_homes : 0,
        project,
        department,
        stock : "",
        stock_quantity : "",
        invoice_type : "stock",
        orders : [],
        enable_tax : true
    })

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    // const project = '64fd0c1953b4852b68cd00c6'
    // const department = '64fd0c1953b4852b68cd00c8'

    // const { data : formData  , error : formError } =  apis.invoices.form.data( 'type=buy&invoice=feed&place=project' )
    const {  data : formData , error : formError , Alert , setters , OrdersElement  } = useInvoicesApi( 
        { type : "buy" , invoice : "feed" , place : "stock" , place_id : stockId } ,
        { data , setData}
    )


  


    useEffect(()=>{

        if(formData){
            setData((prev) => ({
                ...prev,
                stock : formData?.storage_info?.location_id
            }))
        }

    } , [formData])
   
  
    useEffect(()=>{
            if(error || success){
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 2000);
            }
    } , [error , success ])


    const signboard = { loading, error, success, }




    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));

    };


    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: apis.invoices.buy.feed.create(),
            data : {
                ...data ,
                stock : stockId ?? formData?.storage_info?.location_id,
                stock_quantity : data?.quantity,
                items : useInvoiceItemsStore.getState().getItems(),
                quantity : useInvoiceItemsStore.getState().getTotalQuantity()
            } ,
            setLoading,
            setError,
            setSuccess,
            setRefresh,
            setMainData ,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };
  
        // console.log( create_props.data)
        await createFormHandler(create_props);
    };

    return(
        <div className={''}>
            <PageMeta title={'فاتورة شراء اعلاف'} originTitle={'ادارة الفواتير'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>فاتورة شراء اعلاف لمستودع</h3>
            </header>

            <Signboard {...signboard}/>
            <Alert />
            <EditForm
                visible={true}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>

                <InvoiceId setData={setData} type={"feedBuyInv"}   className="span-5" value={formData?.inv_number} />

                <InputText name="invoice_number" className="span-5"   title=" رقم الفاتورة" defaultValue={data.invoice_number} handleInputChange={handleInputChange} />

                <OperatorsInput className={'col-4'} data={data} setData={setData} />

                <InputDate className="col-3"  name="date" label="تاريخ الفاتورة" defaultValue={_date(data.date).forInputDate()} handleInputChange={handleInputChange} />

                <InputFeedGroup data={data} setData={setData} />

                <OrdersElement defaultValue={data.orders}  />
                
                <InputText name={"stock"}  title={"اسم المستودع"} defaultValue={stockName ?? "المستودع الخاص بالمشروع"}  disabled={true} className={"col-4"} />
               
                {/* <>
                    <div className={"stick-error-to-input bb-2  px-0 col-4  " + (error === "الكميات غير متطابقه" ? 'input-has-error ' : '')}>
                            <InputNumber name="stock_quantity"  handleInputChange={handleInputChange}   title={"الكميه"} defaultValue={data.stock_quantity}   className={"w-100"}  />
                            <small className="text-danger px-0 py-0 error-output">{
                             data.quantity && data.stock_quantity && ( ( data.stock_quantity ?? 0) !==  (data.quantity ?? 0)) ?  "الكميات غير متطابقه" : ""
                                }
                            </small>
                        </div>
                </> */}
               
                <TotalPriceInput className="col-2" data={data} setData={setData} />

                <div className="col-2">
                    <InputEnableTax data={data} setData={setData}   />
                </div>


                <InputTax className="col-4"  data={data} setData={setData} />

                <InputTextArea name="more_info" title="ملاحظات" value={data.more_info} handleInputChange={handleInputChange} />

            </EditForm>

        </div>
    )
}
