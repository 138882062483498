import { MdOutlineMinimize } from "react-icons/md";
import { MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import React, { Component } from "react";
import "../styles/modal.styles.scss";
import { cn } from "../helpers/class_name.helpers";

class QuickView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: false,
      item: {},
      maximized: false,
    };
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const isPopup = event.target.closest(".form-modal")? true : false;
    const isRendered =  document.querySelector(".form-modal[data-rendered='true']") ? true : false;
    if(!isPopup  && isRendered ) return this.setState({ state: false });
  };


  

  select = (item) => {
    this.setState({ item });
  };

  toggle = (item) => {
    if (item) this.select(item);
    this.setState({ state: !this.state.state });
  };

  render() {


    return (
      this.state.state && (
        <div className={"modal-container " + (this.props.className ?? "")} ref={(node) => (this.modalContainerRef = node)}>
          <div className={"form-modal p-3 row " + (this.props.modalClassName ?? "")} data-rendered={this.state.state ?? false} >
            <div className={cn(
              "modal-container-content transition-[width] duration-500 ease-in-out"  + (this.props.sized ? "sized-height" : "")  + (this.props.contentClassName ?? "") ,
               this.state.maximized &&  "!w-[90vw]"
            )}>
              <div className="absolute top-0 right-4 left-4 h-[40px] z-[100000]  bg-gray-50 rounded-t-[25px] border-b-[1px] border-gray-100  flex justify-start items-center  px-4 mb-2">
                  <button
                  onClick={() => this.setState({ state: false })}
                  className="hover:bg-red-500 hover:text-white hover:fill-white group w-[30px] h-[30px] flex items-center justify-center rounded-md">
                    <AiOutlineClose className="group-hover:fill-white" />
                  </button>
                  <button
                  onClick={() => this.setState({ maximized: true })}
                   className="hover:bg-lime-500 w-[30px] h-[30px] flex items-center group justify-center rounded-md">
                     <MdOutlineCheckBoxOutlineBlank className="group-hover:fill-white fill-gray-500" />
                  </button>
                  <button
                  onClick={() => this.setState({ maximized: false })}
                   className="hover:bg-amber-500 w-[30px] h-[30px] flex items-center group justify-center rounded-md">
                      <MdOutlineMinimize className="group-hover:fill-white fill-gray-500" />  
                  </button>
              </div>
              <div className={cn(
                "modal-component ",
                // this.state.maximized && "w-[100%]"
              )}>
                {React.cloneElement(this.props.children, {
                  toggle: this.toggle,
                  item: this.state.item && this.state.item,
                })}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default QuickView;
