import React, { useEffect, useRef, useState } from 'react'
import PageMeta from '../../../components/PageMeta'
import useStock from '../useStock'
import { _date } from '../../../helpers/date.helpers'
import { Table } from "../../../layout/table"

import QuickView from '../../../components/QuickView'
import MovementDetails from '../forms/MovementDetails'
import { apis } from '../../../config/apis'
import { accio } from '../../../helpers/axios/axios.get'
import { useParams } from 'react-router-dom'
import Pagination from '../../../layout/pagination/Pagination'
import { setStorePagesCount, setStoreTotal } from '../../../stores/paginate.store'
import Signboard from '../../../components/Signboard'
export default function DetailsTab() {

  const stockId = useParams().id

  const [paginate, setPaginate] = useState({})
  const [loading, setLoading] = useState(false)
  let [api, setApi] = useState(apis.storages.base() + "/movements/paginate-copy?location_id=" + stockId + "&location=stock")
  const [transfers, setTransfers] = useState([])

  const detailsRef = useRef()

  const { categories, CategoriesFilter, query } = useStock({
    location: "stock",
    category: "",
  })

  useEffect(() => {
    let _api = api.split("&category=")[0]
    setApi(_api + "&category=" + query.category)
  }, [query])



  const table = [
    { name: "#", text: "", size: 1 },
    { name: "ar_type", text: "نوع الحركه", size: 2 },
    { name: "from", text: "من", size: 4 },
    { name: "to", text: "الي", size: 3 },
    // { name: "category", text: "الصنف", size: 3, custom: d => categories[d?.category] },
    { name: "sub_category", text: "الفئة", size: 2 },
    { name: "quantity", text: "الكمية", size: 3 },
    { name: "ar_unit", text: "الوحده", size: 2 },
    { name: "price", text: "السعر الاجمالي", size: 3 },
    { name: "date", text: "التاريخ", size: 3, custom: d => <span style={{ fontSize: "13px" }}> {_date(d?.date).dmy()} </span> },
    // {
    //   name: "movements", text: "تفاصيل", size: 2, custom: d => {
    //     return <button onClick={() => detailsRef.current?.toggle(d)}><i className="fa fa-eye " /></button>
    //   }
    // },
  ]



  useEffect(() => {

    const fetch = async () => {
      setLoading(true)
      const response = await accio(api)
      setLoading(false)
      if (response.data) {
        setTransfers(response.data)
        setPaginate(response.paginate)
        setStorePagesCount(paginate?.pages)
        setStoreTotal(paginate?.total)
      }
      else {
        console.log(response.error);
      }
    }

    fetch()
  }, [api])




  return (
    <>
      <div className='page base-card relative'>
        {<QuickView ref={detailsRef} className="overflown-popup"><MovementDetails categories={categories} /></QuickView>}
        <Signboard loading={loading} />
        <PageMeta title="تفاصيل المستودع " />
        <div className="row align-items-center">
          <div className="col-9">
            <h3> التحركات   </h3>
          </div>
          <div className="col-3">
            <CategoriesFilter />
          </div>
        </div>
        {!loading && <div className="absolute left-1/2 -translate-x-1/2 top-0">
          <Pagination paginate={paginate} api={api + "&"} setData={setTransfers} setLoading={setLoading} />
        </div>}
        <Table data={transfers} table={table} />
      </div>
    </>
  )
}
