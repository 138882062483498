import React from 'react'
import useEggsWarehouseFilterStore from '../store/eggs-warehouse-filter.store'
import InputProjects from '../../../shared/components/projects/InputProjects'
import InputDepartments from '../../../shared/components/departments/InputDepartments'
import InputHomes from '../../../shared/components/homes/InputHomes'

export default function EggsWarehouseFilters() {


    const {
        setProjectsIds,
        setDepartmentsIds,
        setHomesIds,
        clear,
        filter,
        setSubmit,
        submit
    }  = useEggsWarehouseFilterStore()

    const onApply = async () => {
      setSubmit()
    }

    const onClear = async () => {
      clear()
      setSubmit()
    }



  return (
      <div className='h-fit py-2 flex justify-between'>
           <div className="flex items-center gap-2">
             <div className="w-fit">
                <InputProjects   onChange={(data) => setProjectsIds(data) } />
               </div> 
             <div className="w-fit">
                <InputDepartments   onChange={(data) => setDepartmentsIds(data) } />
               </div> 
             <div className="w-fit">
                <InputHomes   onChange={(data) => setHomesIds(data) } />
               </div> 
           </div>


           <div className="flex items-center justify-end gap-2">
              <button onClick={onApply} className='add-btn'>
                  تاكيد
              </button>
              <button onClick={onClear}  className='border p-2  rounded-md px-3'>
                  اعادة تعيين
              </button>


           </div>
       </div>
  )
}
