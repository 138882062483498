import { BiCalendar } from "react-icons/bi"; 
import { AiOutlineClear } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";
import { BiCheck } from "react-icons/bi";
import { BiChevronDown } from "react-icons/bi";
import { HiCollection } from "react-icons/hi";
import { useEffect, useState } from 'react';
import { cn } from "../../../helpers/class_name.helpers";
import { _date } from "../../../helpers/date.helpers";


function getNextYearDate() {
  const today = new Date();
  return new Date(today.setFullYear(today.getFullYear() + 1)).toISOString();

}
export default function InputDateRange({ onChange = () => {} }) {


  const [state , setState] = useState(false)

  const [startDate , setStartDate] = useState(new Date().toISOString())

  const [endDate , setEndDate] = useState(getNextYearDate())




  

  useEffect(() => {
    const handler = (e) => {
      if(e.target.closest('[data-keep-input-date-open]')) return
      setState(false)
    }

    document.addEventListener('click' , handler)

    return () => {
      document.removeEventListener('click' , handler)
    }
  }, [])




  useEffect(() => {
    onChange({
      start_year :startDate,
      end_year :endDate
    })
  }, [startDate , endDate])





  return <div className='w-full relative'>

      <header>
        <button data-keep-input-date-open  onClick={() => setState(!state)} 
         className={cn(
          'w-full justify-center  whitespace-nowrap bg-[#fcaa30] text-white  px-3 h-[40px] flex items-center gap-2 rounded-md',
          state && 'bg-[#ce8b28] text-white'
         )}>
          <div className="flex items-center text-white gap-1">
            {/* <HiCollection className="text-white fill-white text-[24px]" /> */}
            <BiCalendar className="text-white fill-white text-[24px]" />
               حدد المده &nbsp;
          </div>
           <BiChevronDown className={cn(
            "text-white fill-white text-[24px]",
            state && 'rotate-180'
           )}/>

        </button>
      </header>




      <div data-keep-input-date-open  className={cn(
        "absolute w-[300px]  top-[40px] left-0  shadow-md rounded-md  z-50 bg-white  overflow-hidden",
        "py-3 flex flex-col",
        state ? 'max-h-[500px]' : 'h-0 p-0',
      )}>

        <header className="px-2 flex justify-between items-center mb-2">
          <h3 className="text-[18px] font-[500] text-gray-500 mb-0">
            حدد المده
          </h3>

          <button 
           onClick={()=>{}}
           className="flex items-center gap-1 hover:text-red-500 group">
              حذف
              <AiOutlineClear className="text-[24px] fill-slate-500 group-hover:fill-red-500" />
              
              
          </button>
        </header>

        <div className="flex flex-col gap-2 px-2  overflow-y-scroll hide-scrollbar flex-1">
           <div className="mt-2">
            <label htmlFor="startDate">تاريخ البداية</label>
            <input type="date"
            className=" focus:outline-[1px] focus:outline-slate-200  focus:border-[1px] focus:border-border  border-[1px] border-border"
            name="startDate" value={_date(startDate).forInputDate()} onChange={(e) => setStartDate(e.target.value)} /> 
           </div>
           <div className="mb-2">
            <label htmlFor="endDate">تاريخ النهاية</label>
            <input type="date"
            className=" focus:outline-[1px] focus:outline-slate-200  focus:border-[1px] focus:border-border  border-[1px] border-border"
            name="endDate" value={_date(endDate).forInputDate()} onChange={(e) => setEndDate(e.target.value)} /> 
           </div>
        </div>

      </div>


  </div>




}

