import { useEffect } from 'react';
import useTargetsStore from '../../targets/stores/targets.store';
import useTargetFilterStore from '../../targets/stores/targets-filter.store';
import useBreadCrumb from '../../../components/breadcrumbs/hooks/breadcrumb.hook';
import { setProjectsBreadCrumbs } from '../../../components/breadcrumbs/store/breadcrumbs.default';
import useMeta from '../../../hooks/useMeta';
import { useParams } from 'react-router-dom';


export default function useProjectTargets() {

  const {id} = useParams()
  
  const fetch = useTargetsStore((state) => state.fetch)

  const filter = useTargetFilterStore((state) => state.filter)
  const setProjectsIds = useTargetFilterStore((state) => state.setProjectsIds)

  const {data}  = useBreadCrumb({id , name : "project"})


  
  useEffect(() => {
    if(!data || !data.length) return
    setProjectsBreadCrumbs.departments(...data)
  } , [data])


 

  useEffect(() => {
    
    if(filter.projects_ids.length === 0 && filter.departments_ids.length === 0  && filter.homes_ids.length === 0) {
      setProjectsIds([id])
  
      filter.homes_ids = []
      filter.departments_ids = []
      filter.projects_ids = [id]
      fetch(filter)
    }else if( filter.departments_ids.length > 0  || filter.homes_ids.length > 0) {
      filter.projects_ids = []
      fetch(filter)
    }
    
  } , [filter , id])


  useMeta({
    title : 'المُستهدف'
  })

}
