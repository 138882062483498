import React from 'react'
import PageMeta from '../../components/PageMeta'
import useTargets from './hooks/useTargets'
import TargetsFilters from './components/TargetsFilters'
import useTargetsStore from './stores/targets.store'
import TargetsTable from './components/TargetsTable'

export default function Targets() {

  useTargets()

  return (
   <>
   
   <div className='page'>
      <PageMeta title='المُستهدف' />
      <TargetsFilters />
    </div>

    <div className='page mt-3 p-4'>
        <TargetsTable />
    </div>


   </>
  )
}
