// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* input {
  outline: 1px solid #aaa;
  border: none;
  color: #777;
}
input:focus {
  border: none;
  outline: 3px solid #aaaaaa54;
}
input::placeholder {
  font-size: 14px;
  color: #777;
} */
`, "",{"version":3,"sources":["webpack://./src/libs/select/select.styles.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;GAYG","sourcesContent":["/* input {\n  outline: 1px solid #aaa;\n  border: none;\n  color: #777;\n}\ninput:focus {\n  border: none;\n  outline: 3px solid #aaaaaa54;\n}\ninput::placeholder {\n  font-size: 14px;\n  color: #777;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
