import React, { forwardRef } from "react";
import QuickView from "./QuickView";

const QuickViewWithOverFlow = forwardRef(({ children }, ref) => {
  return (
    <QuickView
      ref={ref}
      contentClassName="!overflow-visible !overflow-y-visible"
      className="!overflow-visible !overflow-y-visible"
      modalClassName="!overflow-visible !overflow-y-visible">
      {children}
    </QuickView>
  );
});

export default QuickViewWithOverFlow

