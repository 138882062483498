import React, { useMemo, useState } from 'react'
import useUsersData from '../../users/hooks/useUsersData'
import { InputSelect, useFetch } from '../../../config/imports'
import { apis } from '../../../config/apis'
import { allOption } from '../shared.approvals'
import { useSearchParams } from 'react-router-dom'

export default function ProjectsFilter(props) {
    const [state , setState] = useState(undefined)
    const {data : projects} = useFetch(apis.projects.names)

    const [searchParams  , setSearchParams] = useSearchParams()
    const project = searchParams.get("project")
 

    const options = useMemo(() => {
        if(!projects) return []
        return [allOption , ...projects.map(d => ({value : d?._id , text : d?.name}))]
    }, [projects])

    const handleInputChange = (e) => {
        const { value} = e.target;
        // setState(value)

        // if(props?.setQuery) return props.setQuery(pre => ({...pre , project : value}))

        if(props.setQuery) return props.setQuery(value)

        // setSearchParams({ ...searchParams , project : value })
   
    }



  return (

    <InputSelect  className="w-full" options={options}  title="اختر المشروع" handleInputChange={handleInputChange}  defaultValue={project}  />

  )
}
