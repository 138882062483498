import { BsFillStarFill } from "react-icons/bs"; 
import React from 'react';
import useCollectionCenters from './hooks/useCollectionCenters';
import AddCollectionCenterButton from './components/AddCollectionCenterButton';
import CollectionCenters from './components/CollectionCenters';

export default function CollectionCenter() {

  useCollectionCenters()

  return (
    <div className='page h-screen'>
      <div className="mb-4">
        <header>
          <h1 className='text-nowrap flex items-center gap-2 text-[18px]'>
            <BsFillStarFill className="text-[#fcaa30] fill-[#fcaa30]" />
            <span>المُفضله</span>
          </h1>
        </header>
      <div className='flex gap-2 flex-wrap '>    
          <CollectionCenters isFavorite />
      </div>

      </div>

      <div>
        <header>
          <h1 className='text-nowrap flex items-center gap-2 text-[18px]'>
            <img src="/icos/category.png" alt="category icon"/>
            <span>كل نقاط التجميع</span>
          </h1>
        </header>
        <div className='flex gap-2 flex-wrap '>
          <AddCollectionCenterButton />
          <CollectionCenters />
        </div>
      </div>


    </div>
  )
}
