import { useMemo } from "react";
import { InputSelect, useFetch } from "../../../config/imports";
import { operators_api } from "../../../config/apis";
import InputOperator from "../../../shared/components/operator/InputOperator";
import { cn } from "../../../helpers/class_name.helpers";

export default function OperatorsInput(props) {
  const { data, setData, className, defaultValue } = props;

//   const { data: operators } = useFetch(operators_api.names);

//   const operatorsOptions = useMemo(() => {
//     if (!operators) return []; // renders nothing if data is not available
//     return operators.map((operator) => ({
//       _id: operator._id,
//       name: operator.name,
//     }));
//   }, [operators]);

  const handleInputChange = (e) => {
    setData({ ...data, operator: e.target.value });
  };

  return (
    <div className={cn(
        className,

    )}>
      {/* <InputSelect
        className={className ?? "col-3"}
        handleInputChange={handleInputChange}
        defaultValue={defaultValue}
        name={"operator"}
        options={operatorsOptions}
        title={"المورد"}
      /> */}

      <InputOperator className={"w-full col-12 p-0"} handleInputChange={handleInputChange}   />
    </div>
  );
}
