import React from "react";
import { operatorAPI, originAPI } from "../origin.helpers";
import { Icons, useFetch, QuickView, TableHeader, useRef, PageMeta, Signboard } from "../../../config/imports";
import OriginFeedCrud from "./feed_crud";
import Gadwal from "../../../shared/components/gadwal/Gadwal";
import QuickViewWithOverFlow from "../../../components/QuickViewWithOverFlow";




const Feed = () => {

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();



    const { data: feed,error,isLoading, setData: setFeedData, } = useFetch(originAPI.feed);
    const { data: operators  , isLoading :  operatorLoading , error : operatorError} = useFetch(operatorAPI.url);

    const signboard =  {
        error : error || operatorError,
        loading : isLoading || operatorLoading
    }


    const table = [
        {  size : 1 , name : "#" , header : "#" , custom : (d , idx) => idx},
        {  size : 5 , name : "name" , header : "الاسم"  },
        {  size : 3 , name  : "ar_unit"  , header : "وحدة القياس" },
        {  size : 6 , name : "operator" , header : "المورد"  , custom : d => d.operator?.name ?? 'بدون'  },
        {  size : 5 , name : "info" , header : "التفاصيل"  , custom : d => d.info || "-" },
        {  size : 2 , name : "" , header : "" , custom : d => {
            return <div className="flex items-center gap-2">
                       <button  onClick={()=> updateRef.current.toggle(d)}><Icons.Edit /></button>
                       <button  onClick={()=> deleteRef.current.toggle(d)}><Icons.Delete /></button>
            </div>
        }}
    ]


return (
    <>

        <QuickViewWithOverFlow ref={createRef}><OriginFeedCrud.Create  operators={operators}  setMainData={setFeedData}/></QuickViewWithOverFlow>
        <QuickView ref={updateRef}><OriginFeedCrud.Update  operators={operators} setMainData={setFeedData}/></QuickView>
        <QuickView ref={deleteRef}><OriginFeedCrud.Delete  operators={operators} setMainData={setFeedData}/></QuickView>

        <Signboard {...signboard}  />
        <div className=' mt-5 bg-white r-16 p-2'  >
            <PageMeta title={'تقارير العلف اليوميه'} />
            {/*<Signboard {...signboard}/>*/}
            <TableHeader  buttons={ [
                {
                    title: " اضافة نوع علف جديد",
                    onclick: () => createRef.current.toggle()
                }
            ]}/>


            <div className="" style={{marginTop: '100px'}}></div>
   


            <Gadwal table={table} data={feed} />




        </div>

    </>
)

};

export default Feed;
