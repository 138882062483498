import { accio } from "../helpers/axios/axios.get";
import { paths } from "./configs.env";

const BASE_API = paths.api;

const STOCK_BASE_API = BASE_API + "/stock";

const OPERATORS_BASE_API = BASE_API + "/operator";

const FEED_TYPES_BASE_API = BASE_API + "/origin/feed";

const INVOICES_BASE_API = BASE_API + "/invoice";

const PROJECTS_BASE_API = BASE_API + "/projects";

const DEPARTMENTS_BASE_API = BASE_API + "/departments";

const BASE_AUTH_API = BASE_API + "/authentication";

const ROLE_BASE_API = BASE_API + "/authorization";

const USERS_BASE_API = BASE_API + "/users";

const AUTH_BASE_API = BASE_API + "/authentication";

const EMPLOYEES_BASE_API = BASE_API + "/employees";

const REQUEST_BASE_API = BASE_API + "/requests";

const HOMES_BASE_API = BASE_API + "/homes";

const WEIGHTS_BASE_API = BASE_API + "/origin/egg-weight";

const PACKAGING_BASE_API = BASE_API + "/package";

const PRICES_BASE_API = BASE_API + "/price";

const REPORTS_BASE_API = BASE_API + "/Report";

const STRAIN_BASE_API = BASE_API + "/strain";

const strain_api = {
  base: STRAIN_BASE_API,
  custom: (route) => strain_api.base + "/" + route,

  classifications: (id) => strain_api.base + "/classification/" + id,

  breeding: {
    create: () => strain_api.base,
    read: () => strain_api.base,
    update: (id) => strain_api.base + "/" + id,
  },
  production: {
    update: (id) => strain_api.base + "/" + id,
  },
};

const stock_api = {
  base: STOCK_BASE_API,
  update: (id) => stock_api.base + "/" + id,
  custom: (route) => stock_api.base + "/" + route,
  delete: () => stock_api.base,
  toggleFavorite: () => stock_api.base + "/stock/toggle/favorite",
  color: () => stock_api.base + "/stock/color",
  stockNames: () => stock_api.base + "/stock-names"
};


const operators_api = {
  base: OPERATORS_BASE_API,
  names: OPERATORS_BASE_API + "/selected",
  custom: (route) => operators_api.base + "/" + route,
};

const feed_types_api = {
  base: FEED_TYPES_BASE_API,
  custom: (route) => feed_types_api.base + "/" + route,
};

const invoices_api = {
  base: INVOICES_BASE_API,
  invoices: () => invoices_api.base + "/landing",
  warehouse_buy_feed: INVOICES_BASE_API + "/buy-feed",
  warehouse_buy_drugs: INVOICES_BASE_API + "/buy-drug",
  warehouse_maintenance: INVOICES_BASE_API + "/maintenance",
  warehouse_others: INVOICES_BASE_API + "/other",

  paginate: () => invoices_api.base + "/landing/paginate",
  sell: {
    feed: () => invoices_api.base + "/feedbuy",
    drug: () => invoices_api.base + "/drug",
    maintenance: () => invoices_api.base + "/maintenance",
    others: () => invoices_api.base + "/other",
  },
  buy: {
    egg: INVOICES_BASE_API + "/buy-egg",
    form: () => invoices_api.base + "/egginv/form",
    create: () => invoices_api.base + "/egginv/",
  },

  project: {
    feed: () => invoices_api.base + "/feedbuy",
  },
  custom: (route) => invoices_api.base + "/" + route,
};

const projects_api = {
  base: PROJECTS_BASE_API,
  read: PROJECTS_BASE_API + "/project",
  names: PROJECTS_BASE_API + "/project/names",
  details: PROJECTS_BASE_API + "/project/get/system_info",
  create: PROJECTS_BASE_API + "/project/create",
  departments: (id) => PROJECTS_BASE_API + "/departments/" + id,
  setStatus: PROJECTS_BASE_API + "/project/set/system_info",
  custom: (route) => projects_api.base + "/" + route,
  toggleFavorite: () => projects_api.read + "/toggle/favorite",
  color: () => projects_api.read + "/color",
  delete: () => projects_api.read,
  update: (id) => projects_api.read + "/" + id,
  boxes: (id) => projects_api.base + "/landingPage/" + id,
};

const departments_api = {
  base: DEPARTMENTS_BASE_API,
  details: (id) => DEPARTMENTS_BASE_API + "/department/" + id,
  homes: (id) => DEPARTMENTS_BASE_API + "/home/" + id,
  feed_invoices: {
    read: (id) => DEPARTMENTS_BASE_API + "/feedinv/paginate/" + id,
    create: (id) => DEPARTMENTS_BASE_API + "/feedinv/" + id,
    update: (id) => DEPARTMENTS_BASE_API + "/feedinv/" + id,
    delete: (id) => DEPARTMENTS_BASE_API + "/feedinv/"

  },
  custom: (route) => departments_api.base + "/" + route,
  reports: {
    eggs: {
      init: (id) => REPORTS_BASE_API + "/egg/dailyReport/department/" + id,
      form: (id) => REPORTS_BASE_API + "/egg/dailyReport/department/form/" + id,
    },
  },
  stats: {
    green_boxes: (id) => DEPARTMENTS_BASE_API + "/green-box/" + id,
    years: (id) => DEPARTMENTS_BASE_API + "/years-balance/" + id,
    important: (id) => DEPARTMENTS_BASE_API + "/levels-balance/" + id,
  },
};

const roles_api = {
  base: ROLE_BASE_API,
  role: ROLE_BASE_API + "/roles",
  create_role: ROLE_BASE_API + "/permission/create-role",
  delete: (id) => ROLE_BASE_API + "/role/" + id,
  update: (id) => ROLE_BASE_API + "/role/" + id,
  custom: (route) => roles_api.base + "/" + route,
};

const users_api = {
  base: USERS_BASE_API,
  create: USERS_BASE_API + "/create",
  users: USERS_BASE_API + "/user",
  delete: USERS_BASE_API + "/user/delete",
  update: USERS_BASE_API + "/user/info",
  active_status: USERS_BASE_API + "/user/activation",
  rest_password: USERS_BASE_API + "/user/password",
  role: USERS_BASE_API + "/roles",
  enable: (id) => USERS_BASE_API + "/user/enable/" + id,
  disable: (id) => USERS_BASE_API + "/user/disable/" + id,
  custom: (route) => users_api.base + "/" + route,
};
const auth_api = {
  base: AUTH_BASE_API,
  login: BASE_AUTH_API + "/login",
  signup: BASE_AUTH_API + "/register",
  edit_password: BASE_AUTH_API + "/users/password",
  custom: (route) => auth_api.base + "/" + route,
};
const employees_api = {
  base: EMPLOYEES_BASE_API,
  projects: EMPLOYEES_BASE_API + "/project/names",
  custom: (route) => employees_api.base + "/" + route,
  create: () => employees_api.base + "/employee",
  extraWork: () => employees_api.base + "/extra-work",
  vacation: () => employees_api.base + "/vacation",
  jobAssignment: () => employees_api.base + "/job-assignment",
  movements: (id) => employees_api.base + "/employee/operations/" + id,
  eval: (id) => employees_api.base + "/evaluation/",
  deduction: () => employees_api.base + "/deduction/",
  names: () => employees_api.base + "/employee-names",
  extraWorkGroup: () => employees_api.base + "/extra-work-group",
  read: () => employees_api.base + `/employee`,
};

const request_api = {
  base: REQUEST_BASE_API,
  details: (id) => REQUEST_BASE_API + "/" + id,
  custom: (route) => request_api.base + "/" + route,
};
const homes_api = {
  base: HOMES_BASE_API,
  create: HOMES_BASE_API + "/home/create",
  details: (id) => REQUEST_BASE_API + "/" + id,
  color: (id) => homes_api.base + "/home/color",
  custom: (route) => homes_api.base + "/" + route,
  getHome: (id) => HOMES_BASE_API + "/home/" + id,
  updateHomeFromDepartment: (id) => HOMES_BASE_API + "/home/" + id,
  // setHomeFav
  delete: HOMES_BASE_API + "/home",
  // levels : id => HOMES_BASE_API + '/home/' + id,
  mainDetails: (id) => HOMES_BASE_API + "/home/landingPage/" + id,
  getHomeLevels: (id) => homes_api.base + "/levels/home/" + id,
  paginate_home_levels: (id) => homes_api.base + "/level/home/paginate/" + id,

  toggleFavorite: () => homes_api.base + "/home/toggle/favorite",
  homes: (id) => homes_api.base + "/home/department/" + id,
  stats: {
    important: (id) => homes_api.base + "/levels-balance/" + id,
    years: (id) => homes_api.base + "/years-balance/" + id,
  },
};
const weight_api = {
  base: WEIGHTS_BASE_API,
  all: WEIGHTS_BASE_API,
  create: WEIGHTS_BASE_API,
  update: (id) => WEIGHTS_BASE_API + "/" + id,
  delete: (id) => WEIGHTS_BASE_API + "/" + id,
};

const packaging_api = {
  base: PACKAGING_BASE_API,
  all: PACKAGING_BASE_API + "/carton/paginate",
  create: PACKAGING_BASE_API + "/carton",
  update: (id) => PACKAGING_BASE_API + "/carton/" + id,
  carton: (id) => PACKAGING_BASE_API + "/carton/" + id,
};
const prices_api = {
  base: PRICES_BASE_API,
  all: PRICES_BASE_API,
  current: PRICES_BASE_API + "/carton",
  update: () => prices_api.base + "/carton/",
  history: () => prices_api.base + "/carton/history",
  projectPrices: (query) => prices_api.base + "/carton?collection_center=" + query
};
const reports_api = {
  base: REPORTS_BASE_API,
  all: REPORTS_BASE_API,
  custom: (route) => reports_api.base + "/" + route,
  dailyReports: REPORTS_BASE_API + "/daily",
  totalReports: REPORTS_BASE_API + "/total",
  department: {
    init: REPORTS_BASE_API + "/department",
    eggs: {
      init: (id) => reports_api.base + "/egg/dailyReport/department/" + id,
    },
    feed: {
      form: (id) =>
        reports_api.base + "/feed/dailyReport/department/form/" + id,
      read: (id) => reports_api.base + "/feed/dailyReport/department/" + id,
      create: () => reports_api.base + "/feed/dailyReport/multiple",
      edit: (id) => reports_api.base + "/feed/dailyReport/department/" + id,
    },
    dead: {
      read: (id) => reports_api.base + "/dead/dailyReport/department/" + id,
      edit: (id) => reports_api.base + "/dead/dailyReport/" + id,
      form: (id) =>
        reports_api.base + "/dead/dailyReport/department/form/" + id,
      create: () => reports_api.base + "/dead/dailyReport/multiple",
    },
  },
  home: {
    eggs: {
      init: (id) => reports_api.base + "/egg/dailyReport/home/" + id,
      getHomeEggsReports: (id) =>
        reports_api.base + "/egg/dailyReport/home/paginate/" + id,
      updateReport: (id) => reports_api.base + "/egg/dailyReport/home/" + id,
      createEggReport: () => reports_api.base + "/egg/dailyReport",
      getHomeEggsReportsForm: (id) =>
        reports_api.base + "/egg/dailyReport/home/form/" + id,
      createDepartmentReport: () =>
        reports_api.base + "/egg/dailyReport/multiple",
      delete: () => reports_api.base + "/egg/dailyReport",
      update: (id) => reports_api.base + "/egg/dailyReport/" + id,
    },

    feed: {
      create: () => reports_api.base + "/feed/dailyReport",
      form: id => reports_api.base + "/feed/dailyReport/home/" + id,
      getFeedReportsForHome: (id) => reports_api.base + "/feed/dailyReport/home/paginate/" + id,
      createReportForDepartment: () => reports_api.base + "/feed/dailyReport/multiple",
      formDetails: (id) => reports_api.base + "/feed/dailyReport/home/form/" + id,
      reports: (id) => reports_api.base + "/feed/dailyReport/home/paginate/" + id,
    },
    dead: {
      create: () => reports_api.base + "/dead/dailyReport",
      read: (id) => reports_api.base + "/dead/dailyReport/home/paginate/" + id,
      delete: () => reports_api.base + "/dead/dailyReport",
      update: (id) => reports_api.base + "/dead/dailyReport/" + id,
      form: (id) => reports_api.base + "/dead/dailyReport/home/form/" + id,
      dateFilter: (id) => reports_api.base + "/dead/dailyReport/home/print/" + id,
      getAges: (id) => reports_api.base + "/dead/dailyReport/home/ages/" + id
    },
    drugs: {
      base: () => reports_api.base + "/drug/dailyReport",
      create: () => reports_api.home.drugs.base(),
      read: (id) => reports_api.home.drugs.base() + "/home/paginate/" + id,
      delete: () => reports_api.home.drugs.base(),
      update: (id) => reports_api.home.drugs.base() + "/" + id,
      form: (id) => reports_api.home.drugs.base() + "/home/form/" + id,
    },
    maintenances: {
      base: () => reports_api.base + "/maintenance/dailyReport",
      create: () => reports_api.home.maintenances.base(),
      read: (id) => reports_api.home.maintenances.base() + "/home/paginate/" + id,
      delete: () => reports_api.home.maintenances.base(),
      update: (id) => reports_api.home.maintenances.base() + "/" + id,
      form: (id) => reports_api.home.maintenances.base() + "/home/form/" + id,
    },
    others: {
      base: () => reports_api.base + "/other/dailyReport",
      create: () => reports_api.home.others.base(),
      read: (id) => reports_api.home.others.base() + "/home/paginate/" + id,
      delete: () => reports_api.home.others.base(),
      update: (id) => reports_api.home.others.base() + "/" + id,
      form: (id) => reports_api.home.others.base() + "/home/form/" + id,
    },
    cartons: {
      base: () => reports_api.base + "/carton/dailyReport",
      create: () => reports_api.home.cartons.base(),
      read: (id) => reports_api.home.cartons.base() + "/home/paginate/" + id,
      delete: () => reports_api.home.cartons.base(),
      update: (id) => reports_api.home.cartons.base() + "/" + id,
      form: (id) => reports_api.home.cartons.base() + "/home/form/" + id,
    },
    dishes: {
      base: () => reports_api.base + "/dish/dailyReport",
      create: () => reports_api.home.dishes.base(),
      read: (id) => reports_api.home.dishes.base() + "/home/paginate/" + id,
      delete: () => reports_api.home.dishes.base(),
      update: (id) => reports_api.home.dishes.base() + "/" + id,
      form: (id) => reports_api.home.dishes.base() + "/home/form/" + id,
    },
    weights: {
      base: () => reports_api.base + "/birdWeight/dailyReport",
      create: () => reports_api.home.weights.base(),
      read: (id) => reports_api.home.weights.base() + "/home/paginate/" + id,
      delete: () => reports_api.home.weights.base(),
      update: (id) => reports_api.home.weights.base() + "/" + id,
      form: (id) => reports_api.home.weights.base() + "/home/form/" + id,
    },
    lights: {
      base: () => reports_api.base + "/light/dailyReport",
      create: () => reports_api.home.lights.base(),
      read: (id) => reports_api.home.lights.base() + "/home/paginate/" + id,
      delete: () => reports_api.home.lights.base(),
      update: (id) => reports_api.home.lights.base() + "/" + id,
      form: (id) => reports_api.home.lights.base() + "/home/form/" + id,
    },

  },
};

const apis = {
  base: BASE_API,
  prices: prices_api,

  preferences: {
    base: () => apis.base + "/preferences",
    tax: () => apis.base + "/preferences/tax/get-tax/",
    updateTax: (id) => apis.preferences.base() + "/tax/" + id,
  },

  cartonWeights: {
    base: () => apis.base + "/origin/egg-weight",
    availableWeights: () => apis.cartonWeights.base() + "/not-connected",
    // new-form?project=66505b14332bd68fb1db2d9e
    connected: (projectId) => apis.cartonWeights.base() + "/new-form?project=" + projectId,
    connectedWeights: (cartonId) => apis.cartonWeights.base() + `/not-connected?carton=${cartonId}`,
  },
  helpers: {
    base: () => apis.base + "/helper",
    breadCrumbs: () => apis.helpers.base() + "/breadCrumb",
  },
  eggs_warehouse: {
    // {{storage}}/egg/info/all-places
    all: () => apis.base + "/storage/egg/info/all-places",
    collectionCenter: () => apis.base + "/storage/egg/info/collection-center",
  },

  dishes: {
    base: () => apis.base + "/package/dish",
    all: () => apis.dishes.base(),
    paginate: () => apis.dishes.base() + "/paginate",
    create: () => apis.dishes.base(),
    update: (id) => apis.dishes.base() + "/" + id,
    delete: () => apis.dishes.base(),
  },
  sideNav: () => apis.base + "/sidebar",
  warehouses: {
    ...stock_api,
    homes: (id) => apis.warehouses.base + "/homes/" + id,

  },
  notifications: {
    base: () => apis.base + "/notification",
    all: () => apis.notifications.base(),
    read: (id) => apis.notifications.base() + "/" + id,
    paginate: () => apis.notifications.base() + "/paginate",
    markAsRead: (id) => apis.notifications.base() + "/mark-as-read/" + id,
    markAllAsRead: () => apis.notifications.base() + "/mark-all-as-read",
  },
  cartons: {
    base: () => apis.base + "/package",
    all: () => apis.cartons.base() + "/carton",
  },
  levels: {
    base: () => apis.base + "/homes/level",
    delete: () => apis.base + "/homes/level",

    breeding: {
      base: () => apis.base + "/homes/levels/breeding",
      create: () => apis.levels.base() + "/breeding",
      update: (id) => apis.levels.base() + "/breeding/" + id,
      toggle: (id) => apis.levels.base() + "/toggle/" + id,
    },
    production: {
      base: () => apis.base + "/homes/levels/production",
      // read : id => apis.levels.base() + '/production/' + id,
      create: () => apis.levels.base() + "/production",
      update: (id) => apis.levels.base() + "/production/" + id,
      toggle: (id) => apis.levels.base() + "/toggle/" + id,
    },
  },
  origins: {
    base: () => apis.base + "/origin",
    feed: {
      base: () => apis.origins.base() + "/feed",
      create: () => apis.origins.feed.base(),
      update: (id) => apis.origins.feed.base() + "/" + id,
      delete: (id) => apis.origins.feed.base(),
    },
    drug: {
      base: () => apis.origins.base() + "/drug",
      create: () => apis.origins.drug.base(),
      update: (id) => apis.origins.drug.base() + "/" + id,
      delete: (id) => apis.origins.drug.base(),
    },
    packaging: {
      base: () => apis.origins.base() + "/packaging",
      create: () => apis.origins.packaging.base(),
      update: (id) => apis.origins.packaging.base() + "/" + id,
      delete: (id) => apis.origins.packaging.base(),
    },
    maintenance: {
      base: () => apis.origins.base() + "/maintenance",
      create: () => apis.origins.maintenance.base(),
      update: (id) => apis.origins.maintenance.base() + "/" + id,
      delete: () => apis.origins.maintenance.base(),
    },
    others: {
      base: () => apis.origins.base() + "/other",
      create: () => apis.origins.others.base(),
      update: (id) => apis.origins.others.base() + "/" + id,
      delete: () => apis.origins.others.base(),
    },
    weightBalance: {
      years: () => apis.origins.base() + "/system-years",
      all: (year) => apis.origins.base() + "/egg-weight/balance?year=" + year,
      green: () => apis.origins.base() + "/egg-weight/green-box",
    },

    incubators: {
      base: () => apis.base + "/asset/incubator",
      create: () => apis.origins.incubators.base(),
      update: (id) => apis.origins.incubators.base() + "/" + id,
      delete: (id) => apis.origins.incubators.base(),
      all: () => apis.origins.incubators.base(),
      paginate: () => apis.origins.incubators.base() + "/paginate",
      upload: () => paths.uploads + "/incubator/",
    },
  },

  incubator: {
    base: () => apis.base + "/incubator",
    client: {
      base: () => apis.incubator.base() + "/client",
      create: () => apis.incubator.client.base(),
      update: (id) => apis.incubator.client.base() + "/" + id,
      delete: (id) => apis.incubator.client.base(),
      all: () => apis.incubator.client.base(),
      paginate: () => apis.incubator.client.base() + "/paginate",
    },
  },



  assets: {
    base: () => apis.base + "/asset",
    all: () => apis.assets.base() + "/assets",
    asset: (name) => {
      return {
        base: () => apis.assets.base() + "/" + name,
        paginate: () => apis.assets.base() + "/" + name + "/paginate", // add paginate here
        create: () => apis.assets.asset(name).base(),
        update: (id) => apis.assets.asset(name).base() + "/" + id,
        delete: () => apis.assets.asset(name).base(),
      }
    },
    lands: {
      base: () => apis.assets.base() + "/land",
      paginate: () => apis.assets.base() + "/land", // add paginate here
      create: () => apis.assets.lands.base(),
      update: (id) => apis.assets.lands.base() + "/" + id,
      delete: () => apis.assets.lands.base(),
    },
    buildings: {
      base: () => apis.assets.base() + "/building",
      paginate: () => apis.assets.base() + "/building", // add paginate here
      create: () => apis.assets.buildings.base(),
      update: (id) => apis.assets.buildings.base() + "/" + id,
      delete: () => apis.assets.buildings.base(),
    }
  },

  employees: {
    base: () => apis.base + "/employees",
    profile: (id) => apis.employees.base() + "/employee/info/" + id,
    create: () => apis.employees.base() + "/employee",
    update: (id) => apis.employees.base() + "/" + id,
    delete: (id) => apis.employees.base(),
  },
  balances: {
    base: () => apis.base + "/balances",
    init: {
      all: () => apis.balances.base() + "/opening-balance",
      create: () => apis.balances.base() + "/opening-balance",
      update: (id) => apis.balances.base() + "/opening-balance/" + id,
      readOne: (id) => apis.balances.base() + "/opening-balance/" + id,
      delete: () => apis.balances.base() + "/opening-balance",
      form: (year) =>
        apis.balances.base() + "/form?year=" + year ?? new Date().getFullYear(),
    },
    opening: {
      // all : () => apis.balances.base() + "/opening-balance",
    },
  },
  stats: {
    base: BASE_API + "/statistics",
    weight: () => apis.stats.base + "/egg-weight",
    balance: () => apis.stats.base + "/balance",
    birds: () => apis.stats.base + "/birds",
    boxes: () => apis.stats.base + "/green-boxes",
    farmByYears: () => apis.stats.base + "/years-balance",
    importantStats: () => apis.stats.base + "/levels-balance",
    operators: () => apis.stats.base + "/operators",
  },
  orders: {
    base: () => apis.base + "/orders",
    all: () => apis.orders.base() + "/user/created",
    paginate: () => apis.orders.base() + "/user/paginate",
    details: (id) => apis.orders.base() + "/order/" + id,
    accept: (id) => apis.orders.base() + "/movement/approve/" + id,
    reject: (id) => apis.orders.base() + "/movement/reject/" + id,
    month: (id) => apis.orders.base() + "/feed/month-consumption",

    feed: {
      base: () => apis.orders.base() + "/feed/",
      all: (id) => apis.orders.feed.base() + id,
      create: () => apis.orders.feed.base(),
      update: (id) => apis.orders.feed.base() + id,
      delete: () => apis.orders.feed.base(),
      paginate: () => apis.orders.feed.base() + "paginate",
      paginateHome: id => apis.orders.feed.base() + "home/paginate/" + id
    },
    drugs: {
      base: (id) => apis.orders.base() + "/drug/",
      all: (id) => apis.orders.drugs.base() + id,
      create: () => apis.orders.drugs.base(),
      update: (id) => apis.orders.drugs.base() + id,
      delete: () => apis.orders.drugs.base(),
      paginate: () => apis.orders.drugs.base() + "paginate",
      paginateHome: id => apis.orders.drugs.base() + "home/paginate/" + id
    },
    maintenance: {
      base: () => apis.orders.base() + "/maintenance/",
      all: (id) => apis.orders.maintenance.base() + id,
      create: () => apis.orders.maintenance.base(),
      update: (id) => apis.orders.maintenance.base() + id,
      delete: () => apis.orders.maintenance.base(),
      paginate: () => apis.orders.maintenance.base() + "paginate",
      paginateHome: id => apis.orders.maintenance.base() + "home/paginate/" + id
    },
    other: {
      base: () => apis.orders.base() + "/other/",
      all: (id) => apis.orders.other.base() + id,
      create: () => apis.orders.other.base(),
      update: (id) => apis.orders.other.base() + id,
      delete: () => apis.orders.other.base(),
      paginate: () => apis.orders.other.base() + "paginate",
      paginateHome: id => apis.orders.other.base() + "home/paginate/" + id
    },
    packaging: {
      base: () => apis.orders.base() + "/package/",
      all: (id) => apis.orders.packaging.base() + id,
      create: () => apis.orders.packaging.base(),
      update: (id) => apis.orders.packaging.base() + id,
      delete: () => apis.orders.packaging.base(),
      paginate: () => apis.orders.packaging.base() + "paginate",
      paginateHome: id => apis.orders.packaging.base() + "home/paginate/" + id
    },
    cartons: {
      base: () => apis.orders.base() + "/carton/",
      all: (id) => apis.orders.cartons.base() + id,
      create: () => apis.orders.cartons.base(),
      update: (id) => apis.orders.cartons.base() + id,
      delete: () => apis.orders.cartons.base(),
      paginate: () => apis.orders.cartons.base() + "paginate",
      paginateHome: id => apis.orders.cartons.base() + "home/paginate/" + id
    },
    dishes: {
      base: () => apis.orders.base() + "/dish/",
      all: (id) => apis.orders.dishes.base() + id,
      create: () => apis.orders.dishes.base(),
      update: (id) => apis.orders.dishes.base() + id,
      delete: () => apis.orders.dishes.base(),
      paginate: () => apis.orders.dishes.base() + "paginate",
      paginateHome: id => apis.orders.dishes.base() + "home/paginate/" + id
    },
  },
  employees_details: {
    ...employees_api,
    names: () => employees_api.base + "/employee/employee-names",
    department_employees: (id) => employees_api.base + "/landing-page/" + id,
    department_employees_paginated: (id) => employees_api.base + "/landing-page/paginate/" + id,

    all: () => employees_api.base + "/employee",
  },


  authorization: {
    base: () => apis.base + "/authorization",
    roles: () => apis.authorization.base() + "/roles",
    permissions: () => apis.authorization.base() + "/permissions",
    permissions_form: () => apis.authorization.base() + "/permissions/form",
  },
  invoices: {
    base: () => apis.base + "/invoice",
    counter: () => apis.invoices.base() + "/new-number",
    delete: (query) => apis.invoices.base() + "/" + query,
    buy: {
      feed: {
        create: () => apis.invoices.base() + "/feedbuy",
      },
      drug: {
        create: () => invoices_api.base + "/drug",
      },
      maintenance: {
        create: () => invoices_api.base + "/maintenance",
      },
      others: {
        create: () => invoices_api.base + "/other",
      },
      other: {
        create: () => invoices_api.base + "/other",
      },
      carton: {
        create: () => invoices_api.base + "/carton",
      },
      dishes: {
        create: () => invoices_api.base + "/dish",
      }
    },
    sell: {
      // /egginv/new-form?project=66505b14332bd68fb1db2d9e
      connectedWeights: (projectId) => invoices_api.base + "/egginv/new-form?collection_center=" + projectId,
    },
    form: () => invoices_api.base + "/form"
  },
  units: {
    base: () => apis.base + "/helper",
    all: () => apis.units.base() + "/metric-units",
    mass: {
      all: () => apis.units.base() + "/metric-units?type=mass",
    },
    drugs: {
      all: () => apis.units.base() + "/metric-units?type=drug",
    }
  },
  reports_api,
  storages: {
    base: () => apis.base + "/storage",
    transfers: () => apis.storages.base() + "/movements",
    movementDetails: (id) => apis.storages.base() + "/movements/details?movement_id=" + id,
    paginateTransfers: () => apis.storages.base() + "/movements/paginate",
    create: () => apis.storages.base() + "/transfer",
    quantities: _ => apis.storages.base() + "/quantities",
    price: _ => apis.storages.base() + "/price",
    transfer: () => apis.storages.base() + "/egg/transfer-egg"
  },
  departments: {
    ...departments_api,
    all: () => apis.departments.base + "/all",
  },
  homes: {
    ...homes_api,
    homes_details: () => apis.homes.base + "/level/hierarchical",
    feedTypes: (id) => apis.homes.base + "/level/feed-types/" + id,
    landing: () => apis.homes.base + "/home/landingPage",
    departmentType: (id) => apis.homes.base + "/home/get-department-type/" + id,

    target: (id) => apis.homes.base + "/target/home/" + id,
  },
  projects: {
    ...projects_api,
  },
  approvals: {
    base: () => apis.base + "/Report/approvement/suspended-reports",
    all: () => apis.approvals.base(),
    filter: () => apis.approvals.base() + "?",
    details: (id) => apis.base + "/Report/approvement/suspended-report/" + id,
    approve: (id) => apis.base + "/Report/approvement/approve-report/" + id,
    approved: () => apis.base + "/Report/approvement/approved-reports",
    paginatedApprovals: () => apis.base + "/Report/approvement/suspended-reports/paginate",
    paginatedApproved: () => apis.base + "/Report/approvement/approved-reports/paginate",
  },
  collectionCenter: {
    base: () => apis.base + "/collection-center",
    get: () => apis.collectionCenter.base(),
    names: () => apis.collectionCenter.base() + "/names",
    create: () => apis.collectionCenter.base(),
    update: (id) => apis.collectionCenter.base() + "/" + id,
    delete: () => apis.collectionCenter.base(),
    color: () => apis.collectionCenter.base() + "/color",
    fav: () => apis.collectionCenter.base() + "/toggle/favorite",
  },
  targets: {
    base: () => apis.base + "/target",
    all: () => apis.targets.base() + "/info/all-places",
    filter: () => apis.targets.base() + "?",
    details: (id) => apis.base + "/target/" + id
  }

};

export {
  stock_api,
  operators_api,
  feed_types_api,
  invoices_api,
  projects_api,
  departments_api,
  auth_api,
  roles_api,
  users_api,
  employees_api,
  request_api,
  homes_api,
  weight_api,
  packaging_api,
  prices_api,
  reports_api,
  strain_api,
  apis,
};
