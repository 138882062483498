import React, { useCallback, useEffect, useState } from "react";
import useSelectDebounce from "./hooks/useSelectDebounce";
import "./select.styles.css";
import SelectTrigger from "./SelectTrigger";
import SelectOptions from "./SelectOptions";
import { showErrorToast, showInfoToast } from "../../stores/toast.store";
import useSelectCallbackDebounce from "./hooks/useSelectCallbackDebounce";



export default function Select({
  apiFn,
  multiple = false,
  searchable = true,
  onValueChange,
  defaultValue,
  searchLimit = 20
}) {
  const [options, setOptions] = useState([]);
  const [input, setInput] = useState("");
  const debounced = useSelectDebounce(input , 300);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);

  const [watcher, setWatcher] = useState(undefined);
  const [page , setPage] = useState(1)
  const [maxPages , setMaxPages] = useState(1)

  const callApi = async  () => {
    setLoading(true);
    
    const response = await apiFn(input , page);
    
    setLoading(false);
    setMaxPages(response.maxPageCount)


    if(page === 1 && [...options , ...response.options].length > searchLimit){
      setOptions(response.options)
    }else {
      setOptions([...options , ...response.options]);
    }
    
    setPage(page)


  };


  const debouncedApiCall = useSelectCallbackDebounce(callApi , 350)

  useEffect(() => {
    debouncedApiCall();
  }, [input , page]);

  const triggerWatcher = () => {
    setWatcher(
      "xxxxx-xxxx-xxx-xxx".replace(/x/g, () =>
        Math.floor(Math.random() * 16).toString(16)
      )
    );
  };

  const onChange = (value) => {
    // setSelected(value)

    if (multiple) {
      if (selected.includes(value)) {
        setSelected(selected.filter((d) => d !== value));
      } else {
        setSelected([...selected, value]);
      }
    } else {
      setSelected([value]);
    }

    setOpen(false);

    triggerWatcher();
  };

  const onInputChange = (value) => {
    setInput(value);
    setOptions([]); 
    setPage(1);     
  };
  
  
  useEffect(() => {
    if (onValueChange && watcher) {
      if (multiple) {
        onValueChange(selected);
      } else {
        onValueChange(selected[0]);
      }
    }
  }, [watcher]);

  useEffect(() => {
    if (defaultValue) {
      if (multiple) {
        if (!Array.isArray(defaultValue)) {
          showErrorToast("defaultValue must be an array in multiple mode");
          setTimeout(() => { 
            showInfoToast("Select component")
           }, 300)
          console.error("defaultValue must be an array in multiple mode");
          return;
        } else setSelected(defaultValue);
      } else {
        setSelected([defaultValue]);
      }
    }
  }, [defaultValue]);



  const deleteOption = (value) => {
    setSelected(selected.filter((d) => d !== value));
    triggerWatcher();
  };

  return (
    <div className="relative">
      <SelectTrigger
        deleteOption={deleteOption} open={open} setOpen={setOpen}
        options={options} selected={selected} loading={loading}
      />

      {open && (
        <SelectOptions
          searchable={searchable}
          setOpen={setOpen} options={options} selected={selected}
          onChange={onChange} onInputChange={onInputChange} setPage={setPage}
          maxPageCount={maxPages} page={page} input={input}
        />
      )}
    </div>
  );
}
