import React from 'react'
import NavTabs from '../../../components/tabs/NavTabs'
import Departments from '../Departments'
import ProjectTargets from '../pages/ProjectTargets'

export default function ProjectsTabs() {



  const tabs = [
     { name : "الاقسام" , component :  Departments  , param : "departments" },
     { name : "المُستهدف" , component : ProjectTargets  , param : "targeted" }, 
  ]




  return (
    <NavTabs tabs={tabs}  />
  )
}
