import { useMemo, useState } from "react";
import { apis } from "../../../../config/apis";
import { createFormHandler } from "../../../../helpers/form.helper";
import { EditForm, InputSelect, InputText, InputTextArea, PageMeta, Signboard } from "../../../../config/imports";
import ArrayHelpers from "../../../../helpers/array.helpers";

export default function Create(props){

    const {setRefresh, toggle , operators} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        name: '',
        operator: '',
        info: '',
        measuringUnit: ''
    })

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();


        const create_props = {
            url: apis.origins.others.create(),
            data,
            setLoading,
            setError,
            setSuccess,
            setRefresh,
            setMainData  : ()=>{},
            setData,
            FORM_HEADER: `الاضافة`,
        };

        await createFormHandler(create_props);
    };


    const measuringUnits = useMemo(() => {
        return [
            { text : "قطعه" , value : "piece" },
        ]
    } , [])

    return(
        <>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>اضافة نوع اخرى  جديده</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
                <InputText  name={'name'} title={'الاسم'} value={data.name} />
                <InputSelect  name={'operator'} title={'المورد'} value={data.operator}  options={ ArrayHelpers.makeOptionsForInputSelect(operators)} />
                <InputSelect name={ 'measuringUnit' } className={'col-4'} title={'وحدة القياس'} defaultValue={data.measuringUnit} handleInputChange={handleInputChange}  options={measuringUnits} />
                <InputTextArea name={'info'} title={'التفاصيل'} value={data.info} />
            </EditForm>

        </>
    )
}
