import {useEffect} from "react";
import {RouterLinks} from "../../components/LinkList";
import {useNavigator} from "../../config/imports";

const homesDetails = {
    pathBuilder : function(id){ return {
        origin :`${this.origin}`+`/${id}`,
        id
    }},
    origin: "departments",
    navigateTo: "homes",
    title: "البيوت",
    links: [
        {to: "homes",name: "البيوت"},
        // {to: "details",name: "تقاصيل القسم"},
        // {to: "reports/total",name: "التقارير"},
        // {to: "salaries",name: "الرواتب والاجور"},
        {to: "eggs-warehouse",name: "مستودع البيض"},
        {to : "targeted",name : "المُستهدف"},
    ],
};



const HomeIndex = ()=>{

    const HOME_INDEX_LINKS = [
        {name: "البيوت",  path: "base", },
        // {name: "فواتير شراء العلف",  path: "feed-invoices", },

    ];

    useNavigator( "homes" ,"base")

    return <div>

        <RouterLinks data={HOME_INDEX_LINKS} />

    </div>
}

export {
    homesDetails
}
export default HomeIndex
