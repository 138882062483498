import { useEffect } from 'react'
import { setBreadCrumbsStore } from '../../../components/breadcrumbs/store/breadcrumb.store'
import { homeBreadCrumb, targetsBreadCrumb } from '../../../components/breadcrumbs/data/breadcrumbs.data'
import useMeta from '../../../hooks/useMeta'
import useTargetsStore from '../stores/targets.store'
import useTargetFilterStore from '../stores/targets-filter.store'

export default function useTargets() {
  

  const fetch = useTargetsStore((state) => state.fetch)

  const filter = useTargetFilterStore((state) => state.filter)



  useEffect(() => {

    fetch(filter)

  } , [filter])



  
  useEffect(()=>{
    setBreadCrumbsStore([
        homeBreadCrumb,
        targetsBreadCrumb
    ])
  } , [])


    useMeta({
      title : 'المُستهدف'
    })

    
}
