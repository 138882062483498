import React from 'react'
import useTargets from '../../targets/hooks/useTargets'
import TargetsFilters from '../components/TargetsFilters'
import TargetsTable from '../components/TargetsTable'
import useProjectTargets from '../hooks/useProjectTargets'

export default function ProjectTargets() {
  useProjectTargets()

  return (
   <div className='mt-5'>
    
    <div className='page'>
        <TargetsFilters />
      </div>

      <div className='page mt-3 p-4'>
          <TargetsTable />
      </div>


   </div>
  )
}
