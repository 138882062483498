import { useEffect, useState } from "react";
import { apis, reports_api } from "../../../../../config/apis";
import { createFormHandler, setInputsToDefault } from "../../../../../helpers/form.helper";
import { useParams } from "react-router-dom";
import { PageMeta, Signboard, EditForm, InputDate, _date, InputNumber, InputTextArea, useFetch } from "../../../../../config/imports";
import useHomeType from "../../hooks/useHomeType";

export default function Create(props) {

    const { id } = useParams();
    const { setMainData, toggle } = props;
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const { data: deadReport } = useFetch(apis.reports_api.home.dead.form(id))

    const [data, setData] = useState({
        date: new Date().toISOString(),
        dead_chicken: '',
        dead_rooster: '',
        home: id
    })


    const { hasRoosterInfo } = useHomeType() // as breeding or production  






    // test update
    useEffect(() => {
        if (deadReport)
            setData({ ...data, date: deadReport?.date })

    }, [deadReport])

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: reports_api.home.dead.create(),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData: () => { },
            setRefresh: props.setRefresh,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };
        await createFormHandler(create_props);
    };
    return (
        <div className="create-dead-report">
            <PageMeta title={'اضافة تقرير نافق يومي'} originTitle={'تقارير النافق'} />

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>   اضافة تقرير نافق يومي</h3>
            </header>

            <Signboard error={error} success={success} loading={loading} />

            <EditForm
                visible={true}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange} >
                <>
                    <div className="col-12">
                        <InputDate name={'date'} disabled={true} className="w-100 " handleInputChange={handleInputChange} defaultValue={_date(data.date).forInputDate()} placeholder={'B'} title={'التاريخ'} value={data.date} />
                        <small className="h-[30px]"> تاريخ التقرير : {_date(data.date).dmy()}</small>
                    </div>

                    <div className="col-4 mb-3">
                        <InputNumber name={'dead_chicken'} allowDecimal={false} className="w-100 " handleInputChange={handleInputChange} placeholder={'5'} title={'نافق الدجاج'} value={data.dead_chicken} />
                    </div>
                    <div className="col-4 mb-3 relative">
                        <InputNumber className="w-100 " disabled placeholder={''} title={'قيمة نافق الدجاج'} defaultValue={data.dead_chicken * deadReport?.chicken_unit_price} />
                        <span className="absolute bottom-[-15px] left-3 text-slate-400"> سعر الوحده : {deadReport?.chicken_unit_price} </span>
                    </div>
                    <div className="col-4 mb-3">
                        <InputNumber className="w-100 " disabled placeholder={''} title={' عدد الدجاج الحالي'} defaultValue={deadReport?.chicken} />
                    </div>



                    {
                        hasRoosterInfo && <>
                            <div className="col-4">
                                <InputNumber name={'dead_rooster'} className="w-100 " handleInputChange={handleInputChange} placeholder={'6'} title={'نافق الديوك'} value={data.dead_rooster} />
                            </div>

                            <div className="col-4">
                                <InputNumber className="w-100 " disabled placeholder={''} title={'قيمة نافق الديوك'} defaultValue={data.dead_rooster * deadReport?.rooster_unit_price} />
                                <span className="absolute bottom-[-15px] left-3 text-slate-400"> سعر الوحده : {deadReport?.rooster_unit_price} </span>

                            </div>
                            <div className="col-4">
                                <InputNumber className="w-100 " disabled placeholder={''} title={' عدد الديوك الحالي'} defaultValue={deadReport?.rooster} />
                            </div>
                        </>
                    }





                </>
                <InputTextArea name={'notes'} handleInputChange={handleInputChange} placeholder={' ملاحظات على تقارير النافق... '} title={'ملاحظات'} value={data.notes} />
            </EditForm>

        </div>
    )
}
