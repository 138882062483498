import { BiCheck } from "react-icons/bi";
import React, { useEffect, useState } from 'react';
import { apis } from '../../config/apis';
import useFetch from '../../hooks/useFetch';
import PageMeta from '../../components/PageMeta';
import { Table } from '../../layout/table';
import { _date } from '../../helpers/date.helpers';
import Pagination from '../../layout/pagination/Pagination';
import { useNavigate } from 'react-router-dom';
import { setBreadCrumbsStore } from '../../components/breadcrumbs/store/breadcrumb.store';
import { homeBreadCrumb, notificationsBreadCrumb } from '../../components/breadcrumbs/data/breadcrumbs.data';
import { accio } from '../../helpers/axios/axios.get';
import { showErrorToast } from '../../stores/toast.store';
import { uuid } from '../../helpers/id.helper';
import Signboard from "../../components/Signboard";
import { Spinner } from "react-bootstrap";

export default function Notifications(props) {

  const navigate = useNavigate()

  
    const api = apis.notifications.paginate()
    const [loading , setLoading] = useState(false)
    
  
    const { data  , paginate , setters , alert , setRefresh } = useFetch(api);


    const markAllAsRead = async ()=>{
      if(loading) return

      setLoading(true)
      const response = await accio(apis.notifications.markAllAsRead())

      setLoading(false)
      if(response.error){
        return showErrorToast(response.error)
      }


      setRefresh(uuid())
    }


    const table = [
        {size : 2 , name : "#" , text : "#"  },
        {size : 5 , name : "title" , text :  'العنوان ' },
        {size : 10 , name : "body" , text :  'المحتوى ' , custom : d => {

          
          const onClick = async ()=>{

            const  response  = await accio(apis.notifications.markAsRead(d?._id))

            if(response.error) 
              return showErrorToast(response.error)
            
              navigate(d.link , {replace : true})

              setRefresh(uuid())                       
          }


          return <button className='text-dark' onClick={onClick}> {d.body} </button>  

        }},
        {size : 4 , name : "createdAt" , text :  'تاريخ الاشعار', custom : d => _date(d.createdAt).dateAndTime() },

        { size : 2 , name : "status" , text : "الحالة"  , custom  : d => {

          const read = d.is_readd

          return <span className={`px-2 ${read ? "bg-green-300 text-green-800  rounded-md" : "bg-red-300 text-red-800  rounded-md"}`}>{read ? "مقروء" : "غير مقروء"}</span>

        } }
    ]


    useEffect(()=>{
      setBreadCrumbsStore([
          homeBreadCrumb,
          notificationsBreadCrumb,
      ])
    } , [])





  return (
    <div className='base-card bg-white r-16 p-3 mt-3'>
    <PageMeta title=" الاشعارات" />
    {/* <TableHeader   api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} /> */}


    <Pagination   paginate={paginate}    api={api} setData={setters.data} setLoading={setters.loading} search={""} />

    <Signboard {...alert}/>

    <div className=" justify-end flex items-center">
      <button className='add-btn w-[200px] px-4 flex items-center justify-center ' onClick={markAllAsRead}>
          {!loading && <>
            <BiCheck className="fill-white text-white text-[28px]" />
            <span  className="fill-white text-white whitespace-nowrap" >
              وضع الكل كمقروء
            </span>
          </>}

          {loading &&  <Spinner className="fill-white text-white text-[10px]"  size="md" />}
      </button>
    </div>
    <Table table={table} data={data}   className="mt-3" />
  </div>
  )
}
