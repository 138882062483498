import React from 'react'
import useDepartmentsTargets from '../hooks/useDepartmentsTargets'
import TargetsFilters from '../components/TargetsFilters'
import TargetsTable from '../components/TargetsTable'


export default function DepartmentTargets() {

  useDepartmentsTargets()

  return (
   <div className='mt-5'>
    
    <div className='page'>
        <TargetsFilters />
      </div>

      <div className='page mt-3 p-4'>
          <TargetsTable />
      </div>

      
   </div>
  )
}
