import React, { useState, useEffect } from "react";

const InputNumber = ({
  label,
  titleInvalid,
  pattern,
  className,
  handleInputChange,
  name,
  title,
  defaultValue,
  type = "text",
  isReadonly = false,
  placeholder,
  disabled,
  input,
  labelProps,
  required,
  allowDecimal = true,
}) => {
  const [value, setValue] = useState("");
  const [isValid, setIsValid] = useState(true);


  const convertArabicToEnglishDigits = (str) => {
    const arabicToEnglishMap = {
      "٠": "0",
      "١": "1",
      "٢": "2",
      "٣": "3",
      "٤": "4",
      "٥": "5",
      "٦": "6",
      "٧": "7",
      "٨": "8",
      "٩": "9",
    };
    return str.replace(/[٠-٩]/g, (digit) => arabicToEnglishMap[digit] || digit);
  };

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== null) {
      const converted = convertArabicToEnglishDigits(defaultValue.toString());
      setValue(converted);
    }
  }, [defaultValue]);

  const onChange = (e) => {
    let inputValue = e.target.value;
    inputValue = convertArabicToEnglishDigits(inputValue);

    if (allowDecimal) {
      // Allow digits and a single decimal point
      inputValue = inputValue.replace(/[^0-9.]/g, "");
      // Remove any additional decimal points beyond the first one
      if ((inputValue.match(/\./g) || []).length > 1) {
        // Simply remove the last typed '.'
        inputValue = inputValue.replace(/\.(?=.*\.)/, "");
      }
    }else {
      inputValue = inputValue.replace(/[^0-9]/g, "");
    }

    let valid = true;
    if (pattern) {
      const regex = new RegExp(pattern);
      valid = regex.test(inputValue);
    } else if (type === "number") {
      valid = !isNaN(inputValue) && inputValue !== "";
    }

    setIsValid(valid);
    setValue(inputValue);

    const syntheticEvent = {
      ...e,
      target: {
        ...e.target,
        value: inputValue,
        type: "number",
        name,
      },
    };

    handleInputChange(syntheticEvent);
  };

  return (
    <div className={className || "col-12 col-md-4 col-lg-4"}>
      <div data-error="" className="form-group">
        <label htmlFor={name} className={labelProps?.className || ""}>
          {labelProps?.content || label || title}
        </label>
        <input
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          title={title}
          readOnly={isReadonly}
          placeholder={placeholder}
          disabled={disabled}
          pattern={pattern}
          aria-invalid={!isValid}
          className={`form-control ${input?.className || ""} ${
            !isValid ? "is-invalid" : ""
          }`}
          required={required === undefined || required}
        />
        {!isValid && titleInvalid && (
          <span className="invalid-feedback">{titleInvalid}</span>
        )}
      </div>
    </div>
  );
};

export default InputNumber;

// import FormInput from "./FormInput";
// import {useEffect} from "react";

// const InputNumber = (props) => {
// const {className , handleInputChange , name, title , defaultValue, isReadonly , disabled} = props

//     useEffect(()=>{
//         let inputNumberElms = [...document.querySelectorAll('input[type="number"]')]
//     //     loop through all input number elements to prevent increase/decrease value when scrolling with mouse wheel
//         inputNumberElms.forEach(elm =>
//             elm.addEventListener('wheel', (e) =>  e.preventDefault() )
//         )

//     })
//     return (

//         <>
//             <FormInput {...props} step='any'  placeholder={props.placeholder} name={name} className={className} handleInputChange={handleInputChange} disabled={disabled} defaultValue={defaultValue}   isReadonly={isReadonly}  title={title} type={"number"} />
//         </>

//     );

// }

// export default InputNumber;
