import { useState } from "react";
import Signboard from "../../../components/Signboard";
import { deleteFormHandler } from "../../../helpers/form.helper";
import DeleteTable from "../../../layout/table/DeleteTable";
import { useCollectionCenterStore } from "../store/collection-center.store";
import { apis } from "../../../config/apis";
import { _date } from "../../../helpers/date.helpers";



export default function DeleteCollectionCenter(props){

    const {  item } = props

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);

    const refetch = useCollectionCenterStore(state => state.setRefetch)


 

    const table = [
        { size : 12 , name : 'name' , text : 'اسم النقطة' },
        { size : 6 , name : 'createdAt' , text : 'تاريخ الانشاء'  , custom : d => _date(d.createdAt).dmy() },
        { size : 6 , name : 'updatedAt' , text : 'تاريخ اخر تعديل'  , custom : d => _date(d.createdAt).dmy() },
    ]

    const handleDelete = async () => {
        const delete_props = {
            url: apis.collectionCenter.delete() ,
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData : ()=>{
              refetch();
            },
            item,
        };

        // console.log(delete_props)
        await deleteFormHandler(delete_props);
    };

    return(
        <>
  
            <header className="text-center w-fit px-3 m-auto ">
                <h3 className='border-b-[1px] border-[#fca119] pb-3'>حذف نقطة التجميع</h3>
            </header>


            <Signboard error={error} success={success} loading={loading}/>

            <DeleteTable  table={table} data={[item]} />

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => props.close()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleDelete}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
