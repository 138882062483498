import { BiStar } from "react-icons/bi"; 
import React from 'react';
import CollectionCenterMenu from "./CollectionCenterMenu";
import { useCollectionCenterStore } from '../store/collection-center.store';
import { Link } from 'react-router-dom';

export default function CollectionCenterItem(props) {

  const { data  } = props

  const _color = data.system_info?.color || "#41e481"
  const _fav = data.system_info?.is_favorite || false
 
  return (
    <div className='relative'>
      <CollectionCenterMenu data={data} fav={_fav} />
      <Link to={"/collection-center/"+ data._id }  style={{background : _color}}
      className='flex  px-2  text-white text-[18px]  items-center justify-center text-center flex-col w-[150px] h-[150px] border-border border-[1px] rounded-lg '>
          {data.name}
      </Link>
    </div>
  )
}


