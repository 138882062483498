import React, { useMemo, useState } from 'react'
import useFetch from '../../../hooks/useFetch'
import { apis } from '../../../config/apis'
import { InputSelect } from '../../../config/imports'
import { allOption } from '../shared.approvals'
import { useSearchParams } from 'react-router-dom'

export default function DepartmentsFilter(props) {
    const [searchParams ] = useSearchParams()
    const state  = searchParams.get('department')
    // const [state , setState] = useState(undefined)

    const {data : departments} = useFetch(apis.departments.all())


    const options = useMemo(() => {


        if(!departments) return []
        
        return [allOption , ...departments.map(d => ({value : d._id , text : d.name_with_projects}))]
        
        
    } , [departments])
    

    const handleInputChange = (e) => {
        const { value} = e.target;
        // setState(value)
        if(props?.setQuery) return props.setQuery(value)

        // searchParams.set('department' , value)

    }

  return (
    <InputSelect  className="w-full" options={options}  handleInputChange={handleInputChange}  title="اختر القسم"  defaultValue={state}  />
  )
}
