import React, { useEffect, useMemo } from 'react'
import { apis } from '../../config/apis'
import useFetch from '../../hooks/useFetch'
import PageMeta from '../../components/PageMeta'
import { Table } from '../../layout/table'
import { _date } from '../../helpers/date.helpers'
import Icons from '../../layout/Icons'
import { Link, useSearchParams } from 'react-router-dom'
import HomesFilter from './components/HomesFilter'
import DepartmentsFilter from './components/DepartmentsFilter'
import ProjectsFilter from './components/ProjectsFilter'
import DateFilter from './components/DateFilter'
import { TableHeader } from '../../components/TableView'
import { setBreadCrumbsStore } from '../../components/breadcrumbs/store/breadcrumb.store'
import { approvalsBreadCrumb, homeBreadCrumb } from '../../components/breadcrumbs/data/breadcrumbs.data'


export default function Approvals() {
    const api = apis.approvals.paginatedApprovals()
    
    const [searchParams , setSearchParams] = useSearchParams()


    const query = useMemo(() => ({
      home : searchParams.get("home"),
      department : searchParams.get("department"),
      project : searchParams.get("project"),
      start_date : searchParams.get("start_date"),
      end_date : searchParams.get("end_date"),
    }), [searchParams])

    const filters = {
      base(key , value){
        const updatedParams = new URLSearchParams(searchParams);
        if(value){
          updatedParams.set(key, value);
        }else {
          updatedParams.delete(key);
        }

        setSearchParams(updatedParams);
      },

      project(data){
        filters.base("project" , data)
      },
      department(data){
        filters.base("department" , data)
      },
      home(data){
        filters.base("home" , data)
      },
     date(data) {
        const updatedParams = new URLSearchParams(searchParams);

        
        if (data.start_date === "" || data.start_date === undefined || data.start_date === null) {
          updatedParams.delete("start_date"); 
        } else {
          updatedParams.set("start_date", data.start_date); 
        }

        
        if (data.end_date === "" || data.end_date === undefined || data.end_date === null) {
          updatedParams.delete("end_date"); 
        } else {
          updatedParams.set("end_date", data.end_date); 
        }

        
        setSearchParams(updatedParams);
      }

      


    }




    const {data , Alert , setters , paginate } = useFetch(api   , query)





    useEffect(()=>{
      setBreadCrumbsStore([
          homeBreadCrumb,
          approvalsBreadCrumb,
      ])
    } , [])



    const table = [
      {size : 1 , name : "#" , text : "#"  },
      {size : 2 , name : "ar_type" , text : "النوع"  },
      {size : 3 , name : "home" , text : "البيت"  },
      {size : 3 , name : "department" , text : "القسم"  },
      {size : 3 , name : "project" , text : "المشروع"  },
      {size : 3 , name : "date" , text : "تاريخ الانشاء"  , custom : d => _date(d.date).dmy()   },
      {size : 3 , name : "created_by" , text : "اُنشئ بواسطة"  },
      {size : 3 , name : "last_updated_by" , text : "تم التحديث بواسطة"  },
      {size : 3 , name : "date" , text : ""  , custom : d => {
         return <Link to={"/approvals/" + d._id} className='flex items-center'>
                  <span className='ml-2'> تفاصيل  </span>
                  <div className='scale-[.9]'>
                    <Icons.Arrow />
                  </div>
         </Link>
      }  },

    ]

  return (
    <div className='page'>
      <PageMeta title={"التقارير"}  />
      <TableHeader buttons={[]}    api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate}  />
      <div className="row items-center ">
             <div className="col-3 relative top-[0px]">
              <label htmlFor="" className='block  relative bottom-2 whitespace-nowrap'>اختر المده</label>
              <DateFilter setQuery={filters.date} /> 
            </div>
            <div className="col-3"><ProjectsFilter setQuery={filters.project} /> </div>
            <div className="col-3"><DepartmentsFilter setQuery={filters.department} /> </div>
            <div className="col-3"><HomesFilter setQuery={filters.home} /> </div>
        </div>
      <Alert />
      <Table data={data}  table={table} />
    </div>
  )
}
