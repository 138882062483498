import { useState } from "react";
import { apis } from "../../../../config/apis";
import { setInputsToDefault, updateFormHandler } from "../../../../helpers/form.helper";
import { EditForm, InputSelect, InputText, InputTextArea, PageMeta, Signboard } from "../../../../config/imports";

import ArrayHelpers from "../../../../helpers/array.helpers";

export default function Update(props){

    const {toggle , item , setMainData , operators } = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        name:item.name,
        measuringUnit:item.measuringUnit,
        types:item.types,
        operator:item.operator?._id,
        classification:item.classification,
        info:item.info,
    })

    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: apis.origins.drug.update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };
        // console.log(update_props.data)


        await updateFormHandler(update_props);
    };
    // console.log({item})
    return(
        <>
            <PageMeta title={'تعديل نوع الدواء'} originTitle={' الادوية'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>تعديل نوع الدواء</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>

                <InputText name={'name'} className={'col-6'} title={'الاسم'} defaultValue={data.name} />
                
                <>
                   <div className="col-6 relative bottom-1">
                        <label htmlFor="" className="mb-2">وحدة القياس</label>
                        <div className=" cursor-not-allowed  h-[40px] border-[1px] rounded-md flex items-center px-2 bg-gray-100">
                            {data.measuringUnit}
                        </div>
                
                   </div>
                </>

                <InputSelect name={'operator'} className="col-6"  title={'المورد '} defaultValue={data.operator}  options={ ArrayHelpers.makeOptionsForInputSelect(operators)} />

                <InputSelect className='col-6' name={'classification'} title={'التصنيف'} defaultValue={data.classification}  options={[
                    {_id : "تحصين" , name : "تحصين" },
                    {_id : "دواء" , name : "دواء" },
                    {_id : "فتامين" , name : "فتامين" },
                    {_id : "مطهرات" , name : "مطهرات" },
                ]} />


                <InputTextArea name={'info'} title={'التفاصيل'} defaultValue={data.info} />
            </EditForm>
        </>
    )
}
