import { Route, Navigate, BrowserRouter, Routes, } from "react-router-dom";


import LoginPage from "../pages/auth/Login";
import Operators from "../pages/operators/Operators";
import Invoices from "../pages/Invoices/Invoices";

import NotFondPage from "../NotFondPage";

import OriginAndWarehouseContainer from "./OriginAndWarehouseContainer";
import MainLayout from "../layout/MainLayout";

import MainContainer from "./MainContainer";
import ItemCards from "../pages/warehouseDetails/item-cards/ItemCards";
import Orders from "../pages/warehouseDetails/orders/Orders";
// warehouse details imports

import { warehouseDetails } from "../pages/warehouseDetails/warehouseDetails.helper";

import WareHouseFeed from "../pages/warehouseDetails/item-cards/Feed";

import BuyFeed from "../pages/warehouseDetails/item-cards/feed/buy/Buy";
import ClassFeed from "../pages/warehouseDetails/item-cards/feed/class/Class";
import ListFeed from "../pages/warehouseDetails/item-cards/feed/list/ReportList";
import SupplyAndPackaging from "../pages/warehouseDetails/item-cards/SupplyAndPackaging";
import BuySupplements from "../pages/warehouseDetails/item-cards/SupplyNPackaging/BuyList/BuySupplements";
import ProjectsBalances from "../pages/warehouseDetails/item-cards/SupplyNPackaging/ProjectsBalances/ProjectsBalances";
import ClassExportingReports from "../pages/warehouseDetails/item-cards/SupplyNPackaging/ClassExporting/ClassExportingReports";
import Spareparts from "../pages/warehouseDetails/item-cards/Spareparts";
import BuySpareparts from "../pages/warehouseDetails/item-cards/SpareParts/Buy/BuySpareparts";
import SpareClassExportingReports from "../pages/warehouseDetails/item-cards/SpareParts/Exporting/SpareClassExportingReports";
import DrugsAndImmunity from "../pages/warehouseDetails/item-cards/DrugsAndImmunity";
import BuyDrugs from "../pages/warehouseDetails/item-cards/drug/Buy/BuyDrugs";
import DrugsClassExportingReports from "../pages/warehouseDetails/item-cards/drug/Exporting/DrugsClassExportingReports";
import DrugClass from "../pages/warehouseDetails/item-cards/drug/Class/DrugClass";
import StockIndex from "../pages/stocks/StockIndex";
import BillingManagementIndex from "../pages/billing_management/BillingManagementIndex";
import EggStorageUnitsIndex from "../pages/billing_management/egg_storage_units/EggStorageUnitsIndex";
import ImportantStatsIndex from "../pages/billing_management/Important_stats/ImportantStatsIndex";
import WeightBalancesIndex from "../pages/billing_management/weights_balances/WeightBalancesIndex";
import SalesInvoicesIndex from "../pages/billing_management/sales_invioces/SalesInvoicesIndex";
import SalariesIndex from "../pages/salaries/SalariesIndex";
import SalaryProfile from "../pages/salaries/salary_profile/SalaryProfile";
import UserOperations from "../pages/salaries/salary_profile/UserOperations";
import OrderDrugs from "../pages/warehouseDetails/orders/drugs/Buy/OrderDrugs";
import OrderList from "../pages/warehouseDetails/orders/feed/buy/OrderList";
import OrderSupplements from "../pages/warehouseDetails/orders/SupplyNPackaging/BuyList/OrderSupplements";
import OrdersSpareparts from "../pages/warehouseDetails/orders/maintenance/Buy/OrdersSpareparts";

import ProjectsReportIndex from "../pages/Projects/Houses/Reports/projectsReports.index";
import ProjectsFeedReports from "../pages/Projects/Houses/Reports/feed/feed.index";
import FeedReports from "../pages/Projects/Houses/Reports/feed/reports/buy/FeedReports";
import FeedMovementReports from "../pages/Projects/Houses/Reports/feed/reports/buy/FeedMovementReports";
import BirdsNotes from "../pages/Projects/Houses/Reports/notes/birds.notes.index";
import BirdsNotesMenu from "../pages/Projects/Houses/Reports/notes/inputsMenu/BirdsNotesMenu";
import BirdsDrugConsumption from "../pages/Projects/Houses/Reports/drugs/drugs.index";
import DrugsAndFortificationsReports from "../pages/Projects/Houses/Reports/drugs/drugs-invoices/DrugsAndFortificationsReports";
import TestingHelperIndex from "../pages/testing_helper/TestingHelperIndex";
import DrugsConsumptionReports from "../pages/Projects/Houses/Reports/drugs/consumption-reports/DrugsConsumptionReports";
import DrugsAndFortificationsMovementReports from "../pages/Projects/Houses/Reports/drugs/drugs-movments/DrugsAndFortificationsMovmentReports";
import HousesMaintenanceReports from "../pages/Projects/Houses/Reports/maintenance/maintenance.index";
import HousesMaintenanceInvoicesReports from "../pages/Projects/Houses/Reports/maintenance/invoices/HousesMaintenanceInvoicesReports";
import HouseMaintainedOperations from "../pages/Projects/Houses/Reports/maintenance/operations/HouseMaintanceOperations";
import PackagingInvoiceReports from "../pages/Projects/Houses/Reports/packaging/packaging.index";
import PackagingInvoiceReportsList from "../pages/Projects/Houses/Reports/packaging/invoices/PackingInvoicesReports";
import PackagingProjectsBalancesReports from "../pages/Projects/Houses/Reports/packaging/projectbalances/PackagingProjectsBalancesReports";
import ProjectsPackingReportsList from "../pages/Projects/Houses/Reports/packaging/reports/ProjectsPackingReportsList";
import OtherProjectsReports from "../pages/Projects/Houses/Reports/others/others.index";
import ProjectsOthersExpensesInvoices from "../pages/Projects/Houses/Reports/others/invoices/ProjectsOthersExpensesInvoices";
import ProjectsOthersExpensesOperationsInvoices from "../pages/Projects/Houses/Reports/others/operations/ProjectsOthersExpensesOperationsInvoices";
import ProjectsBase from "../pages/Projects/ProjectsBase";
import Departments from "../pages/Projects/Departments";
import HomeIndex, { homesDetails } from "../pages/homes/HomeIndex";
import DepartmentHomes from "../pages/homes/homes/DepartmentHomes";
import DepartmentDetails from "../pages/homes/details/DepartmentDetails";
import DepartmentReports from "../pages/homes/reports/DepartmentReports";
import DepartmentSalaries from "../pages/homes/salaries/DepartmentSalaries";
import ErrorHandler from "./ErrorElements/ErrorHandler";
import DepartmentHomeFeedInvoice from "../pages/homes/homes/DepartmentHomeFeedInvoice";
import { usersMainContainerDetails } from "../pages/users/UsersIndex";
import Roles from "../pages/users/Roles";
import UserBase from "../pages/users/UserBase";
import { useAuthContext } from "../hooks/useAuthContext";
import RequestIndex from "../pages/requests/RequestIndex";
import RequestDetails from "../pages/requests/RequestDetails";
import CartoonDetails from "../pages/billing_management/egg_storage_units/CartoonDetails";
import { pricesDetails } from "../pages/prices/PricesIndex";
import CurrentPrices from "../pages/prices/CurrentPrices";
import PreviousPrices from "../pages/prices/PreviousPrices";
import DailyHomeReport from "../pages/homes/reports/daily/DailyHomeReport";
import TotalHomeReport from "../pages/homes/reports/total/TotalHomeReport";
import OpeningBalancesIndex from "../pages/billing_management/opening_balances/OpeninigBalancesIndex";
import StatsIndex from "../pages/stats/StatsIndex";
import { requestsBaseDetails } from "../pages/requests/RequeststBase";
import MonthlyConsumption from "../pages/requests/MonthlyConsumption";
import origin_routes from "../pages/origin/origin.routes";
import incubators_routes from "../pages/incubator/incubators.routes";
import Notifications from "../pages/notifications/Notifications";
import TabsIndex from "../pages/stocks/stock_tabs/TabsIndex";
import home_routes from "../pages/home_details/home.routes";
import ApprovalsTabs from "../pages/approvals/ApprovalsTabs";
import ApprovalDetails from "../pages/approvals/ApprovalDetails";
import EggsWarehouseTabs from "../pages/eggs_warehouse/EggsWarehouseTabs";
import EggsWarehouse from "../pages/eggs_warehouse/EggsWarehouse";
import DepartmentEggsWarehouse from "../pages/homes/DepartmentEggsWarehouse";
import ProjectEggsWarehouse from "../pages/Projects/ProjectEggsWarehouse";
import Index from "../pages/index/Index";
import Preferences from "../pages/preferences/Preferences";
import Targets from "../pages/targets/Targets";
import CollectionCenter from "../pages/collection-centers/CollectionCenter";
import ProjectsTabs from "../pages/Projects/components/ProjectsTabs";
import DepartmentTargets from "../pages/homes/targets/DepartmentTargets";
import CollectionCenterDetails from "../pages/collection-centers/CollectionCenterDetails";


const AppRouter = () => {
  const { user } = useAuthContext();

  const protectComponent = (component) => {
    if (user) return component;
    else return <Navigate to="/login" />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          {/*<Route path='/register' element={<Register />} />*/}

          <Route element={<MainLayout />} errorElement={<ErrorHandler />}>

            <Route index element={protectComponent(<Index />)} />
            
            <Route path="preferences" element={protectComponent(<Preferences />)} />



            <Route path="statistics" element={protectComponent(<StatsIndex />)} />
            {/*<Route path="operators" element={<Operators />} />*/}
            {/*<ProtectedRoute path="operators" element={<Operators />} />*/}

            <Route path="operators" element={protectComponent(<Operators />)} />
            <Route path="notifications" element={protectComponent(<Notifications />)} />

            <Route path="invoices" element={protectComponent(<Invoices />)} />
            <Route path="target" element={protectComponent(<Targets />)} />
            <Route path="collection-center" element={protectComponent(<CollectionCenter />)} />
            <Route path="collection-center/:id" element={protectComponent(<CollectionCenterDetails />)} />
            <Route path="billing-management" element={protectComponent(<BillingManagementIndex />)}>
              <Route path="opening-balances" element={protectComponent(<OpeningBalancesIndex />)} />
              <Route path="egg-storage-units" element={protectComponent(<EggStorageUnitsIndex />)} />
              <Route path="important-stats" element={protectComponent(<ImportantStatsIndex />)} />
              <Route path="weight-balances" element={protectComponent(<WeightBalancesIndex />)} />
              <Route path="sales-invoices" element={protectComponent(<SalesInvoicesIndex />)} />
            </Route>
          <Route path="egg-storage-units/:id" element={protectComponent(<CartoonDetails />)} />

            {/*users and roles routes*/}
            <Route
              path="users"
              element={protectComponent(
                <MainContainer details={usersMainContainerDetails} />
              )}>
              <Route path="base" element={protectComponent(<UserBase />)} />
              <Route path="roles" element={protectComponent(<Roles />)} />
            </Route>
            {/* Salaries / wages */}
            <Route
              path="salaries"
              element={protectComponent(<SalariesIndex />)}
            />
            <Route
              path="salaries/:id"
              element={protectComponent(<SalaryProfile />)}
            />
            <Route
              path="salaries/:id/operations"
              element={protectComponent(<UserOperations />)}
            />
            {/* <Route path="stocks" element={protectComponent(<Stocks />)}></Route> */}
            {/* <Route path="stocks/:id" element={protectComponent(<TabsIndex />)} /> */}

            <Route
              path="requests"
              element={protectComponent(
                <MainContainer details={requestsBaseDetails} />
              )}>
              <Route path="base" element={protectComponent(<RequestIndex />)} />
              <Route
                path="month-orders"
                element={protectComponent(<MonthlyConsumption />)}
              />
              {/*<Route path='request-details/:id' element={protectComponent(<RequestDetails />)} />*/}
            </Route>
            <Route
              path="request-details/:id"
              element={protectComponent(<RequestDetails />)}
            />
            <Route
              path="warehouse-details"
              element={protectComponent(
                <MainContainer details={warehouseDetails} />
              )}>
              <Route
                path="item-cards"
                element={protectComponent(<ItemCards />)}>
                <Route
                  path="feed"
                  element={protectComponent(<WareHouseFeed />)}>
                  <Route path="buy" element={protectComponent(<BuyFeed />)} />
                  <Route
                    path="class"
                    element={protectComponent(<ClassFeed />)}
                  />
                  <Route path="list" element={protectComponent(<ListFeed />)} />
                </Route>
                <Route
                  path="packaging"
                  element={protectComponent(<SupplyAndPackaging />)}>
                  <Route
                    path="buy"
                    element={protectComponent(<BuySupplements />)}
                  />
                  <Route
                    path="balances"
                    element={protectComponent(<ProjectsBalances />)}
                  />
                  <Route
                    path="export"
                    element={protectComponent(<ClassExportingReports />)}
                  />
                </Route>
                <Route
                  path="maintenance"
                  element={protectComponent(<Spareparts />)}>
                  <Route
                    path="buy"
                    element={protectComponent(<BuySpareparts />)}
                  />
                  <Route
                    path="export"
                    element={protectComponent(<SpareClassExportingReports />)}
                  />
                </Route>
                <Route
                  path="drugs"
                  element={protectComponent(<DrugsAndImmunity />)}>
                  <Route path="buy" element={protectComponent(<BuyDrugs />)} />
                  <Route
                    path="class"
                    element={protectComponent(<DrugClass />)}
                  />
                  <Route
                    path="export"
                    element={protectComponent(<DrugsClassExportingReports />)}
                  />
                </Route>
              </Route>
              <Route path="orders" element={protectComponent(<Orders />)}>
                <Route
                  path="drugs"
                  element={protectComponent(<OrderDrugs />)}
                />
                <Route path="feed" element={protectComponent(<OrderList />)} />
                <Route
                  path="packaging"
                  element={protectComponent(<OrderSupplements />)}
                />
                <Route
                  path="maintenance"
                  element={protectComponent(<OrdersSpareparts />)}
                />
              </Route>
            </Route>
            <Route path="projects">
              <Route index element={protectComponent(<ProjectsBase />)} />
              <Route path=":id">
                <Route
                  path="departments"
                  element={protectComponent(<ProjectsTabs />)}
                />
              </Route>
              <Route
                path="reports"
                element={protectComponent(<ProjectsReportIndex />)}>
                <Route
                  path="feed"
                  element={protectComponent(<ProjectsFeedReports />)}>
                  <Route
                    path="feed-reports"
                    element={protectComponent(<FeedReports />)}
                  />
                  <Route
                    path="feed-movement"
                    element={protectComponent(<FeedMovementReports />)}
                  />
                </Route>
                <Route
                  path="birds-notes"
                  element={protectComponent(<BirdsNotes />)}>
                  <Route
                    path="inputs-menu"
                    element={protectComponent(<BirdsNotesMenu />)}
                  />
                </Route>
                <Route
                  path="drug"
                  element={protectComponent(<BirdsDrugConsumption />)}>
                  <Route
                    path="drugs-invoices"
                    element={protectComponent(
                      <DrugsAndFortificationsReports />
                    )}
                  />
                  <Route
                    path="drugs-consumption"
                    element={protectComponent(<DrugsConsumptionReports />)}
                  />
                  <Route
                    path="drugs-transportation"
                    element={protectComponent(
                      <DrugsAndFortificationsMovementReports />
                    )}
                  />
                </Route>

                <Route
                  path="maintenance"
                  element={protectComponent(<HousesMaintenanceReports />)}>
                  <Route
                    path="maintenance-invoices"
                    element={protectComponent(
                      <HousesMaintenanceInvoicesReports />
                    )}
                  />
                  <Route
                    path="maintenance-operations"
                    element={protectComponent(<HouseMaintainedOperations />)}
                  />
                </Route>
                <Route
                  path="packaging"
                  element={protectComponent(<PackagingInvoiceReports />)}>
                  <Route
                    path="packaging-invoices"
                    element={protectComponent(<PackagingInvoiceReportsList />)}
                  />

                  <Route
                    path="packaging-balances"
                    element={protectComponent(
                      <PackagingProjectsBalancesReports />
                    )}
                  />
                  <Route
                    path="maintenance-reports"
                    element={protectComponent(<ProjectsPackingReportsList />)}
                  />
                </Route>

                <Route
                  path="others"
                  element={protectComponent(<OtherProjectsReports />)}>
                  <Route
                    path="other-buy-invoices"
                    element={protectComponent(
                      <ProjectsOthersExpensesInvoices />
                    )}
                  />
                  <Route
                    path="other-invoices"
                    element={protectComponent(
                      <ProjectsOthersExpensesOperationsInvoices />
                    )}
                  />
                </Route>
              </Route>
            </Route>
            <Route path="project/eggs-warehouse/:id" element={protectComponent(<ProjectEggsWarehouse />)} />

            <Route
              path="departments/:id"
              element={protectComponent(
                <MainContainer details={homesDetails} />
              )}>
              <Route path="homes" element={protectComponent(<HomeIndex />)}>
                <Route path="base" element={protectComponent(<DepartmentHomes />)} />
                <Route path="feed-invoices" element={protectComponent(<DepartmentHomeFeedInvoice />)} />
              </Route>
              <Route path="details" element={protectComponent(<DepartmentDetails />)} />
              <Route path="reports" element={protectComponent(<DepartmentReports />)}>
                 <Route path="daily" element={protectComponent(<DailyHomeReport />)} />
                <Route path="total" element={protectComponent(<TotalHomeReport />)} />
              </Route>
              <Route path="salaries" element={protectComponent(<DepartmentSalaries />)} />
              <Route path="eggs-warehouse" element={protectComponent(<DepartmentEggsWarehouse />)} />
              <Route path="targeted" element={protectComponent(<DepartmentTargets />)} />
            </Route>

            <Route
              path="prices"
              element={protectComponent(
                <MainContainer details={pricesDetails} />
              )}>
              <Route
                path="current"
                element={protectComponent(<CurrentPrices />)}
              />
              <Route
                path="previous"
                element={protectComponent(<PreviousPrices />)}
              />
            </Route>
            {/* home/:id  and sub routes  */}
            {home_routes.router(protectComponent)}


            <Route path="stocks/:id" element={protectComponent(<TabsIndex />)} />

            <Route
              path="warehouses"
              element={protectComponent(<OriginAndWarehouseContainer />)}>
              {origin_routes.router(protectComponent)}

              <Route path="stocks" element={protectComponent(<StockIndex />)} />
            </Route>
            {/* handles  /incubators */}
            {incubators_routes.router(protectComponent)}
            
            <Route path="approvals">
                <Route index  element={protectComponent(<ApprovalsTabs />)} />
                <Route path=":id" element={protectComponent(<ApprovalDetails />)} />
            </Route>
            <Route path="eggs-warehouse">
                <Route index  element={protectComponent(<EggsWarehouse />)} />
            </Route>
            <Route path="test" element={protectComponent(<TestingHelperIndex />)} />
          </Route>

          <Route
            path="login"
            element={!user ? <LoginPage /> : <Navigate to="/" />}
          />
          <Route path="/*" element={<NotFondPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
