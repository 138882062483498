import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useCollectionCenter from '../../../hooks/origins/useCollectionCenter';
import useFetch from "../../../hooks/useFetch";
import { apis } from "../../../config/apis";
import { createFormHandler, InputNumber, InputSelect, Signboard } from "../../../config/imports";
import { uuid } from '../../../helpers/id.helper';

export default function Transfer(props) {



  const collectionCenterId = useParams().id
  const { options } = useCollectionCenter()

  const { data: connected, isLoading } = useFetch(apis.invoices.sell.connectedWeights(collectionCenterId))

  const [connectedWeights, setConnectedWeights] = useState([])


  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");



  const [state, setState] = useState({
    collection_center: collectionCenterId,
    new_collection_center: '',
    items: []
  })

  useEffect(() => {
    if (connected) {
      setConnectedWeights(connected)
    }
  }, [connected])

  const onWeightChange = (id, value) => {
    setConnectedWeights(pre => {
      return pre.map(weight => {
        if (weight._id === id) {
          weight.amount = +value
        }
        return weight
      })
    })
  }

  const onSubmit = async (e) => {

    e.preventDefault();

    if (loading) return
    //
    const create_props = {
      url: apis.storages.transfer(),
      data: {
        ...state,
        items: connectedWeights
      },
      setLoading,
      setError,
      setRefresh: props.setRefresh,
      setSuccess,
      setMainData: () => {
        props.setRefresh(uuid())
      },
      setData: () => { },
      FORM_HEADER: `عملية التحويل`,
    };


    //

    await createFormHandler(create_props);

  }


  //



  return (
    <div className=''>

      <header className="text-center border-b-[1px] border-[#fcaa30] w-fit px-3 pb-2 mb-2 m-auto ">
        <h3> تحويل بين نقاط التجميع</h3>
      </header>

      <Signboard error={error} success={success} loading={loading || isLoading} />

      <div className="row">
        <div className="col-6">
          {/* <label htmlFor="" className="block mb-2">من</label> */}
          {/* <Select defaultValue={collectionCenterId}  searchable onChange={e => setState(pre => ({ ...pre, collection_center: e }))} >
            {options.map(o => {
              // should pass the text to search
            return <Option key={o.value} value={o.value} text={o.text}>
                <div className="flex items-center gap-2 whitespace-nowrap truncate">
                  <GiEggClutch className="text-[20px] fill-[#fcaa30]" />
                  <span  className="truncate text-end">{o.text}  </span>
                </div>
              </Option>
            })}
          </Select> */}
          <InputSelect options={options} title="من" className="w-full" defaultValue={collectionCenterId} handleInputChange={e => setState(pre => ({ ...pre, collection_center: e.target.value }))} />
        </div>
        <div className="col-6">
          {/* <label htmlFor="" className="block mb-2">الجهه</label> */}
          {/* <Select onChange={e => setState(pre => ({ ...pre, new_collection_center: e }))} searchable>
            {options.map(o => {
              return <Option key={o.value} value={o.value} text={o.text}>
                <div className="flex items-center gap-2">
                  <GiEggClutch className="text-[20px] fill-[#fcaa30]" />
                  <span>{o.text}</span>
                </div>
              </Option>
            })}
          </Select> */}
          <InputSelect options={options} title="الى" className="w-full" handleInputChange={e => setState(pre => ({ ...pre, new_collection_center: e.target.value }))} />
        </div>
      </div>

      <div className="flex items-center flex-nowrap overflow-x-auto gap-3 ">
        {
          connectedWeights?.map((weight) => {

            if (weight.is_available) return <div key={weight._id} className='min-w-[100px] relative py-4 flex-1' >
              <InputNumber required={false} allowDecimal={false} value={weight.amount} placeholder={'0'} title={weight.name} handleInputChange={(e) => onWeightChange(weight._id, e.target.value)} className={'w-full'} />
              {/* <span className="absolute bottom-[10px] right-0 text-[12px] text-[#999]"> السعر :  {weight.price}</span> */}
              <span className="absolute bottom-[10px] right-0 text-[12px] text-[#999]"> متاح :  {weight.remaining_amount}</span>
            </div>

            else return <div key={weight._id} className='min-w-[100px] relative py-4 flex-1' >
              <label htmlFor="" className="relative bottom-2">{weight.name}</label>
              <div className="h-[40px] border-[1px] flex items-center   border-[#cdcdcd9a] w-[200px] cursor-not-allowed  rounded-md p-2 px-[12px]  bg-slate-100">
                <span className="text-sm text-gray-500">غير متوفر</span>
              </div>
              {/* <span className="absolute bottom-[0px] right-0 text-[12px] text-[#999]">سعر الوزن :  {weight.price}</span> */}
              <span className="absolute bottom-[0px] right-0 text-[12px] text-[#999]"> متاح :  {weight.remaining_amount}</span>
            </div>

          })
        }
      </div>


      <div className="row items-center">
        <div className="col">
          <button type="button" onClick={() => props.toggle()} className="close-btn">
            الغاء
          </button>
        </div>
        <div className="col d-flex justify-content-end">
          <button className="add-btn" onClick={onSubmit}>
            تاكيد
          </button>
        </div>
      </div>
    </div>
  )
}
