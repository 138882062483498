import { create } from 'zustand';
import { apis } from '../../../config/apis';
import { postData } from '../../../config/imports';



export const useTargetsStore = create((set) => ({

  data : [],
  loading : false,
  setLoading : (loading) => set(() => ({ loading })),

  fetch : async (filter= {}) => {
    set(state => ({...state , loading : true}  ))
    const response = await postData(
      apis.targets.all(),
      filter
    )

    set(state => ({...state , data : response.data , loading : false}))


    // console.log(response);
    

  }
   


}));


export default useTargetsStore