import React from "react";
import { operatorAPI, originAPI } from "../origin.helpers";
import { paths } from "../../../config/configs.env";
import Signboard from "../../../components/Signboard";
import { TableHeader, useFetch, Icons, QuickView, useRef, PageMeta } from "../../../config/imports";
import OriginMaintenanceCrud from "./maintenance_crud";
import Gadwal from "../../../shared/components/gadwal/Gadwal";

const Maintenance = () => {

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();



    const {data, error, setData: setMainData, isLoading} = useFetch(originAPI.maintenance);

    const { data: operators  , isLoading :  operatorLoading , error : operatorError} = useFetch(operatorAPI.url);

    const signboard =  {
        error : error || operatorError,
        loading : isLoading || operatorLoading
    }

        
    const table = [
        {  size : 1 , name : "#" , header : "#" , custom : (d , idx) => idx},
        {  size : 4 , name : "name" , header : "الاسم"  },
        {  size : 3 , name  : "ar_unit"  , header : "وحدة القياس" },
        {  size : 4 , name : "operator" , header : "المورد"  , custom : d => d.operator?.name ?? 'بدون'  },
        {  size : 3 , name : "img" , header :  'الصورة' , custom : d=>{
           return   <a href={paths.uploads + d.img} target='_blank'>
                            <img src={paths.thumbnails + d.img} alt={d.name} width={40} />
                        </a>
          } },
        {  size : 5 , name : "info" , header : "التفاصيل"  , custom : d => d.info || "-" },
        {  size : 2 , name : "" , header : "" , custom : d => {
            return <div className="flex items-center gap-2">
                       <button  onClick={()=> updateRef.current.toggle(d)}><Icons.Edit /></button>
                       <button  onClick={()=> deleteRef.current.toggle(d)}><Icons.Delete /></button>
            </div>
        }}
    ]




  return ( <>

      <PageMeta title={"الصيانة"} />

        <QuickView ref={createRef}><OriginMaintenanceCrud.Create  operators={operators}  setMainData={setMainData}/></QuickView>
        <QuickView ref={updateRef}><OriginMaintenanceCrud.Update  operators={operators} setMainData={setMainData}/></QuickView>
        <QuickView ref={deleteRef}><OriginMaintenanceCrud.Delete  operators={operators} setMainData={setMainData}/></QuickView>

        <Signboard {...signboard} />
        <TableHeader  buttons={ [
            {
                title: " اضافة نوع صيانه جديد ",
                onclick: () => createRef.current.toggle()
            }
        ]}/>

        <div className="" style={{marginTop: '150px'}}></div>
     
        <Gadwal data={data} table={table}   className="mt-3" />
    </>
  );
};

export default Maintenance;
