import React, { useState } from "react";
import { apis } from "../../../config/apis";
import { setInputsToDefault, updateFormHandler, } from "../../../helpers/form.helper";
import Signboard from "../../../components/Signboard";
import { EditForm } from "../../../config/imports";
import { useCollectionCenterStore } from "../store/collection-center.store";
import { generateRandomColor } from "../../../components/targets/targets.hook";

export default function ChangeCollectionCenterColor(props) {
  const { close, item } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const refetch = useCollectionCenterStore((state) => state.setRefetch);
  const [color, setColor] = useState(generateRandomColor());

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const create_props = {
      url: apis.collectionCenter.color(),
      data: {
        color,
        collection_id: item._id,
      },
      setLoading,
      setError,
      setSuccess,
      setMainData: () => {
        refetch();
      },
      setInputsToDefault,
      setData: setColor,
    };

    await updateFormHandler(create_props);
  };

  return (
    <div className="p-2 h-fit">
      <header className="text-center w-fit px-3 m-auto ">
        <h3 className="border-b-[1px] border-[#fca119] pb-3">
          تعديل نقطة التجميع
        </h3>
      </header>

      <Signboard error={error} success={success} loading={loading} />

      <EditForm onSubmit={handleFormSubmit} toggle={close}>

        <>
        <div className="row align-items-center">
          <div className="col-4">
            <label htmlFor="color">اختر اللون</label>
          </div>
          <div className="col-8">
            <input type="color" id="color" name="color" value={color}
              onChange={(e) => setColor(e.target.value)}
              className="form-control"
            />
          </div>
        </div>
        </>

      </EditForm>
    </div>
  );
}
