import React, { useState } from 'react'
import { apis } from '../../../config/apis';
import { createFormHandler, setInputsToDefault, updateFormHandler } from '../../../helpers/form.helper';
import Signboard from '../../../components/Signboard';
import { EditForm, InputText } from '../../../config/imports';
import { useCollectionCenterStore } from '../store/collection-center.store';

export default function UpdateCollectionCenter(props) {

  const {close , item} = props

  

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const refetch = useCollectionCenterStore(state => state.setRefetch)


  const [data , setData] = useState({
      name : item?.name,
  })


  const handleInputChange = (e) => {
      const {name, value} = e.target;
      setData((prev) => ({
          ...prev,
          [name]: value,
      }));
  };

  const handleFormSubmit = async (e) => {
      e.preventDefault();
      
      const create_props = {
          url: apis.collectionCenter.update(item?._id),
          data : {
              ...data,
          },
          setLoading,
          setError,
          setSuccess,
          setMainData : ()=>{
              refetch();
          },
          setInputsToDefault,
          setData,
          FORM_HEADER: `الاضافة`,
      };
      await updateFormHandler(create_props);
  };




  return(
  <div className='p-2 h-fit'>
    
      <header className="text-center w-fit px-3 m-auto ">
          <h3 className='border-b-[1px] border-[#fca119] pb-3'>تعديل نقطة التجميع</h3>
      </header>

      <Signboard error={error} success={success} loading={loading}/>

      <EditForm onSubmit={handleFormSubmit} toggle={close} onChange={handleInputChange}>
          <InputText className={'col-12 '} defaultValue={data.name}  name='name'  placeholder={'p1'}  title={'اسم نقطة التجميع'} />
      </EditForm>


  </div>
  )

}
