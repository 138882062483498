import {useState} from "react";
import {apis, reports_api, weight_api} from "../../../../config/apis";
import {createFormHandler, setInputsToDefault} from "../../../../helpers/form.helper";
import {useParams} from "react-router-dom";
import {
    _date,
    EditForm,
    InputDate,
    InputNumber, InputSelect,
    InputText,
    InputTextArea,
    PageMeta,
    Signboard
} from "../../../../config/imports";
import ArrayHelpers from "../../../../helpers/array.helpers";
import InputMassUnit from "../../../../components/Shared/InputMassUnit";
import InputOperator from "../../../../shared/components/operator/InputOperator";

export default function Create(props){

    const {setMainData, toggle , operators} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        name : '',
        measuringUnit : '',
        operator : '',
        info : '',
    })

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: apis.origins.feed.create(),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };
        console.log(create_props.data)

        await createFormHandler(create_props);
    };
    return(
        <>
            <PageMeta title={' اضافة نوع علف جديد  '} originTitle={' الاعلاف'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>اضافة نوع علف جديد</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
                <InputText name={'name'} title={'الاسم'} value={data.value} />
                <InputMassUnit setData={setData} />
                {/* <InputSelect name={'operator'} title={'المورد '} value={data.operator}  options={ ArrayHelpers.makeOptionsForInputSelect(operators)} /> */}


                <InputOperator  />
                <InputTextArea name={'info'} title={'التفاصيل'} value={data.info} />


            </EditForm>

        </>
    )
}
