import { IoIosColorPalette } from "react-icons/io";
import React from 'react';
import { apis } from '../../../config/apis';
import useFetch from '../../../hooks/useFetch';
import { useParams } from 'react-router-dom';
import PageMeta from '../../../components/PageMeta';
import Helpers from "../../../helpers/helpers";
import { cn } from "../../../helpers/class_name.helpers";
import { _date } from "../../../helpers/date.helpers";
import Signboard from "../../../components/Signboard";
import useTargetsHook from "../../../components/targets/targets.hook";


export default function Targeted() {

  const id = useParams().id

  const api = apis.homes.target(id)

  const {data , isLoading} = useFetch(api)

  const { colorSet  , changeColors} = useTargetsHook(data?.levels, data?.result)
  
  return (
    <div className='flex-1 flex flex-col  w-full '>
        <PageMeta title={"المستهدف"}  />
        <Signboard loading={isLoading} />
        <div className="flex justify-end mb-2 items-stretch ">
          <button onClick={changeColors} className='flex items-center gap-1 border-border border-[1px] px-3 py-2 rounded-md bg-slate-200'>
            <span>تغيير الالوان</span>
            <IoIosColorPalette className="text-[27px]" />
          </button>
        </div>
        <div className='flex w-full flex-nowrap items-stretch  overflow-auto flex-1 border-l-[1px] bg-white'>
          {data?.result?.map((item , index) => {
            return (
              <div className={cn(
                "min-w-[180px]  border-border border-[1px] border-l-0  bg-white p-0 min-h-full"
              )} key={index}>
                  <div className="min-h-[100px] flex  flex-col h-full">
                      <header className='w-full border-b-[1px] border-b-border flex items-center justify-center p-2 bg-slate-100 '>
                          <h3 className='font-[400] text-[18px] mb-0'>{item.ar_month}</h3>
                      </header>
                      <div className="flex-1">
                        <div className="p-2  flex flex-col  gap-2 h-fit ">
                          {
                            item.levels.map((level , idx) => {
                              const _colors = colorSet[level.level_id]
                              return (
                                <div key={idx} className='flex flex-col gap-2 flex-1'>
                                  <div style={{ borderColor: _colors.border, backgroundColor: _colors.bg }}
                                    className="flex-1 rounded-md p-2  border-[1px] ">
                                      <div className="flex flex-col  mt-2 gap-1  text-[14px]">
                                          <span className="flex-1 font-[600]" style={{ color  : _colors.color }} >المستهدف</span>
                                          <span className="flex-1 " style={{ color  : _colors.color }} >{Helpers.Number.commaFormat(level.target_count)}</span>
                                      </div>
                                      <div className="flex flex-col  mt-2 gap-1  text-[14px]">
                                      <span className="flex-1 font-[600]" style={{ color  : _colors.color }} >عدد الدجاج</span>
                                          <span className="flex-1" style={{ color  : _colors.color }} >{Helpers.Number.commaFormat(level.checken_number)}</span>
                                      </div>
                                      <div className="flex flex-col  mt-2 gap-1  text-[14px]">
                                      <span className="flex-1 font-[600]" style={{ color  : _colors.color }} > تاريخ البدء</span>
                                          <span className="flex-1" style={{ color  : _colors.color }} >{_date(level.start_date).local()}</span>
                                      </div>
                                      <div className="flex flex-col  mt-2 gap-1  text-[14px]">
                                      <span className="flex-1 font-[600]" style={{ color  : _colors.color }} > تاريخ الانتهاء</span>
                                          <span className="flex-1" style={{ color  : _colors.color }} >{_date(level.end_date).local()}</span>
                                      </div>
                                  </div>
                                </div>
                              )
                            }
                            )
                          }
                        </div>
                      </div>
                      <div className="h-[50px] border-t-[1px] border-t-border flex items-center justify-center bg-green-100">
                            {Helpers.Number.commaFormat(item.total_count)}
                      </div>
                  </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

