import "../styles/sidenav.scss";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLogout } from "../hooks/useLogout";
import { useAuthContext } from "../hooks/useAuthContext";
import { useFetch } from "../config/imports";
import { company, paths } from "../config/configs.env";
import { apis } from "../config/apis";

const SideNav = () => {

  const { data: NAV_LINKS_DATA } = useFetch(apis.sideNav())
  const [stored, setStored] = useState(JSON.parse(window.localStorage.getItem("NAV_LINKS_DATA")) ?? [])

  const { logout } = useLogout()
  const handleSignOut = () => {
    logout()
  }


  useEffect(() => {
    if (NAV_LINKS_DATA) {
      window.localStorage.setItem("NAV_LINKS_DATA", JSON.stringify(NAV_LINKS_DATA))
    }
  }, [NAV_LINKS_DATA])



  // console.log({stored , NAV_LINKS_DATA})





  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [img, setImg] = useState()

  const { user } = useAuthContext()


  useEffect(
    () => {
      if (user) {
        setUsername(user.user_name)
        setEmail(user.email)
        setImg(user.img)
      }
    }
  )

  const [sideNavState, setSideNavState] = useState(false);


  useEffect(() => {
    // when resize window less 992px should close side nav
    window.onresize = (e) => {
      if (e.target.innerWidth < 992) {
        setSideNavState(true);
        // toggleSideNavHandler()
        toggler()
      }
    }

  })

  useEffect(() => {
    // when render check window width and toggle if less than 992
    if (window.innerWidth < 992) {
      setSideNavState(true);
      toggler()
    }
  }, [])


  const toggleSideNavHandler = () => {
    setSideNavState(!sideNavState);
    toggler()
  };



  const sideNavLinks = NAV_LINKS_DATA ?? stored
  const toggler = () => {
    const sideNavElm = document.querySelector(".side-nav-comp");
    const menu = document.querySelector(".menu-tgl");


    if (!sideNavState) {
      sideNavElm.classList.add("tgl-nav");
      menu.classList.add("rotate-menu");

    }
    else {
      sideNavElm.classList.remove("tgl-nav");
      menu.classList.remove("rotate-menu");
    }


  }

  return (
    <div className="side-nav-container side-nav-comp ">
      <div className="show-hide-btn-container d-flex justify-content-end">
      </div>
      <div className="side-nav-content-container ">
        <div className="side-nav-header">
          <div className="side-nav-head">

            {
              !sideNavState ? (
                <header>
                  <h3 className="capitalize">{company.name}</h3>
                  <img src="/logo/logo.png" alt="company name" />
                </header>
              ) : null
            }

            <button className="menu-tgl" onClick={toggleSideNavHandler}>
              {
                //  <i className="fa-solid fa-chevron-left " style={{ fontSize: "24px" , color : '#777' }} />
                sideNavState ?
                  <i className="fa-solid fa-chevron-right" style={{ fontSize: "24px", color: '#777' }} /> :
                  <i className="fa-solid fa-chevron-left " style={{ fontSize: "24px", color: '#777' }} />
              }
            </button>

          </div>
          <div className={"nav-links " + !sideNavState ? " mt-3 " : ""}>
            {
              !sideNavState ? (
                <header>القائمة</header>
              ) : null
            }
            <ul>
              {sideNavLinks.map((link) => (
                <li className=" nav-link-container" key={link.id} id={link.id}>
                  <NavLink to={link.path} className="row">
                    <div className="col-2 ico-container">
                      <img src={paths.uploads + link.icon} alt={''} width={'20px'} />
                    </div>
                    <div className="col-6 whitespace-nowrap" title={link.title}>
                      {link.title}
                    </div>
                    <div className="col-2">
                      <span className="nav-notifications-count">
                        {link.notificationsCount}
                      </span>
                    </div>
                  </NavLink>
                </li>
              ))}





            </ul>
          </div>
        </div>
        <div>
          <div className="nav-profile-container">
            {
              !sideNavState ? (
                <header>
                  <span className="muted">الملف الشخصي</span>
                </header>
              ) : null
            }
            <div className="side-nav-profile row">
              <div className={sideNavState ? "col-12 text-center" : "col-2"}>
                <img 
                 className="min-w-[40px]"
                 src={img ? paths.uploads + 'users/' + img : '/icos/profile.png'} alt="profile" />
              </div>
              {
                !sideNavState ? (
                  <div className="col-8">
                    <p className="profile-name">{username && username}</p>
                    <p className="profile-role muted">{email}</p>
                  </div>
                ) : null
              }

            </div>
          </div>
          <div className="nav-sign-out text-center">
            <NavLink onClick={handleSignOut} to='/login' className="flex items-center" >
              <img src="/icos/logout.png" alt="sign out" className="w-[20px] block ml-2 " />
              {
                !sideNavState ? (
                  <span className="">تسجيل خروج</span>
                ) : null
              }
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
