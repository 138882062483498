import {useState} from "react";
import {apis, reports_api, weight_api} from "../../../../config/apis";
import {setInputsToDefault, updateFormHandler} from "../../../../helpers/form.helper";
import {
    _date,
    EditForm,
    InputDate,
    InputNumber, InputSelect,
    InputText,
    InputTextArea,
    PageMeta,
    Signboard
} from "../../../../config/imports";
import {useParams} from "react-router-dom";
import ArrayHelpers from "../../../../helpers/array.helpers";
import InputMassUnit from "../../../../components/Shared/InputMassUnit";

export default function Update(props){

    const {id} = useParams();
    const {toggle , item , setMainData , operators } = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        name : item.name,
        measuringUnit : item.measuringUnit,
        operator : item.operator,
        info : item.info,
    })
    // console.log({item})
    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: apis.origins.feed.update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };
        console.log(update_props.data)


        await updateFormHandler(update_props);
    };
    // console.log({item})
    return(
        <>
            <PageMeta title={'تعديل نوع العلف'} originTitle={' الاعلاف'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>   تعديل نوع العلف</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>

                <InputText name={'name'} title={'الاسم'} defaultValue={data.name} />

                <InputMassUnit setData={setData} value={data.measuringUnit}/>

                <InputSelect name={'operator'} title={'المورد '} defaultValue={data.operator._id}  options={ ArrayHelpers.makeOptionsForInputSelect(operators)} />
                
                <InputTextArea name={'info'} title={'التفاصيل'} defaultValue={data.info} />

            </EditForm>
        </>
    )
}
