import { useMemo, useState } from "react";

const useTargetsHook = (levels , data   ) => {

  const [rand , setRand] = useState(0)

  const changeColors = () => {
    setRand(Math.random())
  }


  const {colors , colorSet} = useMemo(() => {
    if (!data) return [];

    const colorSet = {};

    const colors = new Array(levels.length).fill(0).map((_, idx) => {
      let color, light;
     
      color = generateRandomColor();
      light = adjustColorLightness(color, 0.2);
      

      return colorSet[levels[idx]] = {
        bg: light,
        border: color,
        color: color,
      };
    });

    return {colors , colorSet};

  }, [data , rand]);



  return {
    colors,
    colorSet,
    changeColors
  }

}


export default useTargetsHook





export function adjustColorLightness(hexColor, factor = 1) {
  factor = Math.max(0, Math.min(1, factor));

  hexColor = hexColor.replace('#', '');

  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  const newR = Math.round(r * factor + 255 * (1 - factor));
  const newG = Math.round(g * factor + 255 * (1 - factor));
  const newB = Math.round(b * factor + 255 * (1 - factor));

  return `#${newR.toString(16).padStart(2, '0')}${newG.toString(16).padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
}


export function generateRandomColor() {
  const randomValue = () => Math.floor(Math.random() * 256);

  const r = randomValue();
  const g = randomValue();
  const b = randomValue();

  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}
