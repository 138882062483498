import { _date, Signboard, useFetch } from "../../config/imports";
import { prices_api } from "../../config/apis";
import PageMeta from "../../components/PageMeta";
import Map from "../../components/Map";
import Table from "../../components/_gadwal/Table";
import Accordion from "../../components/ui/accordion/Accordion";
import { useEffect } from "react";
import { setBreadCrumbsStore } from "../../components/breadcrumbs/store/breadcrumb.store";
import { homeBreadCrumb, oldPricesBreadCrumb } from "../../components/breadcrumbs/data/breadcrumbs.data";


export default function PreviousPrices(props){


    const {data : history , error : historyError ,isLoading : loading  } = useFetch( prices_api.history() )

    const signboard = { error : historyError , loading : loading}

    const table = [
        { header : "اسم الكارتونه"  , name : "carton" , size : 4 },
        { header : "اسم الوزن"  , name : "weight" , size : 4 },
        { header : "السعر القديم"  , name : "old_price" , size : 4 },
        { header : "السعر الجديد"  , name : "new_price" , size : 4 },
        { header : "تاريخ اخر تعديل"  , name : "updatedAt" , size : 4 , custom : d =>_date(d?.updatedAt).dmy() },
    ]


    useEffect(()=>{
        setBreadCrumbsStore([
            homeBreadCrumb,
            oldPricesBreadCrumb,
        ])
    } , [])


    return(
                <div className='bg-white r-16 p-3'>
                    <Signboard {...signboard}/>
                    <PageMeta title="الاسعار السابقه" />
                        <Map  data={history} map={({ prices , collection_center , collection_center_id}) =>{
                            return (
                                <div key={ collection_center_id } className="my-2"> 
                                    <Accordion  header={ <div className="text-lg px-3"> {collection_center} </div> }>
                                        <div key={collection_center_id} className=" rounded-md my-3 p-3">
                                            <Table data={prices} table={table} />
                                        </div>
                                    </Accordion>
                                </div>
                            )
                    }}/>

                </div>
    )
}

