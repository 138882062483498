import { create } from 'zustand';



export const useTargetFilterStore = create((set) => ({
 filter : {
  collections_center_ids:[],
  projects_ids: [],
  departments_ids: [],
  homes_ids: [],
  start_year: undefined,
  end_year: undefined,
 },

 currentDepartmentId : undefined,
 currentProjectId : undefined,

 setCurrentProjectId : (id) => set((state) => ({ ...state , currentProjectId : id})),
 setCurrentDepartmentId : (id) => set((state) => ({ ...state , currentDepartmentId : id})),


  setCollectionsCenterIds : (ids) => set((state) => ({ ...state ,      
     filter: {
      ...state.filter,
       collections_center_ids : ids
      }
       })),
  setProjectsIds : (ids) => set((state) => ({ ...state ,      
     filter: {
      ...state.filter,
       projects_ids :  ids
      }
      })),
  setDepartmentsIds : (ids) => set((state) => ({ ...state ,      
     filter: {
      ...state.filter,
       departments_ids :  ids
      }
      })),
  setHomesIds : (ids) => set((state) => ({ ...state ,      
     filter: {
      ...state.filter,
       homes_ids :  ids
      }
      })), 

  setStartYear : (year) => set((state) => ({ ...state ,      
     filter: {
      ...state.filter,
       start_year :  year
      }
      })),
  setEndYear : (year) => set((state) => ({ ...state ,      
     filter: {
      ...state.filter,
       end_year :  year
      }
      })),
   


}));


export default useTargetFilterStore