import { useParams } from "react-router-dom"
import useTargetFilterStore from "../../targets/stores/targets-filter.store"
import useTargetsStore from "../../targets/stores/targets.store"
import useBreadCrumb from "../../../components/breadcrumbs/hooks/breadcrumb.hook"
import { setProjectsBreadCrumbs } from "../../../components/breadcrumbs/store/breadcrumbs.default"
import { useEffect } from "react"
import useMeta from "../../../hooks/useMeta"



export default function useDepartmentsTargets() {

  const {id} = useParams()
  
  const fetch = useTargetsStore((state) => state.fetch)

  const filter = useTargetFilterStore((state) => state.filter)


  const setCurrentProjectId = useTargetFilterStore((state) => state.setCurrentProjectId)
  const setCurrentDepartmentId = useTargetFilterStore((state) => state.setCurrentDepartmentId)









  const {data : crumbs}  = useBreadCrumb({id , name : "department"})


  const projectId = crumbs.filter(crumb => crumb.model === "project" || crumb.section === "project")[0]?.id




  useEffect(() => {

    if(id && projectId){
      setCurrentProjectId(projectId)
      setCurrentDepartmentId(id)
    }

  }, [projectId , id])

  
 

  useEffect(() => {
      if(!crumbs || !crumbs.length) return
      const projectCrumb = crumbs.filter(crumb => crumb.model === "project" || crumb.section === "project")[0]
      const departmentCrumb = crumbs.filter(crumb => crumb.model === "department" || crumb.section === "department")[0]

      const _crumbs = [
          {name : projectCrumb.name , to : `/projects/${projectCrumb.id}/departments`},
          // TODO : check why name is not returning
          {name : departmentCrumb.name ?? "القسم" , to : `/departments/${departmentCrumb.id}/homes/base`},
          {name : "المُستهدف" , to : `/departments/${departmentCrumb.id}/targeted` }
      ]

      setProjectsBreadCrumbs.departmentHomes(_crumbs)
  } , [crumbs])


  

 

  useEffect(() => {



    
    if(filter.projects_ids.length === 0 && filter.departments_ids.length === 0  && filter.homes_ids.length === 0) {

      filter.projects_ids = []
      filter.departments_ids = [id]
      filter.homes_ids = []


      fetch(filter)

    }else if( filter.homes_ids.length > 0) {
      filter.projects_ids = []
      filter.departments_ids = []
      fetch(filter)
    }
    
  } , [filter , id ])


  useMeta({
    title : 'المُستهدف'
  })

}
