import { SiHomeadvisor } from "react-icons/si";
import { BiSubdirectoryLeft } from "react-icons/bi";
import { AiOutlineClear } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";
import { BiCheck } from "react-icons/bi";
import { BiChevronDown } from "react-icons/bi";
import { useEffect, useState } from 'react';
import { cn } from "../../../helpers/class_name.helpers";
import useDebounce from "../../../hooks/useDebounce";
import useProjects from "../../../hooks/origins/useProjects";

export default function InputHomes({ onChange = () => {} , projectId = null , departmentId = null}) {


  const [state , setState] = useState(false)
  const [search , setSearch] = useState("")
  const debounced = useDebounce(search)
  const [options , setOptions] = useState([])

  const [selectedIds , setSelectedIds] = useState([])

  const [homesNames , setHomesNames] = useState([])


  const {projects} = useProjects()



  useEffect(() => {
      if(!projects) return 


      const options = [...projects?.map(p => ({
        value : p._id,
        text : p.name,
        selected : false,
        departments : p.departments.map(dep => ({
          text : dep.name,
          value : dep._id,
          selected : false,
          homes : dep?.homes?.map(home => ({
            text : home.name,
            value : home._id,
            selected : false
          }))
        }))
      }))]

      if(projectId && departmentId){

    
        const selectedProject = options?.filter(p => p.value === projectId)[0]
   
        const selectedDepartment = selectedProject?.departments?.filter(d => d.value === departmentId)

        let filtered = {
          ...selectedProject,
          departments : selectedDepartment
        }
        
        return setOptions([filtered ]) 

      }


      if(projectId){
       return setOptions(options.filter(d => d.value === projectId))
      }






      setOptions( options)



  

  }, [projects , projectId , departmentId])


  

  const filtered = options.filter(d => d.text.toLowerCase().includes(debounced.toLowerCase()))



  useEffect(() => {
    const handler = (e) => {
      if(e.target.closest('[data-keep-home-input-open]')) return
      setState(false)
    }

    document.addEventListener('click' , handler)

    return () => {
      document.removeEventListener('click' , handler)
    }
  }, [])


  const setSelected = ( homeId) => {
    setSelectedIds(pre => {
      if(pre.includes(homeId)){
        return pre.filter(id => id !== homeId)
      }
      return [...pre , homeId]
    })
  }


  const isSelected = (homeId) => {
    return selectedIds.includes(homeId)
  }


  const clearSelected = () => {
    setSelectedIds([])
  }



  useEffect(() => {
    if(onChange)
      onChange( selectedIds )
    
  }, [selectedIds])


  const howManySelected = selectedIds.length



  return <div className='w-full relative'>

      <header>
        <button data-keep-home-input-open  onClick={() => setState(!state)} 
        className={cn(
          'w-full justify-center  whitespace-nowrap bg-[#fcaa30] text-white  px-3 h-[40px] flex items-center gap-2 rounded-md',
          state && 'bg-[#eb971a]'
        )}>
          <div className="flex items-center text-white gap-1">

            <SiHomeadvisor className="text-white fill-white text-[24px]" />
               البيوت  &nbsp;
            {howManySelected > 0 && <>({howManySelected})</>}
          </div>
           <BiChevronDown className={cn(
            "text-white fill-white text-[24px]",
            state && 'rotate-180'
           )}/>

        </button>
      </header>




      <div data-keep-home-input-open  className={cn(
        "absolute w-[300px]  top-[130%] shadow-md rounded-md  z-50 bg-white  overflow-hidden",
        "py-3 flex flex-col",
        state ? 'max-h-[500px]' : 'h-0 p-0',
      )}>

        <header className="px-2 flex justify-between items-center mb-2">
          <h3 className="text-[18px] font-[500] text-gray-500 mb-0">
            البيوت  ({filtered.length})
          </h3>

          <button onClick={clearSelected}
              className="flex items-center gap-1 hover:text-red-500 group">
                حذف
              <AiOutlineClear className="text-[24px] fill-slate-500 group-hover:fill-red-500" />
              {howManySelected > 0 && <>({howManySelected})</>}
          </button>
        </header>
        <div className="px-2 ">
          <div className="bg-[#f7f7f8] rounded-md flex items-center justify-center mb-2">
            <span className="w-[40px]  text-center flex items-center justify-center">
              {/* <BiSearchAlt2 className="text-[24px] fill-slate-500"  /> */}
              <CiSearch className="text-[24px] fill-slate-500" />
            </span>
            <input data-keep-collection-menu-open type="search" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='بحث' 
            className='w-full h-[45px]  text-[16px] text-[#333] outline-none border-none m-0  bg-transparent' />  

          </div>

        </div>

        <div className="flex flex-col gap-2   overflow-y-scroll hide-scrollbar flex-1 px-4">
            {filtered.map((item) => {
              return (
                <div key={item.value} >
                    <div className="flex items-center relative">
                      
                      <span className="block text-gray-500 mb-2">{item.text}</span>
                    </div>
                  {
                    item.departments.map((dep) => (
                      <div  key={dep.value} className="px-2" >
                          <div className="">
                              <div className="flex items-center relative mb-1 text-gray-400 ">
                                {/* <span className="text-slate-500 absolute right-[5px] bottom-[17px]">|</span> */}
                                <BiSubdirectoryLeft className="text-[24px] fill-amber-400" />
                                <span className="text-gray-500">{dep.text}</span>
                              </div>

                           <div className="px-2">
                            { 
                                  dep.homes?.map((home) => (
                                  <button onClick={() => setSelected(home.value)}   key={home.value} 
                                      className={cn(
                                        "w-full cursor-pointer text-end px-3 py-2 text-[16px] text-[#333] outline-none border-none rounded-md hover:bg-slate-100",
                                        "flex items-center justify-between mb-1 fle",
                                        isSelected(home.value) && 'bg-slate-100'
                                      )}>

                                      <div className="flex items-center relative">
                                        {/* <span className="text-slate-500 absolute right-[5px] bottom-[17px]">|</span> */}
                                        <BiSubdirectoryLeft className="text-[24px] fill-green-400" />
                                        <span className="">{home.text}</span>
                                      </div>

                                        {isSelected(home.value) && <BiCheck className="text-[24px] fill-slate-400" />}

                                    </button>
                                  ))
                                }

                                {!dep.homes && (
                                    <div className="flex items-center relative px-3 py-2">
                                      <BiSubdirectoryLeft className="text-[24px] fill-slate-400" />
                                      <span className="text-red-500">لا يوجد بيوت</span>
                                    </div>
                                )}
                           </div>
                          </div>
                    </div>
                    ))
                  }
                </div>
              )
            })}
            {
              !filtered.length && (
                <div className="w-full text-end h-[40px] px-3 text-[16px] text-[#333] outline-none border-none">
                    لا توجد نتائج
                </div>
              )
            }
        </div>

      </div>


  </div>




}
