
export const homeBreadCrumb = { name : ' الرئيسية' , to : '/' }
export const preferencesBreadCrumb = { name : 'الاعدادت' , to : '/preferences' }
export const collectionCenterBreadCrumb = { name : 'نقاط التجميع' , to : '/collection-center' }


export const projectsBreadCrumb = { name : 'المشاريع' , to : '/projects' }
export const departmentsBreadCrumb = { name : 'الاقسام' , to : '/departments' }
export const homesBreadCrumb = { name : 'الموظفين' , to : '/homes' }
    export const homeDetailsBreadCrumb = { name : 'تفاصيل المستخدم' , to : '/details' }
    export const stagesBreadCrumb = { name : 'المراحل' , to : '/stages' }
    export const reportsBreadCrumb = { name : 'التقارير' , to : '/reports' }
        export const deadReportsBreadCrumb = { name : 'تقارير النافق' , to : '/dead' }
        export const feedReportsBreadCrumb = { name : 'تقارير الاعلاف' , to : '/feed' }
        export const eggsReportsBreadCrumb = { name : 'تقارير البض' , to : '/eggs' }
        export const drugsReportsBreadCrumb = { name : 'تقارير الادوية' , to : '/drugs' }
        export const maintenancesReportsBreadCrumb = { name : 'تقارير الصيانه' , to : '/maintenances' }
        export const otherReportsBreadCrumb = { name : 'تقارير الاخرى' , to : '/other' }
        export const cartonReportsBreadCrumb = { name : 'تقارير الكراتين' , to : '/cartons' }
        export const dishesReportsBreadCrumb = { name : 'تقارير الاطباق' , to : '/dishes' }
    export const homeOrdersBreadCrumb = { name : 'الطلبات', to : '/orders' }
        export const feedOrdersBreadCrumb = { name : 'طلبات الاعلاف' , to : '/feed' }
        export const drugsOrdersBreadCrumb = { name : 'طلبات الادوية' , to : '/drugs' }
        export const maintenancesOrdersBreadCrumb = { name : 'طلبات الصيانه' , to : '/maintenances' }
        export const otherOrdersBreadCrumb = { name : 'طلبات الاخرى' , to : '/other' }
        export const cartonOrdersBreadCrumb = { name : 'طلبات الكراتين' , to : '/cartons' }
        export const dishesOrdersBreadCrumb = { name : 'طلبات الادويه' , to : '/dishes' }
    export const homeMovementsBreadCrumb  = { name : 'التحركات', to : '/transfers' }
    export const homeEggsWarehouseBreadCrumb = { name : 'مستودع البيض', to : '/eggs-warehouse' }






export const statsBreadCrumb = { name : 'الاحصائيات' , to : '/statistics' }
export const requestsBreadCrumb = { name : 'الطلبات' , to : '/requests' }
export const monthlyRequestsBreadCrumb = { name : 'الطلبات الشهرية' , to : '/month-orders' }
export const pricesBreadCrumb = { name : 'الاسعار الحاليه' , to : '/prices' }
export const oldPricesBreadCrumb = { name : 'الاسعار السابقه' , to : '/previous' }
//-----------------------origins-----------------------------------------------
export const warehousesBreadCrumb = { name : 'المستودعات' , to : '/warehouses/stocks' }
export const originsBreadCrumb = { name : "الاصول" , to : "/origins" }
export const assetsDataBreadCrumb = { name : "بيانات الاصول" , to : "/assets-data" }

export const originCardsBreadCrumb = { name : "كروت الاصول" , to : "/origin-cards" }
    export const incubatorsBreadCrumb= { name : "الحضانات" , to : "/incubators" }
    export const hatcheriesBreadCrumb= { name : "الفقاسات" , to : "/hatcheries" }
    export const landsBreadCrumb = { name : "الاراضي" , to : "/lands" }
    export const buildingsBreadCrumb = { name : "المباني" , to : "/buildings" }
    export const poultryHousesBreadCrumb = { name : "بيوت الدواجن" , to : "/poultry-houses" }
    export const machineBreadCrumb = { name : "الالات والمعدات" , to : "/machines" }
    export const carsBreadCrumb = { name : "السيارات" , to : "/cars" }
    export const biologicalBreadCrumb = { name : "اصل حيوي" , to : "/biological" }
    export const roadsBreadCrumb = { name : "الطرق" , to : "/roads" }

export const itemsCardsBreadCrumb = { name : "كروت الاصناف" , to : "/items-cards" }
    export const feedBreadCrumb = { name : "الاعلاف" , to : "/feed" }
    export const drugsBreadCrumb = { name : "الادويه" , to : "/drug" }
    export const maintenanceBreadCrumb = { name : "الصيانة" , to : "/maintenance" }
    export const otherBreadCrumb = { name : "اخرى" , to : "/other" }
    export const cartonsBreadCrumb = { name : "الكراتين" , to : "/cartons" }
    export const dishesBreadCrumb = { name : "الاطباق" , to : "/dishes" }

export const birdBreedBreadCrumb = { name : "قائمة سلالة الطيور" , to : "/bird-breed" }
   export const breedingBreadCrumb = { name : "تربية" , to : "/breeding" }
   export const productionBreadCrumb = { name : "انتاج" , to : "/production" }

export const weightsBreadCrumb = { name : "الاوزان" , to : "/weights" }

export const billingManagementBreadCrumb = { name : "وحدلت التخزين" , to : "/billing-management" }
    export const weightBalancesBreadCrumb = { name : "ارصدة الاوزان" , to : "/weight-balances" }
    export const openingBalancesBreadCrumb = { name : " الارصده الافتتاحيه" , to : "/opening-balances" }

export const stocksBreadCrumb = { name : "المخزن" , to : "/المستودعات" }
    export const stockDetailsBreadCrumb = { name : "تفاصيل المخزن" , to : "?tab=details" }
    export const stockFeedBreadCrumb = { name : "الاعلاف" , to : "?tab=feed" }
    export const stockDrugsBreadCrumb = { name : "الادويه" , to : "?tab=drugs" }
    export const stockMaintenanceBreadCrumb = { name : "الصيانة" , to : "?tab=maintenance" }
    export const stockOtherBreadCrumb = { name : "اخرى" , to : "?tab=other" }
    export const stockCartonsBreadCrumb = { name : "الكراتين" , to : "?tab=cartons" }
    export const stockDishesBreadCrumb = { name : "الاطباق" , to : "?tab=dishes" }

export const eggsWarehouseBreadCrumb = { name : "مستوع البيض" , to : "/eggs-warehouse" }


export const invoicesBreadCrumb = { name : "الفواتير" , to : "/invoices" }
    export const invoicesListBreadCrumb = { name : "سجل الفواتير" , to : "/invoices#list" }

export const salariesBreadCrumb = { name : "الرواتب والاجور" , to : "/salaries" }

export const operatorsBreadCrumb = { name : "العملاء والموردين" , to : "/operators" }

export const usersBreadCrumb = { name : "المستخدمين" , to : "/users" }
    export const usersRolesBreadCrumb = { name : "الادوار" , to : "/roles" }


export const approvalsBreadCrumb = { name : "الموافقات" , to : "/approvals?tab=reports" }
    export const oldApprovalsBreadCrumb = { name : "الموافقات السابقه" , to : "/approvals?tab=approved" }


export const notificationsBreadCrumb = { name : "الاشعارات" , to : "/notifications" }

export const targetsBreadCrumb = { name : "المُستهدف" , to : "/target" }