import Table from 'gadwal'
import React from 'react'

export default function Gadwal({ data = [], table = [] }) {
  return (
   <div className='w-full max-h-[500px] overflow-y-auto small-scrollbar  overflow-x-auto'>
     <Table 
      fixedHeight={false}
      data={data} table={table} 
      stripped={false}
      className='min-w-[1000px]'
      headerProps={{
          className: "bg-gray-50 sticky top-0"
      }}
      bodyProps={{
          className : "hover:bg-[#F9FAFB] border-b-[1px] h-[55px]  flex items-center"
      }}
      bodyCellProps={{
        className : "truncate"
      }}
    />
   </div>
  )
}