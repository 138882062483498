import { useEffect, useRef, useCallback } from "react";

function useSelectCallbackDebounce(callback, delay = 500) {
  const timeoutRef = useRef();

  const debouncedFunction = useCallback((...args) => {
    if (timeoutRef.current !== undefined) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);

  // Cleanup the timeout when the component unmounts or when delay changes
  useEffect(() => {
    return () => {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [delay]);

  return debouncedFunction;
}

export default useSelectCallbackDebounce;
