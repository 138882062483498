import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { apis } from '../../config/apis'
import { useParams } from 'react-router-dom'
import { InputSelect, _date } from "../../config/imports"
import axiosPost from '../../helpers/axios/axios.post'
import useAlert from '../../hooks/useAlert'
import { refresh } from '../../helpers/id.helper'
import Helpers from '../../helpers/helpers'
import useStockStore from './stores/stock.store'
export default function useStock(_query) {
    const {setRefresh : setStoreRefresh , refresh : storeRefresh  , setTransfers} = useStockStore(state => state)
    const {id} = useParams()
    const [quantities , setQuantities] = useState(null)
    const [reFetch , setReFetch] = useState("")
    const [query , setQuery] = useState({
      ..._query ,
      location_id : _query?.id ?? id,
      refresh : refresh()
    })
    const [data , setData] = useState(null)

    const {Alert , setters} = useAlert()

    const setRefresh = ()=>{
      setReFetch(refresh())
      setStoreRefresh()
      setQuery({...query , refresh : refresh()})
    }



    const categories = {
      "" : "الكل",
      feed : "اعلاف",
      drug : "ادويه",
      maintenance : "صيانه",
      other : "اخرى",
      carton : "كراتين",
      dish : "اطباق",
    }

    const fetchQuantities = useCallback( async(q)=>{
        setters.loading()
        const {data , error} = await axiosPost(apis.storages.quantities() , q)
        setters.reset()
        if(error)  setters.error(error)
        else return setQuantities(data)
    } , [query])
 

    const CategoriesFilter = ()=>{

      const options = useMemo(()=>{
          const keys = Object.keys(categories)
          return keys.map(key => ({text : categories[key] , value : key}))
        } , [categories])
        
        return useMemo(()=>{
            return  <InputSelect 
                      options={options} className="col-12" 
                      defaultValue={query.category} title="اختر الصنف" 
                      handleInputChange={(e)=> setQuery({...query , category : e.target.value})}  
              />
        })
    }

    const setQueryCategory = (category)=>{
      setQuery({...query , category})
    }

     const table = [
        { name : "#"  , text : "" , size : 1  },
        {name : "type" , text : "نوع الحركه" , size : 2 },
        { name : "from" , text : "من" , size : 4 },
        { name : "to" , text : "الي" , size : 4 },
        { name : "category" , text : "الصنف" , size : 2 , custom : d => categories[d?.category] },
        { name : "sub_category" , text : "الفئة" , size : 2 },
        { name : "quantity" , text : "الكمية" , size : 3 },
        { name : "date" , text : "التاريخ" , size : 2 , custom :d =>  _date(d?.date).dmyt() },
      ]

  
      const quantitiesTable = [
        { name: "#", text: "", size: 1 },
        { name: "sub_category", text: "الصنف", size: 5 },
        { name: "quantity", text: "الكمية", size: 4, custom: (d) => Helpers.Number.commaFormat(+d?.quantity?.toFixed(2)) },
        { name: "ar_unit", text: "وحدة القياس", size: 4  },
      ];


  return {
    table,

    categories,
    quantities,
    Alert,
    setCat : setQueryCategory,
    setRefresh,
    table,
    CategoriesFilter,
    fetchQuantities,
    quantitiesTable,
    reFetch,
    query
  }
}
