import { useState } from "react";
import { apis, feed_types_api, operators_api, strain_api } from "../../../../config/apis";

import { useParams } from "react-router-dom";
import {
    EditForm, InputDate,
    InputNumber, PageMeta,
    Signboard, useFetch, createFormHandler, setInputsToDefault, InputSelect
} from "../../../../config/imports";
import ArrayHelpers from "../../../../helpers/array.helpers";
import InputOperator from "../../../../shared/components/operator/InputOperator";

const DAYS_IN_WEEK = 7,
    DAYS_IN_MONTH = 28,
    DAYS_IN_YEAR = 365.25,
    ROUND_DIGITS = 2;
export default function Create(props) {

    const { id } = useParams();
    const { setMainData, toggle, setRefresh, hasRoosterInfo } = props;

    let [data, setData] = useState({
        home: id,
        operator: '',
        strain: '',
        feed_type: [],
        feed_average_price: '',
        start_date: '',
        end_date: '',

        chicken: '',
        chicken_unit_price: '',
        totaled_chicken_price: '',

        rooster: '',
        rooster_unit_price: '',
        totaled_rooster_price: '',

        totaled_bird: '',
        totaled_bird_price: '',

        age_by_day: '',
        age_by_week: '',
        age_by_month: '',
        age_by_year: '',

        expected_cumulative_feeds: '',
        expected_cumulative_production: '',

        target_eggs_num: '',
        target_carton_num: '',
        target_dishes_num: '',

        expected_feed_grams: '',
        expected_feed_kilo: '',
        expected_feed_tons: '',
        feed_conversion_factor: '',
        estimated_feed_value: '',
    })

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const { data: strain, isLoading: strainLoading, error: strainError } = useFetch(strain_api.base)
    const { data: feed, isLoading: feedLoading, error: feedError } = useFetch(feed_types_api.base)

    let signboard = {
        error: error || strainError || feedError,
        success,
        loading: loading || strainLoading || feedLoading,
    }

    const sum = {

        totalChickenPrice: () => +(data.chicken * data.chicken_unit_price),

        totalRoosterPrice: () => +(data.rooster * data.rooster_unit_price),

        totalBirdCount: () => +(data.chicken + data.rooster),

        totalBirdPrice: () => (data.chicken * data.chicken_unit_price) + (data.rooster * data.rooster_unit_price),

        ageInWeeks: () => +((data.age_by_day) / DAYS_IN_WEEK).toFixed(ROUND_DIGITS),

        ageInMonths: () => +((data.age_by_day) / DAYS_IN_MONTH).toFixed(ROUND_DIGITS),

        ageInYears: () => +((data.age_by_day) / DAYS_IN_YEAR).toFixed(ROUND_DIGITS),

        expectedCumulativeFeeds: () => +(data.totaled_bird * data.age_by_day).toFixed(ROUND_DIGITS),

        expectedCumulativeProduction: () => +(data.totaled_bird * data.age_by_day).toFixed(ROUND_DIGITS),

        targetEggCount: () => +(sum.expectedCumulativeProduction() * .75).toFixed(ROUND_DIGITS),

        targetCartonCount: () => +(sum.targetEggCount() / 360).toFixed(ROUND_DIGITS),

        targetDishesCount: () => +(sum.targetEggCount() / 30).toFixed(ROUND_DIGITS),

        expectedFeedGrams: () => +(sum.expectedCumulativeFeeds() * 105).toFixed(ROUND_DIGITS),

        expectedFeedKilo: () => +(sum.expectedFeedGrams() / 1000).toFixed(ROUND_DIGITS),

        expectedFeedTons: () => +(sum.expectedFeedKilo() / 1000).toFixed(ROUND_DIGITS),

        feedFactor: () => +(sum.targetCartonCount() / sum.expectedFeedTons()).toFixed(ROUND_DIGITS),

        estimatedFeed: () => +((data.feed_average_price) * (sum.targetCartonCount())).toFixed(ROUND_DIGITS)

    }



    const handleInputChange = (e) => {
        const { name, value, type } = e.target;

        let updates = { ...data }

        updates = {
            ...updates,
            [name]: type === 'number' ? +value : value,
            totaled_chicken_price: sum.totalChickenPrice(),
            totaled_rooster_price: sum.totalRoosterPrice(),
            totaled_bird: sum.totalBirdCount(),
            totaled_bird_price: sum.totalBirdPrice(),

            age_by_week: sum.ageInWeeks(),
            age_by_month: sum.ageInMonths(),
            age_by_year: sum.ageInYears(),

            expected_cumulative_feeds: sum.expectedCumulativeFeeds(),
            expected_cumulative_production: sum.expectedCumulativeProduction(),

            target_eggs_num: sum.targetEggCount(),
            target_carton_num: sum.targetCartonCount(),
            target_dishes_num: sum.targetDishesCount(),

            expected_feed_grams: sum.expectedFeedGrams(),
            expected_feed_kilo: sum.expectedFeedKilo(),
            expected_feed_tons: sum.expectedFeedTons(),

            feed_conversion_factor: sum.feedFactor(),
            estimated_feed_value: sum.estimatedFeed(),
        }

        data = { ...updates }

        setData({ ...data })
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const create_props = {
            url: apis.levels.production.create(),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData: () => { },
            setRefresh,
            // setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };

        // console.log({data : create_props.data})

        await createFormHandler(create_props);
    };



    return (
        <>
            <PageMeta title={'انشاء مرحلة انتاج جديدة للبيت'} originTitle={'مراحل البيت '} />

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>انشاء مرحلة انتاج جديدة للبيت</h3>
            </header>

            <Signboard {...signboard} />

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}
            >

                {/* <InputSelect   value={data.operator} name={'operator'} options={ ArrayHelpers.makeOptionsForInputSelect(operators) } title={'المورد'} /> */}
                <InputOperator value={data.operator} />
                <InputSelect value={data.strain} name={'strain'} options={ArrayHelpers.makeOptionsForInputSelect(strain)} title={'السلاله'} />
                <InputSelect defaultValue={data.feed_type} multiple={true} name={'feed_type'} options={ArrayHelpers.makeOptionsForInputSelect(feed)} title={'نوع العلف'} />
                <InputNumber value={data.feed_average_price} placeholder='0' name={'feed_average_price'} title={' متوسط سعر العلف'} />
                <InputDate value={data.start_date} name={'start_date'} title={'تاريخ البدء'} />
                <InputDate value={data.end_date} name={'end_date'} title={'تاريخ الانتهاء'} />
                <InputNumber value={data.chicken} placeholder='0' name={'chicken'} allowDecimal={false} title={'عدد الدجاج '} />
                <InputNumber value={data.chicken_unit_price} placeholder='0' name={'chicken_unit_price'} title={'سعر وحدة الدجاج  '} />
                <InputNumber defaultValue={sum.totalChickenPrice()} name={'totaled_chicken_price'} title={'اجمالي سعر الدجاج'} />
                {
                    hasRoosterInfo && <>

                        <InputNumber handleInputChange={handleInputChange} value={data.rooster} placeholder='0' allowDecimal={false} name={'rooster'} title={'عدد الديوك'} />
                        <InputNumber handleInputChange={handleInputChange} value={data.rooster_unit_price} placeholder='0' name={'rooster_unit_price'} title={'سعر وحده الديوك  '} />
                        <InputNumber defaultValue={sum.totalRoosterPrice()} name={'totaled_rooster_price'} title={'اجمالي سعر الديوك'} />

                    </>
                }

                <InputNumber defaultValue={sum.totalBirdCount()} name={'totaled_bird'} title={'اجمالي  الطيور'} className={'col-6'} />
                <InputNumber defaultValue={sum.totalBirdPrice()} name={'totaled_bird_price'} title={'اجمالي  سعر الطيور'} className={'col-6'} />
                <><hr className={'my-3'} /></>
                <InputNumber value={data.age_by_day} placeholder='0' name={'age_by_day'} title={'عمر البيت المستهدف باليوم'} />
                <InputNumber defaultValue={sum.ageInWeeks()} placeholder='0' name={'age_by_week'} title={'عمر البيت المستهدف بالأسابيع'} />
                <InputNumber defaultValue={sum.ageInMonths()} placeholder='0' name={'age_by_month'} title={'عمر البيت المستهدف بالأشهر'} />
                <InputNumber defaultValue={sum.ageInYears()} placeholder='0' name={'age_by_year'} title={'عمر البيت المستهدف بالسنين'} />

                <InputNumber defaultValue={sum.expectedCumulativeFeeds()} placeholder='0' name={'expected_cumulative_feeds'} title={'العدد التراكمي المتوقع أعلاف'} />
                <InputNumber defaultValue={sum.expectedCumulativeProduction()} placeholder='0' name={'expected_cumulative_production'} title={'العدد التراكمي المتوقع أنتاج'} />

                <InputNumber defaultValue={sum.targetEggCount()} placeholder='0' name={'target_eggs_num'} title={'عدد البيض المستهدف'} />
                <InputNumber defaultValue={sum.targetCartonCount()} placeholder='0' name={'target_carton_num'} title={'عدد الكرتون المستهدف'} />
                <InputNumber defaultValue={sum.targetDishesCount()} placeholder='0' name={'target_dishes_num'} title={'عدد الأطباق المستهدفة'} />

                <InputNumber defaultValue={sum.expectedFeedGrams()} placeholder='0' name={'expected_feed_grams'} title={'كمية الأعلاف المتوقعة بالجرام'} />
                <InputNumber defaultValue={sum.expectedFeedKilo()} placeholder='0' name={'expected_feed_kilo'} title={'كمية الأعلاف المتوقعة بالكيلو'} />
                <InputNumber defaultValue={sum.expectedFeedTons()} placeholder='0' name={'expected_feed_tons'} title={'كمية الأعلاف المتوقعة بالطن'} />

                <InputNumber defaultValue={sum.feedFactor()} className={'col-6'} placeholder='0' name={'feed_conversion_factor'} title={'معامل تحويل العلف المستهدف'} />
                <InputNumber defaultValue={sum.estimatedFeed()} className={'col-6'} placeholder='0' name={'estimated_feed_value'} title={'قيمة العلف التقديرية'} />

            </EditForm>

        </>
    )
}
