import React from 'react'
import { apis } from '../../../config/apis';
import useFetch from '../../../hooks/useFetch';
import { _date } from '../../../helpers/date.helpers';
import Helpers from '../../../helpers/helpers';
import PreviewDetailsItem from './PreviewDetailsItem';
import { cn } from '../../../helpers/class_name.helpers';

import { ImSpinner2 } from "react-icons/im";

const api = ( type  , id)=>{
  return `${apis.invoices.base()}/${type}/${id}`
}

export default function PreviewProjectInvoice(props) {


 
  const { data   , isLoading} = useFetch(api(props.item.en_category === "feed" ? "feedbuy" :  props.item.en_category , props.item._id))
  
 
 

  return (
    <>
      <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
        <h3 className='flex items-center'>
          <span>          تفاصيل  فاتورة {data?.ar_type} { data?.ar_category } </span>
          <span className='mx-2 text-[16px] flex items-center'>
          {
              isLoading ?  <>( <ImSpinner2 className='animate-spin' /> )</> : <>( {data?.invNumber} )</> 
            }
          </span>
        </h3>
      </header>
      <div className="row items-center">
        <div className="span-5">
          <PreviewDetailsItem title="المسلسل" value={data?.invNumber} />
        </div>
        <div className="span-5">
          <PreviewDetailsItem title="رقم الفاتورة" value={data?.invoice_number} /> 
        </div>
        <div className="col-4">
          <PreviewDetailsItem title="المورد" value={data?.operator?.name} />
        </div>
        <div className="col-3">
          <PreviewDetailsItem title="تاريخ الفاتورة" value={data?.date  ? _date(data?.date).dmyt() : ""} />
        </div>
        <div className="col-3">
          <PreviewDetailsItem title="النوع" value={data?.sub_category?.name} />
        </div>
        <div className="col-3">
          <PreviewDetailsItem title="وحدة القياس" value={data?.ar_unit ?? data?.unit} /> 
        </div>
        <div className="col-3">
          <PreviewDetailsItem title="سعر الوحده" value={Helpers.Number.commaFormat(data?.unit_price)} />
        </div>
        <div className="col-3">
          <PreviewDetailsItem title="الكمية" value={Helpers.Number.commaFormat(data?.quantity)} />
        </div>
        </div>
       <div className="flex items-stretch rounded-md overflow-hidden w-full mb-3">
          <div className='flex flex-col sticky right-0 bg-white'>
                  <div className='w-[100px] p-2 border-border border-[1px] bg-slate-50 border-b-0  border-l-0'>الاسم</div>
                  <div className='w-[100px] p-2 border-border border-[1px] bg-slate-50 border-l-0'>الكمية</div>
                </div>
              <div className="border-y-[1px] flex overflow-x-auto relative hide-scrollbar flex-1">

                <div key={data?.stock?._id} className="flex flex-col flex-1">
                    <div className= "min-w-[200px]  p-2 border-border border-[1px] border-l-0 border-b-0 border-t-0 ">
                        {data?.stock?.name}
                    </div>
                    <div className= "min-w-[200px]  p-2 border-border border-[1px] border-l-0 border-b-0">
                      {Helpers.Number.commaFormat(data?.stock_quantity ?? 0)}
                    </div>
                </div>
            
                {
                  data?.home?.map((home , index) => {

                    const lastElm = data?.home?.length  - 1 === index

                    return (
                      <div key={home.home_id._id} className="flex flex-col flex-1">
                        <div
                          className={cn(
                         "min-w-[200px]  p-2 border-border border-[1px] border-l-0 border-b-0 border-t-0",
                            lastElm ? "border-l-[1px]" : "border-l-[0px]"
                          )}>
                          {home.home_id.name ?? "غير محدد"}
                        </div>
                        <div
                          className={cn(
                            "min-w-[200px]  p-2 border-border border-[1px] border-l-0 border-b-0",
                            lastElm ? "border-l-[1px]" : "border-l-[0px]"
                          )}>
                          {Helpers.Number.commaFormat(home.quantity ?? 0)}
                        </div>
                      </div>
                    );
                  })
                }
              </div>
       </div>

        <div className="row">
          <div className="col-4">
            <PreviewDetailsItem title="اسم المستودع" value={data?.stock?.name} />
          </div>
          <div className="col-4">
            <PreviewDetailsItem title="اسم المشروع" value={data?.project?.name} />
          </div>
          <div className="col-4">
            <PreviewDetailsItem title="اسم القسم" value={data?.department?.name} />
          </div>
          <div className="col-3">
            <PreviewDetailsItem title="الاجمالي قبل الضريبه" value={Helpers.Number.commaFormat(data?.total_price)} />
          </div>
          <div className="col-3">
            <PreviewDetailsItem title="نسبة الضريبه" value={data?.tax_percentage} />
          </div>
          <div className="col-3">
            <PreviewDetailsItem title="قيمة الضريبه" value={Helpers.Number.commaFormat(data?.tax_value?.toFixed(2))} />
          </div>
          <div className="col-3">
            <PreviewDetailsItem title="الاجمالي بعد الضريبه" value={Helpers.Number.commaFormat(data?.price_with_tax)} />
          </div>
          <div className="col-12">
            <PreviewDetailsItem title="الملاحظات" value={data?.more_info} valueClassName='min-h-[100px] items-start p-2 overflow-visible whitespace-normal  text-[#333]' />
          </div>
      </div>
      <div className="flex justify-end">
        <button onClick={props.toggle} className="close-btn  mt-3">اغلاق</button>
      </div>
    </>
  )
}
