import React, { useEffect } from "react";
import { accio } from "../../../helpers/axios/axios.get";
import { Selectly } from "../../../libs/select";
import { operators_api } from "../../../config/apis";
import { cn } from "../../../helpers/class_name.helpers";

import propTypes from "prop-types";

const SEARCH_LIMIT = 20


export default function InputOperator({ className, handleInputChange , ...props }) {
  
  // const { className, handleInputChange } = props;

  const [defaultValue, setDefaultValue] = React.useState();

  useEffect(() => {
    if (props.value) {
      setDefaultValue(props.value);
    } else if (props.defaultValue) {
      setDefaultValue(props.defaultValue);
    }
  }, [props.defaultValue, props.value]);

  const fetchOperators = async (search, page) => {
    const response = await accio(operators_api.names, {
      search,
      page,
      limit : SEARCH_LIMIT
    });

    const maxPageCount = response?.paginate.pages;
    
    if (response.data) {
      return {
        options : response.data.map((op) => ({
          value: op._id,
          label: op.name,
        })),
        maxPageCount
      }
    } else {
      return {
        options: [],
        maxPageCount : 0
      };
    }
  };

  const onChange = (value) => {
    if (handleInputChange) {
      const mockEvent = {
        target: {
          name: "operator",
          type : "select",
          value,
        },
      }
      handleInputChange(mockEvent);
    }

  };

  return (
    <div className={cn("col-4 h-fit ", className)}>
      <label htmlFor="" className="relative -top-[10px]">
        اختر المورد
      </label>
      <Selectly
        apiFn={fetchOperators}
        onValueChange={onChange}
        defaultValue={defaultValue}
        searchLimit={SEARCH_LIMIT}
      />
    </div>
  );
}

InputOperator.propTypes = {
  className: propTypes.string,
  handleInputChange: propTypes.func,
  defaultValue: propTypes.string,
  value: propTypes.string,
};
