import React from 'react'
import useTargetFilterStore from '../../targets/stores/targets-filter.store'
import InputDepartments from '../../../shared/components/departments/InputDepartments'
import InputHomes from '../../../shared/components/homes/InputHomes'
import InputDateRange from '../../../shared/components/date/InputDateRange'
import { useParams } from 'react-router-dom'

export default function TargetsFilters() {

  const projectId = useParams().id

  const {
      setProjectsIds,
      setDepartmentsIds,
      setHomesIds,
      setStartYear,
      setEndYear,
      currentDepartmentId,
      currentProjectId,

  }  = useTargetFilterStore()




 



  return (
    <div className='h-fit py-2'>
        <div className="flex items-center gap-2">
   
          <div className="w-fit">
             <InputHomes projectId={currentProjectId}  departmentId={currentDepartmentId}  onChange={(data) =>{
               setProjectsIds([])
               setHomesIds(data) 
               }} />
            </div> 
          <div className="w-fit">
             <InputDateRange 
              onChange={(data) => {
                  setProjectsIds([])
                  const startYear = new Date(data.start).getFullYear()
                  const endYear = new Date(data.end).getFullYear()
                   setStartYear(startYear)
                   setEndYear(endYear)
               }}
             />
          </div> 
    
        </div>
    </div>
  )
}
