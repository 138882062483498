import { BiTrash } from "react-icons/bi";
import React from 'react';
import FeedTypeSelectInput from './FeedTypeSelectInput';
import MeasuringUnitInput from './MeasuringUnitInput';
import UnitPriceInput from './UnitPriceInput';
import AmountInput from './AmountFeedInput';
import useInvoiceItemsStore from '../stores/invoice-items.store';

export default function InputFeedGroup({ data, setData }) {


  const { items, addRow, deleteRow } = useInvoiceItemsStore(state => ({
    items: state.items,
    addRow: state.addItem,
    deleteRow: state.deleteItem
  }))


  return (
    items.map((item) => (
      <div className='w-full  relative' key={item.id}>
        <div className="row g-3">

          <div className="col-3 ">
            <FeedTypeSelectInput data={data} setData={setData} item={item} />
          </div>

          <div className="col-3 ">
            <MeasuringUnitInput data={data} setData={setData} item={item} />
          </div>

          <div className="col-2 ">
            <UnitPriceInput data={data} setData={setData} item={item} />
          </div>

          <div className="col-2 ">
            <AmountInput data={data} setData={setData} item={item} />
          </div>
          <div className="col-2 ">
            <span className='mb-2 block'>الاجمالي</span>
            <span className=' border-border border-[2px] flex items-center px-2 h-[40px] rounded-md bg-slate-100'>
              {+(item.unit_price * item.quantity).toFixed(2)}
            </span>
          </div>

        </div>
        <button
          onClick={addRow}
          type="button" className='absolute top-10  -right-7 border-border border-[1px] rounded-md p-1 py-0  hover:bg-[#86d993] hover:text-white group'>
          <span className="text-[30px] group-hover:text-white leading-[20px] text-[#fcaa30]">+</span>
        </button>
        {
          items.length > 1 && (
            <>
              <button
                onClick={() => deleteRow(item.id)}
                type="button" className='absolute top-9  -left-7  border-[1px] rounded-md p-1  hover:bg-red-500 hover:text-white group'>
                <BiTrash className="!text-[20px] group-hover:fill-white fill-red-500" />
              </button>
            </>
          )
        }
      </div>
    ))
  )
}
