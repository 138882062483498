import React from 'react'
import CreateCollectionCenter from '../forms/CreateCollectionCenter'
import { createPopup } from 'airpop'
import { useCollectionCenterStore } from "../store/collection-center.store"
import { Spinner } from 'react-bootstrap'
export default function AddCollectionCenterButton() {
  const open = createPopup(<CreateCollectionCenter />)

  const loading = useCollectionCenterStore(state => state.loading)

  return (

    <div className="bg-gray-300 border-border w-[150px] h-[150px] border-[1px] rounded-lg flex items-center justify-center flex-col">
      <div onClick={() => open()}
      className="flex flex-col justify-center items-center gap-2 hover:cursor-pointer">
        {!loading && <img src="/icos/add-square.png" alt="add"/>}
        { loading && <Spinner animation="border" size="md" />}
        <p className="font-[500] mb-0">اضافة نقطة تجميع</p>
      </div>
    </div>

  )
}
