import React, { useMemo } from 'react'
import { useCollectionCenterStore } from '../store/collection-center.store'
import CollectionCenterItem from './CollectionCenterItem'

export default function CollectionCenters({isFavorite = false}) {

  let data = useCollectionCenterStore(state => state.data)

  const centers = useMemo(() => {
    const fav = []
    const notFav = []

    data.forEach(c => {
      if(c.system_info){
        const isFavByUser = c.system_info.is_favorite
        if(isFavByUser) fav.push(c)
        else notFav.push(c)
      }
      else notFav.push(c)
    })

    if(isFavorite) return fav
    else return notFav
  }, [data, isFavorite])
  


  return (
    <div className='flex gap-2 flex-wrap'>
        {centers.map(c =><CollectionCenterItem key={c._id} data={c} />  )}
    </div>
  )
}
