import React, { useEffect, useState } from 'react'
import useEggsWarehouseStore from '../store/eggs_warehouse.store'
import { apis } from '../../../config/apis'
import axios from 'axios'
import axiosAuthParams from '../../../helpers/axios/axios.auth.params'
import StatsGroup from './StatsGroup'
import Signboard from '../../../components/Signboard'
import EggsTableContent from './EggsTableContent'
import useEggsWarehouseFilterStore from '../store/eggs-warehouse-filter.store'

export default function EggsWarehouseTable() {
  const formatted = useEggsWarehouseStore(state => state.formatted)
  const filter = useEggsWarehouseFilterStore(state => state.filter)
  const submit = useEggsWarehouseFilterStore(state => state.submit)

  const [loading , setLoading] = useState(false)
  // const submit = useEggsWarehouseStore(state => state.submit)
  const [data , setData] = useState({
    totals_egg_process : [],
    egg_info_in_array : {
       date : '',
       cartons : []
    }
  })

    useEffect(() => {


      const controller = new AbortController();
      const signal = controller.signal;
      const fetch = async () => {
        setLoading(true)
        try {
            const response = await axios.post(apis.eggs_warehouse.all(),{ 
                projects_ids: filter.projects_ids,
                departments_ids: filter.departments_ids,
                homes_ids: filter.homes_ids
             } , {
                signal : signal ,
                ...axiosAuthParams()
            } );
            setLoading(false)
            setData(response.data.data)

          } catch (error) {
              setLoading(false)
              if (axios.isCancel(error)) {
                  console.log('Request canceled', error.message);
              } else {
                  console.error('Error fetching data:', error);
              }
          }

      }


      if(submit)
         fetch()
      



      // return () => {
      //   controller.abort();
      // };


    } , [submit])




  return (
    <>


    
      
      <div className='page mt-4'>

        <Signboard loading={loading}  />
        <StatsGroup data={data.totals_egg_process} />

      </div>
      
      <div className='page mt-4'>
        <Signboard loading={loading}  />
        <EggsTableContent data={data.egg_info_in_array} rest={data}  />
      </div>
      
    
    </>
  )
}

// import React, { useEffect, useState } from 'react'
// import useEggsWarehouseStore from '../store/eggs_warehouse.store'

// import { apis } from '../../../config/apis'
// import axios from 'axios'
// import axiosAuthParams from '../../../helpers/axios/axios.auth.params'
// import StatsGroup from './StatsGroup'
// import Signboard from '../../../components/Signboard'
// import EggsTableContent from './EggsTableContent'
// import EggsWarehouseComponent from '../../../components/eggs_warehouse/EggsWarehouseComponent'

// export default function EggsWarehouseTable() {
//   const formatted = useEggsWarehouseStore(state => state.formatted)

//   const submit = useEggsWarehouseStore(state => state.submit)


//   return (
//     <>

//        <EggsWarehouseComponent  filter={formatted} deps={[submit]}   /> 

//     </>
//   )
// }
