import { create } from "zustand";
import { accio } from "../../../helpers/axios/axios.get";
import { apis } from "../../../config/apis";
import { showErrorToast } from "../../../stores/toast.store";
import { uuid } from "../../../helpers/id.helper";


export const useCollectionCenterStore = create((set) => ({
  data: [],
  loading : false,
  refetch : undefined,
  user : {
    id : "",
  },

  setLoading : (loading) => set(() => ({ loading })),

  setData: (data) => set(() => ({ data })),
  
  fetch : async () => {
      set(state => ({...state , loading : true} ))
    try {
      const response = await accio(apis.collectionCenter.get())
      set(state => ({...state , loading : false} ))
      set(state => ({...state , data : response.data.collection_centers  , user : response.data.user} ))
    }
    catch (error) {
      set(state => ({...state , loading : false} ))
      showErrorToast(error)
    }

  },
  
  setRefetch : () => set(() => ({ refetch : uuid() }))


}));