import React, { useEffect } from 'react'

export default function useMeta({title = ''}) {


  const BASE_NAME = process.env.REACT_APP_COMPANY_NAME

  useEffect(()=>{

    document.title =  BASE_NAME + ' | ' + title


    return ()=>{
      document.title = BASE_NAME
    }


  } , [])



}
