
import {
    Signboard,
    TableHeader,
    PageMeta,
    useFetch,
    _date,
    Grid,
    Table,
    Icons,
    QuickView
} from "../../../config/imports";
import { useRef } from "react";
import { invoices_api, weight_api } from "../../../config/apis";
import { Link } from "react-router-dom";
import WeightCrud from "./crud";

export default function Weight(props) {
    const deleteRef = useRef(),
        createRef = useRef(),
        updateRef = useRef()


    const { data, error, isLoading, setData } = useFetch(weight_api.all)
    const signboard = {
        error: error,
        loading: isLoading
    }

    //
    // const data = [
    //     { _id : "651161aef3213" ,  code : 'B', weight : 100  , createdAt : new Date() , updatedAt : new Date(), info : 'info about the weight' },
    //     { _id : "651161aef1234" ,  code : 'C', weight : 45  , createdAt : new Date() , updatedAt : new Date(), info : 'info about the weight' },
    //     { _id : "651161aef1235" ,  code : 'D', weight : 120  , createdAt : new Date() , updatedAt : new Date(), info : 'info about the weight' },
    //     { _id : "651161aef1236" ,  code : 'E', weight : 220  , createdAt : new Date() , updatedAt : new Date(), info : 'info about the weight' },
    //     { _id : "651161aef1237" ,  code : 'F', weight : 250  , createdAt : new Date() , updatedAt : new Date(), info : 'info about the weight' },
    //     { _id : "651161aef1238" ,  code : 'G', weight : 50  , createdAt : new Date() , updatedAt : new Date(), info : 'info about the weight' },
    //     { _id : "651161aef1239" ,  code : 'H', weight : 500  , createdAt : new Date() , updatedAt : new Date(), info : 'info about the weight' },
    //     { _id : "651161aef123a" ,  code : 'I', weight : 330  , createdAt : new Date() , updatedAt : new Date(), info : 'info about the weight' },
    //     { _id : "651161aef123b" ,  code : 'J', weight : 150  , createdAt : new Date() , updatedAt : new Date(), info : 'info about the weight' },
    // ]

    const sizes = {
        serial: 1,
        name: 3,
        weight: 4,
        info: 7,
        createdAt: 3,
        updatedAt: 3,
    }


    const header = {
        serial: <Grid.Span size={sizes.serial}></Grid.Span>,
        name: <Grid.Span size={sizes.name}>الرمز</Grid.Span>,
        weight: <Grid.Span size={sizes.weight}>الوزن بالجرام</Grid.Span>,
        info: <Grid.Span size={sizes.info}>     معلومات اضافيه    </Grid.Span>,
        createdAt: <Grid.Span size={sizes.createdAt}>  تاريخ الاضافة </Grid.Span>,
        updatedAt: <Grid.Span size={sizes.updatedAt}>  تاريخ  اخر تعديل   </Grid.Span>,
        settings: <Grid.Span size={sizes.settings}> </Grid.Span>,
    }



    return <div className='base-card bg-white r-16 p-2 mt-2'>

        <QuickView ref={createRef}><WeightCrud.Create setMainData={setData} /></QuickView>
        <QuickView ref={updateRef}><WeightCrud.Update setMainData={setData} /></QuickView>
        <QuickView ref={deleteRef}><WeightCrud.Delete setMainData={setData} /></QuickView>

        <PageMeta title={' إضافة الاوزان '} />
        <TableHeader buttons={[
            {
                title: " اضافة وزن جديد",
                onclick: () => createRef.current.toggle()
            }
        ]} />
        <Table.Header header={header} />

        <Signboard {...signboard} />
        {
            data && data.length > 0 && data.map((weight, index) => (
                <Grid.FlexResponsive key={weight._id} className={'align-items-center data-row'}>
                    <Grid.Span size={sizes.serial}>{index + 1}</Grid.Span>
                    <Grid.Span size={sizes.name}>{weight.name}</Grid.Span>
                    <Grid.Span size={sizes.weight}>{weight.weight}</Grid.Span>
                    <Grid.Span size={sizes.info}>{weight.info}</Grid.Span>
                    <Grid.Span size={sizes.createdAt}>{_date(weight.createdAt).simple('date')}</Grid.Span>
                    <Grid.Span size={sizes.updatedAt}>{_date(weight.updatedAt).simple('date')}</Grid.Span>
                    <Grid.Span size={sizes.settings} className='settings-buttons'>
                        <button onClick={() => deleteRef.current.toggle(weight)} > <Icons.Delete />  </button>
                        <button onClick={() => updateRef.current.toggle(weight)} > <Icons.Edit />  </button>
                    </Grid.Span>
                </Grid.FlexResponsive>
            ))
        }





    </div>;
}
