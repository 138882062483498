import React, { useEffect, useState } from "react";
import useBreadCrumb from "../../../components/breadcrumbs/hooks/breadcrumb.hook";
import { collectionCenterBreadCrumb, homeBreadCrumb, } from "../../../components/breadcrumbs/data/breadcrumbs.data";
import { useParams } from "react-router-dom";
import { setBreadCrumbsStore } from "../../../components/breadcrumbs/store/breadcrumb.store";
import { postData } from "../../../config/imports";
import { apis } from "../../../config/apis";
import { showErrorToast } from "../../../stores/toast.store";

export default function useCollectionCenterDetails() {
  const id = useParams().id;

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const { data: crumbs } = useBreadCrumb({ id, name: "collection_center" });


  const [refresh, setRefresh] = useState(undefined);

  useEffect(() => {
    setBreadCrumbsStore([
      homeBreadCrumb,
      collectionCenterBreadCrumb,
      { name: crumbs?.[0]?.name, to: `/collection-center/${crumbs?.[0]?.id}` },
    ]);
  }, [crumbs]);


  const fetch = async () => {
    setLoading(true);
    const response = await postData(apis.eggs_warehouse.collectionCenter(), {
      collections_center_ids: [id],
    });
    setLoading(false);

    if (response.error) {
      showErrorToast(response.error);
      return;
    }
    setData(response.data);
  };

  useEffect(() => {

    fetch();
  }, [id]);

  useEffect(() => {
    if (refresh)  fetch();
  }, [refresh]);




  useEffect(() => {
    if (refresh) {
      setTimeout(() => { setRefresh(undefined); }, 1000)
    }
  }, [refresh]);


  return {
    loading,
    data,
    setRefresh
  };
}
