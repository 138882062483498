import { useState } from "react";
import {
  EditForm,
  FormInput,
  InputSelect,
  InputText,
  InputTextArea,
  InputTextAsNumber,
  PageMeta,
  Signboard,
  setInputsToDefault,
  createFormHandler
} from "../../config/imports";
import { operatorAPI } from "./operator.routes";

const CreateOperator = (props) => {
  const { toggle, setMainData } = props;

  const FORM_HEADER = "إضافة عملاء او موردين أو مناديب";

  const MAIN_TITLE = "إضافة عملاء او موردين أو مناديب";

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const operatorTypes = [
    { _id: "مورد", name: "مورد" },
    { _id: "عميل", name: "عميل" },
    { _id: "مندوب", name: "مندوب" },
  ];

  const [data, setData] = useState({
    name: "",
    type: "",
    place: "",
    city: "",
    phone: "",
    mobile: "",
    email: "",
    mail_box: "",
    website: '',
    tax_number: "",
    postal_code: "",
    more_info: "", //12
  });

  const handleFormSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

    const create_props = {
      url: operatorAPI.base,
      data,
      setLoading,
      setError,
      setSuccess,
      setMainData,
      setInputsToDefault,
      setData,
      FORM_HEADER: ` إضافة  ${data.type} بنجاح`,
    };

    await createFormHandler(create_props);


  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      <PageMeta title={FORM_HEADER} originTitle={MAIN_TITLE} />

      <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
        <h3>{FORM_HEADER}</h3>
      </header>

      <Signboard error={error} success={success} loading={loading} />

      <EditForm
        onSubmit={handleFormSubmit}
        toggle={toggle}
        visible={true}
        onChange={handleInputChange}>
        <InputText title={"الاسم"} className={"col-12 col-sm-12 col-md-6 col-lg-6"} name={"name"} value={data.name} placeholder={"محمد"} />
        <InputSelect title={"النوع"} className={"col-12 col-sm-12 col-md-3 col-lg-3"} name={"type"} value={data.type} options={operatorTypes} />
        <InputText title={"المكان"} className={"col-12 col-sm-12 col-md-3 col-lg-3"} name={"place"} value={data.place} placeholder={"السعودية"} />
        <InputText title={"المدينة"} className={"col-12 col-sm-12 col-md-4 col-lg-4"} name={"city"} value={data.city} placeholder={"الرياض"} />
        <InputTextAsNumber value={data.phone} className={"col-12 col-sm-12 col-md-4 col-lg-4"} name={"phone"} label={"الهاتف"} invalid={"من فضلك ادخل ارقام فقط"} placeholder={"012345"} />
        <InputTextAsNumber value={data.mobile} className={"col-12 col-sm-12 col-md-4 col-lg-4"} name={"mobile"} label={"رقم الموبيل"} invalid={"من فضلك ادخل ارقام فقط "} placeholder={"012345"} />
        <FormInput className={"col-12 col-sm-12 col-md-6 col-lg-6"} name={"email"} label={"البريد الالكتروني"} type={"email"} value={data.email} placeholder={"a@a.com"} />
        <InputText title={"الرقم الضريبى"} className={"col-12 col-sm-12 col-md-3 col-lg-3"} name={"tax_number"} value={data.tax_number} placeholder={"9xx-xx-xxxx"} />
        <InputText title={"الرمز البريدى"} className={"col-12 col-sm-12 col-md-3 col-lg-3"} name={"postal_code"} value={data.postal_code} placeholder={"012345"} />
        <InputText title={"صندوق البريد"} className={"col-12 col-sm-12 col-md-6 col-lg-6"} name={"mail_box"} value={data.mail_box} placeholder={"St.No 134 Riyadh 145-134"} />
        <InputText title={"الموقع الالكتروني"} className={"col-12 col-sm-12 col-md-6 col-lg-6"} name={"website"} value={data.website} placeholder={"www.example.com"} />
        <InputTextAsNumber label={"السجل التجاري الخاص بالمؤسسة"} className={"col-12 col-sm-12 col-md-6 col-lg-6"} name={"commercial_register"} value={data.commercial_register} placeholder={"1010123456"} />
        <InputTextAsNumber label={" الرقم الوطني او رقم الاقامة"} className={"col-12 col-sm-12 col-md-6 col-lg-6"} name={"residency_number"} value={data.residency_number} placeholder={"2345678901"} />
        <InputTextArea title={"لمحة موجزة"} className={"col-12"} name={"more_info"} value={data.more_info} placeholder={"اكتب هنا لمحة موجزة عن العميل"} />
      </EditForm>
    </>
  );
};

export default CreateOperator;
