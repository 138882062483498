import { BiTransferAlt } from "react-icons/bi";
import React, { useRef } from 'react';
import QuickView from '../../components/QuickView';
import Transfer from './forms/Transfer';
import { Signboard } from '../../config/imports';
import EggsWarehouseBoxes from '../../components/eggs_warehouse/EggsWarehouseBoxes';
import EggsWarehouseTable from '../../components/eggs_warehouse/EggsWarehouseTable';
import useCollectionCenterDetails from "./hooks/useCollectionCenterDetails";

export default function CollectionCenterDetails() {
 

  const transferRef = useRef(null)

  const { loading , data , setRefresh} =  useCollectionCenterDetails()


  return (
      <>
        <QuickView ref={transferRef}  modalClassName="overflow-visible" contentClassName="overflow-visible" ><Transfer setRefresh={setRefresh}  /></QuickView>
        <div className='page mt-4 overflow-x-hidden '>
            <Signboard loading={loading} />
            <EggsWarehouseBoxes data={data?.totals_egg_process} />
        </div>
        <div className='page mt-4 overflow-x-hidden '>
           <button className='add-btn !py-2 !px-5  flex items-center gap-2' onClick={() => transferRef.current.toggle()}>
             <span className="text-white">تحويل</span> 
             <BiTransferAlt className="text-[20px] fill-white" />
          </button>
        </div>
        <div className='page mt-4 overflow-x-hidden px-5'>
            <Signboard loading={loading} />
            <EggsWarehouseTable data={data?.egg_info_in_array} rest={data}  />
        </div>
      </>
  )
}
