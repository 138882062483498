import React from 'react';
import PageMeta from "../../components/PageMeta";

import { accio } from '../../helpers/axios/axios.get';
import { operators_api } from '../../config/apis';
import { Selectly } from '../../libs/select';





const TestingHelperIndex = (props) => {

  const fetchOperators = async (search, page ) => {
    const response = await accio(operators_api.names, { 
      search, 
      page 
    });
    if (response.data) {
      return response.data.map(op => ({
        value: op._id,
        label: op.name
      }));
    } else {
      return [];
    }
  };

  const onChange = (value) => {
      // console.log({value})
  }
  

  return (
    <>
      <PageMeta title="testing " />
      <Selectly apiFn={fetchOperators} onValueChange={onChange} multiple    />
    </>
  );
};


export default TestingHelperIndex;