import React from 'react'

export default function FavCollectionCenter(props) {
  console.log(props);
  





  return (
    <div>
      <header>
        <h3>
          إضافة نقطة التجميع الى المفضله
        </h3>
      </header>


    </div>
  )
}
