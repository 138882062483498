import { apis } from "../../../config/apis"
import { _date } from "../../../helpers/date.helpers"
import useFetch from "../../../hooks/useFetch"
import useUnits from "../../../hooks/useUnits"
import { Table } from "../../../layout/table"

export default function HomeMovementDetails(props) {
  const { toggle, item, categories } = props
  const { data, Alert } = useFetch(apis.storages.movementDetails(item?.movement_id))
  const { getUnitName } = useUnits()


  const table = [
    { name: "#", text: "#", size: 1 },
    { name: "ar_type", text: "النوع ", size: 3 },
    { name: "from", text: "من", size: 4 },
    { name: "to", text: "الي", size: 4 },
    // { name : "category" , text : "الصنف" , size : 2 , custom : d => categories[d?.category] },
    // { name : "sub_category" , text : "الفئة" , size : 3 },
    { name: "unit", text: "الوحده", size: 3, custom: d => getUnitName(d?.unit) },
    { name: "quantity", text: "الكمية", size: 3 },
    { name: "price", text: "سعر الوحدة", size: 3 },
    { name: "date", text: "التاريخ", size: 3, custom: d => <span style={{ fontSize: "13px" }}> {_date(d?.date).dmy()} </span> }
  ]

  return (
    <>
      <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-4">
        <h3>    تفاصيل تحرك {data && categories[data[0]?.category] + " - " + data[0]?.sub_category}      </h3>
      </header>
      <Alert />
      <Table data={data} table={table} />
      <button onClick={toggle} className='add-btn mt-[20px]'> الغاء </button>
    </>
  )
}
