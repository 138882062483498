import { useMemo } from 'react'
import { apis } from '../../config/apis'
import useFetch from '../useFetch'
import { InputSelect } from '../../config/imports'
import { Group } from '../../helpers/helpers'

export default function useCollectionCenter() {

    const {data : centers} = useFetch(apis.collectionCenter.names())
 

    const options = useMemo(() => {
        if(!centers) return []
        return [...centers.map(d => ({value : d?._id , text : d?.name}))]
    }, [centers])


    const groupedCenters = useMemo(() => {
        if(!centers) return 
        return Group(centers).byId
    }, [centers])


    const getById = (id) => {
        if(!centers || !id) return undefined
        return groupedCenters[id]
    }


    const getName = (id) => {
        if(!centers || !id) return undefined
        return getById(id)?.name
    }


    const InputCenters = useMemo(() => (props) => 
        <InputSelect  {...props} options={options} className={ (props.className || "") + " w-full"  } />
    , [options])


  return {
    options,
    getName,
    getById,
    Input : InputCenters
  }
}
