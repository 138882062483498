import React from 'react'
import { Spinner } from 'react-bootstrap'
import useTargetsStore from '../../targets/stores/targets.store'

export default function TargetsTable() {

  const data = useTargetsStore((state) => state.data)

  const loading = useTargetsStore((state) => state.loading)

  return (
    <div className='row'>
        {
          data.map((target) => (
            <div className='col-3 border-[1px]  p-0 flex flex-col' key={target.month}>
              <div className=' py-1 bg-gray-100 text-center flex gap-2 justify-center items-center'>
                  <span>{target.ar_month}</span>
                  {loading && <Spinner animation="border" size="sm" className='fill-amber-500 text-amber-500'  />}
                 {!loading && <span>({target.homes?.length  ?? 0}) </span> }
               </div>
              <div className="flex flex-col h-full flex-1">
                  <header className='flex-1 overflow-y-auto small-scrollbar  max-h-[160px] h-[160px]  min-h-[160px]'>
                    {target.homes?.map((home , index) => (
                     <div key={home.home_id + index} className=' border-b-[1px] border-b-border px-2'>
                          <div className="flex items-center h-[39px] sticky top-0 bg-white">
                                <div className="w-[40%]">
                                    الاســــــــم
                                </div>
                                <div className="flex-1 whitespace-nowrap truncate">
                                    {home.home_name}
                                </div>
                          </div>
                          <div className="flex items-center h-[39px]">
                                <div className="w-[40%]">
                                    المُستهدف
                                </div>
                                <div className="flex-1">
                                    {home.target_count}
                                </div>
                          </div>
                          <div className="flex items-center h-[39px]">
                                <div className="w-[40%]">
                                    الدجــــــــاج
                                </div>
                                <div className="flex-1">
                                    {home.checken_number}
                                </div>
                          </div>
                          <div className="flex items-center h-[39px]">
                                <div className="w-[40%]">
                                    العـــــــــام
                                </div>
                                <div className="flex-1">
                                    {home.year}
                                </div>
                          </div>
                     </div>
                    ))}

                    {target.homes?.length === 0 && <div className='flex-1   h-full flex justify-center items-center'>لا يوجد بيانات</div>}
                  </header>
                  <footer className='h-fit text-center bg-green-400 text-white py-1'>
                    {target.total_count}
                  </footer>
              </div>

            </div>
          ))
        }
    </div>
  )
}
