import { useEffect, useState } from 'react';
const useSelectDebounce = (value, delay = 500) => {
  const [state, setState] = useState(value);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setState(value);
    }, delay);
    return () => clearTimeout(timeout);
  }, [value, delay]);
  return state;
};
export default useSelectDebounce
