import { AiOutlineClose } from "react-icons/ai";
import React, { useEffect, useRef } from "react";
import OptionsList from "./OptionsList";

export default function SelectOptions({
  options,
  selected,
  onChange,
  onInputChange,
  searchable,
  setOpen,
  setPage,
  page,
  maxPageCount,
  input,
}) {
  const optionsRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionsRef]);

  return (
    <div
      ref={optionsRef}
      className="absolute bg-white rounded-md z-[10000]  border inset-x-0">
      {searchable && (
        <div className="p-2 ">
          <input
            value={input}
            onChange={(e) => onInputChange(e.target.value)}
            type="text"
            placeholder="ابحث هنا..."
            className="focus:outline-none !border-[1px] rounded-md h-[40px]  px-2 py-1 w-full block my-0 text-[14px] placeholder:text-[14px]"
          />

          {input && (
            <button
              onClick={() => onInputChange("")}
              className="absolute top-4 left-4 text-[20px] cursor-pointer">
              <AiOutlineClose className="fill-gray-400" />
            </button>
          )}
        </div>
      )}
      <div className="max-h-[300px] overflow-auto small-scrollbar ">
        <OptionsList
          options={options}
          selected={selected}
          onChange={onChange}
          setPage={setPage}
          page={page}
          maxPageCount={maxPageCount}
        />
      </div>
    </div>
  );
}
