import { useState } from "react";
import { projects_api, stock_api } from "../../../config/apis";
import { setInputsToDefault, updateFormHandler } from "../../../helpers/form.helper";
import {
    EditForm, InputText,
    PageMeta, Signboard,
    useFetch,
    InputSelect
} from "../../../config/imports";
import ArrayHelpers from "../../../helpers/array.helpers";
import useCollectionCenter from "../../../hooks/origins/useCollectionCenter";

export default function EditHome(props) {

    const { toggle, item, setMainData, department } = props
    const { data: stocks, error: stocksError, isLoading: stocksIsLoading } = useFetch(stock_api.base);



    console.log(item);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const { options } = useCollectionCenter()


    const signboard = {
        error: stocksError || error,
        loading: stocksIsLoading || loading,
        success: success
    }

    const [data, setData] = useState({
        name: item.name,
        stock: item.stock,
        collection_center: item.collection_center._id
    })

    // console.log(data)
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: projects_api.update(item._id),
            data: {
                name: data.name
            },
            setLoading,
            setError,
            setSuccess,
            setMainData: () => setMainData(pre => {
                let index = pre.findIndex(i => i._id === item._id)
                if (index === -1) return pre
                pre[index] = {
                    ...item,
                    stock: data.stock?._id,
                    name: data.name
                }
                return [...pre]
            }),
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };

        await updateFormHandler(update_props);
    };


    return (<>
        <PageMeta title={"تعديل  بيانات المشروع " + item.name} originTitle={' المشاريع '} />

        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3>{"  تعديل  بيانات المشروع    " + item.name}</h3>
        </header>

        <Signboard {...signboard} />


        <EditForm
            visible={props.showFooter === undefined ? true : false}
            onSubmit={handleFormSubmit}
            toggle={toggle}
            onChange={handleInputChange}>
            <InputText className={'col-12 mx-auto'} defaultValue={data.name} name={'name'} placeholder={'p1'} title={'اسم المشروع'} />
            {/* <InputSelect  className={'col-4'} defaultValue={data.stock?._id} name={'stock'} options={ ArrayHelpers.makeOptionsForInputSelect(stocks) } title={'المستودع'} />
            <InputSelect  className={'col-4'} defaultValue={data.collection_center} name={'collection_center'} options={ options } title={'نقطة التجميع'} /> */}


        </EditForm>
    </>)
}
