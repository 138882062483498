import { RxTriangleDown } from "react-icons/rx"; 
import { TbTriangleInvertedFilled } from "react-icons/tb"; 
import { AiOutlineClear } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";
import { BiCheck } from "react-icons/bi";
import { BiChevronDown } from "react-icons/bi";
import { HiCollection } from "react-icons/hi";
import { useEffect, useState } from 'react';
import useCollectionCenters from '../../../pages/collection-centers/hooks/useCollectionCenters';
import { useCollectionCenterStore } from '../../../pages/collection-centers/store/collection-center.store';
import { cn } from "../../../helpers/class_name.helpers";
import useDebounce from "../../../hooks/useDebounce";

export default function InputCollectionCenter({ onChange = () => {} }) {


  const [state , setState] = useState(false)
  const [search , setSearch] = useState("")
  const debounced = useDebounce(search)
  const [options , setOptions] = useState([])

  useCollectionCenters()

  const centers = useCollectionCenterStore(state => state.data)

  useEffect(() => {
      if(!centers) return []
      setOptions( [...centers.map(d => ({
        value : d?._id ,
        text : d?.name,
        selected : false
      }))])
  }, [centers])


  const filtered = options.filter(d => d.text.toLowerCase().includes(debounced.toLowerCase()))


 

  useEffect(() => {
    const handler = (e) => {
      if(e.target.closest('[data-keep-collection-input-open]')) return
      setState(false)
    }

    document.addEventListener('click' , handler)

    return () => {
      document.removeEventListener('click' , handler)
    }
  }, [])


  const setSelected = (id) => {
     setOptions(options.map(d => d.value === id ? {...d , selected : !d.selected} : d))
  }


  const clearSelected = () => {
    setOptions(options.map(d => ({...d , selected : false})))
  }


  useEffect(() => {
    if(onChange){
      onChange(options.filter(d => d.selected).map(d => d.value))
    }
  }, [options])


  const howManySelected = options.filter(d => d.selected).length

  return <div className='w-full relative'>

      <header>
        <button data-keep-collection-input-open  onClick={() => setState(!state)} className='w-full justify-center  whitespace-nowrap bg-[#fcaa30] text-white  px-3 h-[40px] flex items-center gap-2 rounded-md'>
          <div className="flex items-center text-white gap-1">
            <HiCollection className="text-white fill-white text-[24px]" />
              نقاط التجميع &nbsp;
              {howManySelected > 0 && <>({howManySelected})</>}
          </div>
           <BiChevronDown className={cn(
            "text-white fill-white text-[24px]",
            state && 'rotate-180'
           )}/>

        </button>
      </header>




      <div data-keep-collection-input-open  className={cn(
        "absolute w-[300px]  top-[40px] shadow-md rounded-md  z-50 bg-white  overflow-hidden",
        "py-3 flex flex-col",
        state ? 'max-h-[500px]' : 'h-0 p-0',
      )}>

        <header className="px-2 flex justify-between items-center mb-2">
          <h3 className="text-[18px] font-[500] text-gray-500 mb-0">
            نقاط التجميع ({filtered.length})
          </h3>

          <button 
           onClick={clearSelected}
           className="flex items-center gap-1 hover:text-red-500 group">
              حذف
              <AiOutlineClear className="text-[24px] fill-slate-500 group-hover:fill-red-500" />
              {howManySelected > 0 && <>({howManySelected})</>}
              
          </button>
        </header>
        <div className="px-2 ">
          <div className="bg-[#f7f7f8] rounded-md flex items-center justify-center mb-2">
            <span className="w-[40px]  text-center flex items-center justify-center">
              {/* <BiSearchAlt2 className="text-[24px] fill-slate-500"  /> */}
              <CiSearch className="text-[24px] fill-slate-500" />
            </span>
            <input data-keep-collection-menu-open type="search" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='بحث' 
            className='w-full h-[45px]  text-[16px] text-[#333] outline-none border-none m-0  bg-transparent' />  

          </div>

        </div>

        <div className="flex flex-col gap-2 px-2  overflow-y-scroll hide-scrollbar flex-1">
            {filtered.map((item) => {
              return (
                <button onClick={() => setSelected(item.value)}  key={item.value} 
                className={cn(
                  "w-full text-end h-[40px] px-3 text-[16px] text-[#333] outline-none border-none",
                  "hover:bg-gray-100 rounded-md",
                  "flex justify-between items-center py-2",
                  item.selected && 'bg-gray-100'
                )}>
                    {item.text}
                    {item.selected && <BiCheck className="text-[24px] fill-slate-400" />}
                </button>
              )
            })}
            {
              !filtered.length && (
                <div className="w-full text-end h-[40px] px-3 text-[16px] text-[#333] outline-none border-none">
                    لا توجد نتائج
                </div>
              )
            }
        </div>

      </div>


  </div>




}
