
import { useSearchParams } from "react-router-dom";
import { cn } from "../../helpers/class_name.helpers";

const NavTabs = (props) => {
  const tabs = props.tabs;

  const param = props.param ?? "tab";

  const [searchParams, setSearchParams] = useSearchParams();

  const getComponentWithParam = (param) =>
    tabs.find((tab) => tab.param === param) ?? tabs[0];

  let tabParam = searchParams.get(param);

  const isValidTab = tabs.filter((tab) => tab.param === tabParam).length > 0;

  if (!tabParam || !isValidTab) tabParam = tabs[0].param;

  const tab = getComponentWithParam(tabParam);

  const Component = tab.component;

  const tabsHandler = (tab) => {
    setSearchParams({ [param]: tab });
  };

  return (
    <>
      <div
        className={cn(
          "flex items-center justify-center gap-3 px-3 bg-white rounded-md w-fit mx-auto  py-1",
          props.className
        )}
      >
        {tabs.map((tab) => (
          <button
            key={tab.param}
            className={cn(
              "px-2 py-1 rounded-md",
              props.navClassName,
              tab.param === tabParam &&
                (props.activeClassName || "bg-[#FCA119] text-white")
            )}
            onClick={() => tabsHandler(tab.param)}
          >
            {tab.name}
          </button>
        ))}
      </div>

      <div className={props.componentClassName}>
        <Component />
      </div>
    </>
  );
};

export default NavTabs;
