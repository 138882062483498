import { create } from 'zustand'
import { apis, operators_api } from '../../../config/apis';
import { accio } from '../../../helpers/axios/axios.get';
import { showErrorToast } from '../../../stores/toast.store';
import { objectHasValues } from '../../../helpers/helpers';
import { id } from '../../../helpers/id.helper';



export const useInvoiceItemsStore = create((set) => ({
  items : [{
    "sub_category": "",
    "unit_price": 0,
    "quantity": 0,
    "total_price": "",
    "unit": "",
    id : id(),
    ar_unit : ""
  }],



  resetItems : () => {
    set(state => ({
      items : [
        { "sub_category": "",
          "unit_price": "",
          "quantity": "",
          "total_price": "",
          "unit": "",
          id : id(),
          ar_unit : ""
        }
      ]
    }))
  },

  addItem : ()=>{
    set(state => ({
      items : [
        ...state.items , {
        "sub_category": "",
        "unit_price": "",
        "quantity": "",
        "total_price": "",
        "unit": "",
        id : id(),
        ar_unit : ""
      }]
    }))
  } ,


  deleteItem : (id)=>{
    set(state => ({
      items : state.items.filter(item => item.id !== id)
    }))
  } ,



  getItems : ()=>{
    const items = useInvoiceItemsStore.getState().items
    return items.map(item => {
      const {id , ...rest} = item
      return {
        ...rest,
        total_price : item.unit_price * item.quantity
      }
    })
  },
  onItemChange : (key , value , id)=>{
    set(state => ({
      items : state.items.map(item => {
        if(item.id === id){
          return { ...item, [key] : value }
        }
        return item
      })
    }))
  },
  getTotalPrice : ()=>{
    const items = useInvoiceItemsStore.getState().items
    return items.map(item => {
      return {
        total_price : item.unit_price * item.quantity
      }
    }).reduce((a , b) => a + b.total_price , 0)
  },  
  getTotalQuantity : ()=>{
    const items = useInvoiceItemsStore.getState().items
    return items.map(item => {
      return {
        total_quantity : item.quantity
      }
    }).reduce((a , b) => a + b.total_quantity , 0)
  },




}));


export default useInvoiceItemsStore