import { useState , useMemo } from "react";
import { EditForm, InputSelect, InputText, InputTextArea, PageMeta, Signboard, updateFormHandler } from "../../../../config/imports";
import ArrayHelpers from "../../../../helpers/array.helpers";
import InputMassUnit from "../../../../components/Shared/InputMassUnit";
import { apis } from "../../../../config/apis";

export default function Update(props){

    const {toggle , item , setRefresh , operators } = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        name: item.name ,
        operator: item.operator._id ,
        info: item.info ,
        measuringUnit: item.measuringUnit
    })

    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: apis.origins.others.update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData : ()=>{},
            setRefresh ,
            setData,
            FORM_HEADER: `تعديل`,
        };
        // console.log(update_props.url)

        await updateFormHandler(update_props);
    };



    const measuringUnits = useMemo(() => {
        return [
            { text : "قطعه" , value : "piece" },
        ]
    } , [])

    
    return(
        <>
            <PageMeta title={'تعديل نوع الاخرى'} originTitle={'الاخرى'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>تعديل نوع الاخرى</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={ true }
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
                    <InputText  name={'name'} title={'الاسم'} defaultValue={data.name} />
                    <InputSelect name={ 'measuringUnit' } className={'col-4'} title={'وحدة القياس'} defaultValue={data.measuringUnit} handleInputChange={handleInputChange}  options={measuringUnits} />
                    <InputSelect  name={'operator'} title={'المورد'} defaultValue={data.operator}  options={ ArrayHelpers.makeOptionsForInputSelect(operators)} />
                    <InputTextArea name={'info'} title={'التفاصيل'} defaultValue={data.info} />
                </EditForm>
        </>
    )
}
