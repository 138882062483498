import { useState } from "react";
import { apis, feed_types_api, operators_api, strain_api, } from "../../../../config/apis";
import { _date, EditForm, InputDate, InputNumber, PageMeta, Signboard, useFetch, setInputsToDefault, updateFormHandler, InputSelect } from "../../../../config/imports";
import { useParams } from "react-router-dom";
import InputOperator from "../../../../shared/components/operator/InputOperator";

export default function Update(props){

    const {id} = useParams();
    const {toggle , item  , setRefresh , hasRoosterInfo} = props


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    const {data : operators , isLoading : operatorsLoading , error : operatorsError } = useFetch(operators_api.names)
    const {data : strain , isLoading : strainLoading , error : strainError } = useFetch(strain_api.base)
    const {data : feed , isLoading : feedLoading , error : feedError } = useFetch(feed_types_api.base)

    let signboard = {
        error : error  || strainError || feedError,
        success,
        loading : loading   || strainLoading || feedLoading,
    }

    const [data , setData] = useState({
        operator : item.operator._id ,
        start_date : item.start_date ,
        end_date : item.end_date ,
        strain : item.strain._id ,
        feed_type : item.feed_type?.map(item => item._id) ,
        chicken : item.chicken ,
        chicken_unit_price : item.chicken_unit_price ,
        rooster : item.rooster ,
        rooster_unit_price : item.rooster_unit_price ,
        totaled_chicken_price : item.totaled_chicken_price ,
        totaled_rooster_price : item.totaled_rooster_price ,
        totaled_bird : item.totaled_bird ,
        totaled_bird_price : item.totaled_bird_price ,
        feed_average_price : item.feed_average_price ,
        home : id
    })


    const handleInputChange = (e) => {
        const { name , value , type } = e.target;
        data[name] = type  === 'number' ? +value : value
        setData({
            ...data
        })
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: apis.levels.breeding.update(item._id),
            data : {
                ...data,
                totaled_chicken_price :data.chicken_unit_price * data.chicken,
                totaled_rooster_price : data.rooster * data.rooster_unit_price,
                totaled_bird : data.chicken + data.rooster,
                totaled_bird_price : (data.rooster * data.rooster_unit_price ) + (data.chicken * data.chicken_unit_price)
            },
            setLoading,
            setError,
            setSuccess,
            setMainData : ()=>{} ,
            setRefresh,
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };
  
        await updateFormHandler(update_props);
    };



    return(
        <>
            <PageMeta title={'تعديل مرحلة تربية جديدة للبيت'} originTitle={'مراحل البيت '}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>تعديل مرحلة تربية جديدة للبيت</h3>
            </header>

            <Signboard {...signboard}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}
            >

                <InputOperator value={data.operator} />
                <InputSelect   defaultValue={data.strain} name={'strain'} options={strain &&  strain.map( strain => ({  name : strain.name , _id : strain._id })) } title={'السلاله'} />
                <InputSelect   defaultValue={data.feed_type} multiple={true}  name={'feed_type'} options={feed &&  feed.map( feed => ({  name : feed.name , _id : feed._id })) } title={'نوع العلف'} />
                <InputNumber   defaultValue={data.feed_average_price} name={'feed_average_price'} title={' متوسط سعر العلف'} />
                <InputDate     defaultValue={_date(data.start_date).forInputDate()} name={'start_date'} title={'تاريخ البدء'} />
                <InputDate     defaultValue={_date(data.end_date).forInputDate()} name={'end_date'} title={'تاريخ الانتهاء'} />
                <InputNumber   defaultValue={data.chicken} name={'chicken'} title={'عدد الدجاج '} />
                <InputNumber   defaultValue={data.chicken_unit_price} name={'chicken_unit_price'} title={'سعر وحدة الدجاج  '} />
                <InputNumber   defaultValue={data.chicken_unit_price * data.chicken} name={'totaled_chicken_price'} title={'اجمالي سعر الدجاج'} />
                {
                    hasRoosterInfo && <>
                        <InputNumber  handleInputChange={handleInputChange}  defaultValue={data.rooster} name={'rooster'} title={'عدد الديوك'} />
                        <InputNumber  handleInputChange={handleInputChange}  defaultValue={data.rooster_unit_price} name={'rooster_unit_price'} title={'سعر وحده الديوك  '} />
                        <InputNumber   defaultValue={ data.rooster * data.rooster_unit_price} name={'totaled_rooster_price'} title={'اجمالي سعر الديوك'} />
                    </> 
                 }
              
                <InputNumber   defaultValue={data.chicken + data.rooster} name={'totaled_bird'} title={'اجمالي  الطيور'} className={'col-6'} />
                <InputNumber   defaultValue={ (data.rooster * data.rooster_unit_price ) + (data.chicken * data.chicken_unit_price)} name={'totaled_bird_price'} title={'اجمالي  سعر الطيور'} className={'col-6'} />

            </EditForm>
        </>
    )
}
