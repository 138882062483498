import { useEffect, useMemo, useState } from "react";
import { _date, createFormHandler, EditForm, InputDate, InputNumber, InputSelect, InputText, InputTextArea, PageMeta, setInputsToDefault, Signboard } from "../../../config/imports";
import { apis } from "../../../config/apis";
import FeedTypeSelectInput from "../shared/FeedTypeSelectInput";
import MeasuringUnitInput from "../shared/MeasuringUnitInput";
import UnitPriceInput from "../shared/UnitPriceInput";
import AmountInput from "../shared/AmountFeedInput";
import SharedHomesInputs from "../shared/SharedHomesInputs";
import TotalFromHomesInput from "../shared/TotalFromHomesInput";
import TotalPriceInput from "../shared/TotalPriceInput";
import OperatorsInput from "../shared/OperatorsInput";
import InvoiceId from "../shared/InvoiceId";
import useInvoicesApi from "./useInvoicesApi";
import InputTax from "../shared/InputTax";
import InputEnableTax from "../components/InputEnableTax";
export default function BuyWarehouseFeedInvoice(props) {

    const {
        project, department,
        setMainData, toggle,
        setRefresh,
        invoiceFor,
        stockName, stockId
    } = props

    // const [Alert , setters]= useAlert()



    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    // const project = '64fd0c1953b4852b68cd00c6'
    // const department = '64fd0c1953b4852b68cd00c8'
    const [data, setData] = useState({
        // home_id,
        invNumber: "",
        date: new Date().toISOString(),
        total_price: 0,
        isChecked: false,
        total_quantity_from_homes: 0,
        project,
        department,
        stock: "",
        stock_quantity: 0,
        invoice_type: "project",
        orders: [],
    })
    // const { data : formData  , error : formError } =  apis.invoices.form.data( 'type=buy&invoice=feed&place=project' )
    const { data: formData, error: formError, Alert, setters, OrdersElement } = useInvoicesApi({
        type: "buy", invoice: "feed",
        place: "project", place_id: department
    },
        { data, setData }

    )







    useEffect(() => {

        if (formData) {
            setData((prev) => ({
                ...prev,
                stock: stockId ?? formData?.storage_info?.location_id
            }))
        }

    }, [formData])


    useEffect(() => {
        if (error || success) {
            setTimeout(() => {
                setError("");
                setSuccess("");
            }, 2000);
        }
    }, [error, success])


    const signboard = { loading, error, success }






    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));

    };


    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        // console.log({datab4 : data , s  : formData?.storage_info});


        const create_props = {
            url: apis.invoices.buy.feed.create(),
            data: {
                ...data,
                stock: formData?.storage_info?.location_id,
                home: data?.home.filter(h => +h?.quantity > 0)
            },
            setLoading,
            setError,
            setSuccess,
            setRefresh,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };

        // console.log({data : create_props.data})
        await createFormHandler(create_props);
    };

    // console.log(data)
    return (
        <div className={''}>
            <PageMeta title={'فاتورة شراء اعلاف'} originTitle={'ادارة الفواتير'} />

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>فاتورة شراء اعلاف لقسم</h3>
            </header>

            <Signboard {...signboard} />
            <Alert />
            <EditForm visible={true} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange}>

                <InvoiceId setData={setData} type={"feedBuyInv"} className="span-5" value={formData?.inv_number} />

                <InputText name="invoice_number" className="span-5" title=" رقم الفاتورة" defaultValue={data.invoice_number} handleInputChange={handleInputChange} />

                <OperatorsInput className={'col-4'} data={data} setData={setData} />

                <InputDate className="col-3" name="date" label="تاريخ الفاتورة" defaultValue={_date(data.date).forInputDate()} handleInputChange={handleInputChange} />

                <div className="col-3">
                    <FeedTypeSelectInput data={data} setData={setData} />
                </div>
                <div className="col-3">
                    <MeasuringUnitInput data={data} setData={setData} />
                </div>
                <div className="col-3">
                    <UnitPriceInput data={data} setData={setData} />
                </div>
                <div className="col-3">
                    <AmountInput data={data} setData={setData} />
                </div>




                <OrdersElement defaultValue={data.orders} />

                <SharedHomesInputs stockName={formData?.storage_info?.stock_name} project={project} setError={setError} department={department} data={data} setData={setData} storages={formData?.home_storages} />

                <TotalFromHomesInput className="col-4" data={data} setData={setData} setError={setters.error} />


                <TotalPriceInput className="col-2" data={data} setData={setData} isProject={true} />

                <div className="col-2">
                    <InputEnableTax data={data} setData={setData} />
                </div>


                <InputTax className="col-4" data={data} setData={setData} isProject={true} />

                <InputTextArea name="more_info" title="ملاحظات" value={data.more_info} handleInputChange={handleInputChange} />

            </EditForm>

        </div>
    )
}
