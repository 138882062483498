import { EditForm, Grid, InputNumber, PageMeta, setInputsToDefault, Signboard, updateFormHandler } from "../../config/imports";
import { apis } from "../../config/apis";
import { useEffect, useMemo, useState } from "react";
import { accio } from "../../helpers/axios/axios.get";
import Map from "../../components/Map";
import { key } from "../../helpers/id.helper";
import { showErrorToast } from "../../stores/toast.store";
import { setBreadCrumbsStore } from "../../components/breadcrumbs/store/breadcrumb.store";
import { homeBreadCrumb, pricesBreadCrumb, } from "../../components/breadcrumbs/data/breadcrumbs.data";
import useCollectionCenter from "../../hooks/origins/useCollectionCenter";

export default function CurrentPrices(props) {

  const [data, setData] = useState([]);
  const [currentError, setCurrentError] = useState("");
  const [currentLoading, setCurrentLoading] = useState(false);

  const [query, setQuery] = useState({
    collection_center: undefined,
  });

  const { Input, getName, options } = useCollectionCenter();

  const [isDataChanged, setIsDataChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [prices, setPrices] = useState([]);
  // let [updateData , setUpdateData] = useState([])
  const [update, setUpdate] = useState([]);

  const signboard = {
    loading: currentLoading || loading,
    error: currentError || error,
    success,
  };

  // make a copy of data in order to return it when  use cancel the  edits
  useEffect(() => {
    if (data) setPrices([...data]);
  }, [data]);

  const weightNames = useMemo(() => {
    if (!data) return [];
    return data[0]?.weights?.map((w) => ({ name: w.name, id: w.weight }));
  }, [data]);

  const handleInputChange = (e, cartonIndex, weightIndex) => {
    if (!query.collection_center || query.collection_center === "")
      return showErrorToast("من فضلك اختر المشروع قبل التعديل");

    setIsDataChanged(true);

    const _prices = [...prices];

    const _carton = _prices[cartonIndex];

    const _weight = _carton.weights[weightIndex];

    _weight.price = +e.target.value;

    setPrices([..._prices]);

    setUpdatedData(
      +e.target.value,
      _carton.carton,
      _weight.price_id,
      _weight.weight,
      _weight.checking_id
    );
  };

  useEffect(() => {
    setBreadCrumbsStore([homeBreadCrumb, pricesBreadCrumb]);
  }, []);

  const setUpdatedData = (price, cartonId, priceId, weightId, fakeId) => {
    const _update = [...update];

    const index = _update.findIndex((d) => d.fakeId === fakeId);

    if (index > -1) {
      _update[index] = {
        ..._update[index],
        price,
        carton: cartonId,
        price_id: priceId ?? "",
        weight: weightId,
        fakeId,
      };
      setUpdate([..._update]);
    } else {
      _update.push({
        price_id: priceId ?? "",
        price,
        carton_id: cartonId,
        weight: weightId,
        carton: cartonId,
        fakeId,
      });
      setUpdate([..._update]);
    }
  };

  useEffect(() => {
    if (!query.collection_center) return;

    const fetchPrices = async () => {
      setLoading(true);
      const response = await accio(
        apis.prices.projectPrices(query.collection_center)
      );
      setLoading(false);
      if (!response) return;

      setPrices([...response.data]);
    };
    fetchPrices();
  }, [query.collection_center]);

  useEffect(() => {
    if (!options || options.length === 0) return;

    const center = options[0]?.value;

    if (!center) return;

    setQuery({ ...query, collection_center: center });
  }, [options]);

  const handleFormSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

    const create_props = {
      url: apis.prices.update(),
      data: {
        prices: update.map(({ carton_id, fakeId, ...rest }) => rest),
        collection_center: query?.collection_center,
      },
      setLoading,
      setError,
      setSuccess,
      setMainData: () => {
        setUpdate([]);
      },
      setInputsToDefault,
      FORM_HEADER: `الاضافة`,
    };

    await updateFormHandler(create_props);
  };

  const handleCancel = async (e) => {
    setLoading(true);
    const { data } = await accio(
      apis.prices.projectPrices(query.collection_center)
    );
    if (!data) return;
    setPrices([...data]);
    setLoading(false);
    // setUpdateData([])
    setIsDataChanged(false);
  };

  return (
    <div className="bg-white r-16 p-4">
      <Signboard {...signboard} />
      <header className="flex items-center justify-between">
        <h3>الاسعار الحاليه لنقطة التجميع " {getName(query?.collection_center)}"</h3>
        <div className="col-4">
          <Input defaultValue={query.collection_center} setQuery={setQuery} handleInputChange={(e) =>
              setQuery({ ...query, collection_center: e.target.value })
            }
          />
        </div>
      </header>
      <PageMeta title={"الاسعار الحاليه"} />

      <EditForm
        onSubmit={handleFormSubmit}
        onChange={handleInputChange}
        visible={false}>
        <>
          {!query.collection_center || query.collection_center === "" ? (
            <p className="text-center text-white px-4 py-2 bg-red-300 w-fit mx-auto rounded-md">
              {/* من فضلك اختر المشروع قبل التعديل */}
            </p>
          ) : (
            <div className="overflow-auto">
              <div className="flex items-center !h-[40px]">
                <Grid.Span size={3}> الكارتونه / الاوزان</Grid.Span>
                <Map data={weightNames} map={(item, index) => ( <Grid.Span key={key()} size={2}> {item.name} </Grid.Span> )}
                />
              </div>
              {prices &&
                prices.length > 0 &&
                prices.map((price, cartonIndex) => {
                  return (
                    <div
                      key={cartonIndex}
                      className={"align-items-center flex "}>
                      <Grid.Span size={3}> {price.name}</Grid.Span>
                      {price.weights &&
                        price.weights.length > 0 &&
                        price.weights.map((weight, weightIndex) => {
                          return weight.is_connected ? (
                            <InputNumber
                              key={weight.weight}
                              handleInputChange={(e) =>
                                handleInputChange(
                                  e,
                                  cartonIndex,
                                  weightIndex,
                                  weight
                                )
                              }
                              className={"span-2 px-1 py-0"}
                              placeholder={"0"}
                              // title={weight.name}
                              labelProps={{ className: "hidden" }}
                              defaultValue={weight.price}
                            />
                          ) : (
                            <div className="span-2 px-1" key={weight.weight}>
                              <div className=" w-full  flex items-center justify-center h-[38px]  rounded-md  bg-[#f2f2f2]  " />
                            </div>
                          );
                        })}
                    </div>
                  );
                })}
            </div>
          )}

          {isDataChanged && (
            <Grid.FlexResponsive
              className={"align-items-center mt-4 justify-content-between "}>
              <div className="span-6">
                <button
                  type="button"
                  className="close-btn"
                  onClick={handleCancel}>
                  الغاء
                </button>
              </div>
              <div className="span-6 flex justify-end">
                <button className="add-btn">تعديل البيانات</button>
              </div>
            </Grid.FlexResponsive>
          )}
        </>
      </EditForm>
    </div>
  );
}
