import React, { useEffect, useState } from "react";
import { apis, reports_api } from "../../../../config/apis";
import { createFormHandler, setInputsToDefault, updateFormHandler } from "../../../../helpers/form.helper";
import { InputSelect, PageMeta, Signboard } from "../../../../config/imports";
import { showErrorToast } from "../../../../stores/toast.store";

export default function LockLevel(props) {
    const { toggle, item, setMainData, setRefresh } = props

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);

    console.log({ item })
    const [data, setData] = useState({
        ...item,
        operator: item.operator,
        feed_type: item.feed_type,
        strain: item.strain,
        status: item.status === 'open' ? 'close' : 'open',
        current_status: ""


    })

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        let api = apis.levels.production.toggle(item._id)

        if (item.status === "open") {

            api = api + "?current_status=" + data.current_status

            if (!data.current_status) {
                showErrorToast("من فضلك حدد سبب غلق المرحله")

                return
            }
        }


        const create_props = {
            url: api,
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData: () => { },
            setRefresh,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };

        // console.log({data : create_props})

        await updateFormHandler(create_props);
    };


    useEffect(() => {
        const modal = document.querySelector(('.modal-container-content'))
        modal.style.width = '600px'
        modal.style.padding = '20px'
    })


    // current_status : [dead', 'transfered']

    return (
        <>

            <header className="w-fit m-auto px-4 text-center border-bottm-line ">
                <h3>{data.status !== 'open' ? ' هل انت متأكد من غلق المرحله' : ' هل انت متأكد من فتح المرحله'}</h3>
            </header>




            {
                item.status === "open" && <>
                    <div className="flex items-center gap-4 w-full">
                        <header>
                            <h3 className="text-[18px] whitespace-nowrap relative top-2">سبب غلق المرحله</h3>
                        </header>

                        <div className="flex items-center gap-2 w-full">
                            <InputSelect defaultValue={data.current_status} className="col-12" handleInputChange={e => setData((prevState) => ({ ...prevState, current_status: e.target.value }))}
                                options={[{ value: 'dead', text: 'نافق' }, { value: 'transfered', text: 'تحويل' }]}
                            />

                        </div>
                    </div>


                </>
            }

            <Signboard error={error} success={success} loading={loading} />

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => toggle()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleFormSubmit}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
