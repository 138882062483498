import React, { useEffect, useMemo, useState } from 'react';
import { apis } from '../../../config/apis';
import { createFormHandler } from '../../../helpers/form.helper';
import Signboard from '../../../components/Signboard';
import { EditForm, InputDate, InputNumber, InputSelect, InputText, _date } from '../../../config/imports';
import { useParams } from 'react-router-dom';
import useStockTransferForm from '../hooks/useStockTransferForm';
import useStock from '../useStock';
import useUnits from '../../../hooks/useUnits';
import Helpers from '../../../helpers/helpers';
export default function CreateTransfer(props) {
  const { id } = useParams()
  const { toggle, setRefresh, type, header } = props
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const signboard = { loading, error, success, }
  const { getUnitName, convertUnit } = useUnits()
  const { Inputs, data, setData, InputType, submitDataType, options } = useStockTransferForm(id, type)

  const { fetchQuantities, quantities } = useStock()

  useEffect(() => {
    fetchQuantities({ category: type, location_id: id })
  }, [])

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: type === "number" ? +value : value,
    }));
  };




  const locationOptions = useMemo(() => {
    return [
      { text: "المستودع", value: "stock" },
      { text: "بيت", value: "home" },
    ]
  }, [])



  const handleFormSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

    const create_props = {
      url: apis.storages.create(),
      data: {
        ...submitDataType[type],
        location_id: id,
        location: "stock"
      },
      setLoading,
      setError,
      setSuccess,
      setInputsToDefault: () => { },
      setRefresh,
      setData,
      FORM_HEADER: `الاضافة`,
    };
    // console.log(create_props.data);
    await createFormHandler(create_props);
  };


  const measuringUnits = useMemo(() => {
    return [
      { text: "قطعه", value: "piece" },
    ]
  }, [])

  // console.log(quantities[type]);

  const defaultUnit = (data.unit === "feed" || type === "feed") ? (data.unit !== "" ? data.unit : "ton") : "piece";

  const InputDataType = InputType[type]

  return (
    <>
      <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
        <h3> تحويل  {header}    </h3>
      </header>
      <Signboard {...signboard} />

      <EditForm visible={true} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange}>

        {/* <InputSelect name="_from" label="من"className="col-6"   options={locationOptions} defaultValue={data._from} />
                {
                  data._from === "stock" ?
                  <Inputs.Stocks className="col-6" name="location_id" title="اختر المستودع"  /> :
                  <Inputs.Homes  className="col-6" name="location_id"  label="اختر البيت"  />
                } */}

        <div className="col-6 mb-[10px] ">
          <InputText disabled={true} className="w-100" name="location_id" title="من" defaultValue={"مستودع"} />
        </div>
        <div className="col-6 mb-[10px]  relative">
          <InputText disabled={true} className={"w-100"} name="location_id" title="الجهه" defaultValue={options?.stocks?.filter(s => s.value === id)[0].text} />
          {quantities && (
            <div className='fs-14  absolute bottom-[-10px] '> الكمية المتاحه للتحويل :
              <span className='text-secondary '> {Helpers.Number.commaFormat(convertUnit(quantities[type]?.quantity, quantities[type]?.unit, defaultUnit))}    </span>
              <span> {getUnitName(defaultUnit)}    </span>
            </div>
          )}

          {
            !quantities && <div className='fs-14  absolute bottom-[-10px] text-amber-400'> جاري تحميل الكمية المتاحه... </div>
          }
        </div>
        <InputSelect name="_to" className="col-6" label="الى" options={locationOptions} defaultValue={data._to} />
        <div className="col-6 p-0">
          {
            data._to === "home" ?
              <InputSelect handleInputChange={handleInputChange} options={options.homes} name="destination_id" className="col-12" label="اختر البيت" /> :
              <Inputs.Stocks handleInputChange={handleInputChange} name="destination_id" className="col-12" title="اختر المستودع" />
          }
        </div>

        {/* <Inputs.FeedType  className="col-4"  name="sub_category"  title="نوع العلف" defaultValue={data.sub_category} /> */}
        <div className="col-4 p-0">
          <InputDataType handleInputChange={handleInputChange} className="col-12" name="sub_category" title="النوع " defaultValue={data.sub_category} />
        </div>

        {

          type === "feed" ?
            <Inputs.Unit name="unit" title=" وحدة القياس" defaultValue={data.unit} /> :
            <InputSelect name="unit" title=" وحدة القياس" defaultValue={data.unit} options={measuringUnits} />
        }


        <InputNumber name="consumption" allowDecimal={false} title="الكميه" defaultValue={data.consumption} />
        {/* <InputDate className="col-3"  name="date"  title="التاريخ" defaultValue={_date(data.date).forInputDate()}  /> */}



      </EditForm>
    </>
  )
}
