import { useState } from "react";
import { apis } from "../../../../config/apis";
import { updateFormHandler } from "../../../../helpers/form.helper";
import Signboard from "../../../../components/Signboard";
import { EditForm } from "../../../../config/imports";
import InputNumber from "../../../../components/form/inputs/InputNumber";
import InputText from "../../../../components/form/inputs/InputText";

export default function Update (props){

    const {setMainData, toggle , item , asset} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    // console.log({item})

    const [data , setData] = useState({...item})

    let signboard = { error  ,success,loading  }

    const handleInputChange = (e) => {
        const { name , value , type } = e.target;
        data[name] = type  === 'number' ? +value : value
        setData({
            ...data
        })
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: apis.dishes.update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setData,
            FORM_HEADER: `تعديل`,
        };

        await updateFormHandler(update_props);
    };




    return(
        <>
        
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3> تعديل بيانات الطبق</h3>
            </header>

            <Signboard {...signboard}/>

            <EditForm visible={true} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange} >
                <InputText name="name" title="اسم الطبق" className="col-12"  placeholder="طبق  4 * 3"  value={data.name} />
                <InputNumber name="eggs_in_dish" title="عدد البيض في كل طبق" className="col-6" placeholder="12" value={data.eggs_in_dish} />
                <InputText name="type" title="نوع الطبق" className="col-6" placeholder="ورق مقوى"  value={data.type} />
            </EditForm>

        </>
    )
}
