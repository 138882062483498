import { useEffect } from 'react'
import { setBreadCrumbsStore } from '../../../components/breadcrumbs/store/breadcrumb.store'
import { collectionCenterBreadCrumb, homeBreadCrumb } from '../../../components/breadcrumbs/data/breadcrumbs.data'
import useMeta from '../../../hooks/useMeta'
import { useCollectionCenterStore } from '../store/collection-center.store'

export default function useCollectionCenters() {


  const fetch = useCollectionCenterStore(state => state.fetch)
  const refetch = useCollectionCenterStore(state => state.refetch)

  useEffect(()=>{
    if(refetch) fetch()
  } , [refetch])


  useEffect(()=>{
     fetch()
  } , [])


  useMeta({title : 'نقاط التجميع'})

  useEffect(()=>{
    setBreadCrumbsStore([
        homeBreadCrumb,
        collectionCenterBreadCrumb
    ])
  } , [])

}
