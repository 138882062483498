import {
    _date, Icons,
    QuickView, Signboard,
    TableHeader,
    useFetch,PageMeta ,

} from "../../config/imports";
import React, {useEffect, useRef, useState} from "react";
import {apis, roles_api, users_api} from "../../config/apis";
import '../../styles/user.styles.scss'
import {paths} from "../../config/configs.env";
import {Table} from "../../layout/table";
import UsersCrud from "./users_crud";
import {accio} from "../../helpers/axios/axios.get";
import ImageViewer from "../../components/ImageViewer";
import { setBreadCrumbsStore } from "../../components/breadcrumbs/store/breadcrumb.store";
import { homeBreadCrumb, usersBreadCrumb } from "../../components/breadcrumbs/data/breadcrumbs.data";


export default function UserBase(){
    const createRef = useRef(),
        updateRef = useRef(),
        deleteRef = useRef(),
        activateRef = useRef(),
        passwordRef = useRef(),
        previewRef = useRef()

    const {data , isLoading , error , setData } = useFetch(users_api.users)

    const {data : roles , error : rolesError , isLoading : rolesIsLoading   } = useFetch(apis.authorization.roles())

    const [isDataAdded , setIsDataAdded] = useState(false)

    const reloadUsers = async () => {
        const { data } = await accio( users_api.users )
        setData( data )
        setIsDataAdded(false)
    }

    useEffect( ()=>{
        if(isDataAdded)
            reloadUsers()
    } , [isDataAdded])


    const signboard = {
        error : error || rolesError,
        loading : isLoading || rolesIsLoading
    }


    const table = [
        {size : 1 , name : "#" , text : "#"  },
        {size : 2 , name : 'user_img' , text : 'صورة المستخدم' , custom : item => {
            return item.user_img &&  <div className={'user-img'}
                onClick={ ()=>previewRef.current?.toggle(
                    {src : '/users/' +  item.user_img , alt : item.username , header : " المستخدم " + item.username })}
            >
                    <img src={paths.uploads + '/users/' +  item.user_img} width={'50px'} height={'50px'}   alt={item.username}/>
            </div>
        }
        },
        {size : 3 , name : "username" , text : "الإسم"  },
        {size : 4 , name : "email" , text : "الإيميل"  },
        {size : 3 , name : "is_active" , text : "حالة المستخدم" , custom : item => item.is_active ?  <div className="w-100 active-status"> حساب فعال  </div>: <div className="w-100 not-active-status"> حساب متوقف    </div>},
        {size : 4 , name : 'roles' , text : 'الادوار' , custom : item =>
             item.roles.length > 0 ?
                 item.roles?.map((role , index) => <span key={index} className='role-name'> {role.role} </span>) :
                 <span className='px-2'>بدون</span>
        },
        {size : 3 , name :'updatedAt' , text : 'تاريخ التعديل' , custom : item => _date(item.updatedAt).dmy() },
        {size : 3 , name : '' , text : '' , custom : item =>{
                return (
                   <div className={'settings-buttons'}>
                       <button  onClick={()=> deleteRef.current?.toggle(item)}><Icons.Delete /></button>
                       <button  onClick={()=> updateRef.current?.toggle(item)}><Icons.Edit /></button>
                       <button  onClick={()=> activateRef.current?.toggle(item)}>
                           {item.is_active ?  <Icons.Lock /> :    <Icons.ActiveLock />}
                       </button>
                       <button  onClick={()=> passwordRef.current?.toggle(item)}><Icons.Password /></button>
                   </div>
                )

            }}
    ]



    
    useEffect(()=>{
        setBreadCrumbsStore([
            homeBreadCrumb,
            usersBreadCrumb,
        ])
  } , [])




    return (
        <div className='user-base bg-white r-16 base-card py-3 px-2'>

            <QuickView   ref={createRef} modalClassName="overflow-visible">
                <UsersCrud.Create
                    updateMain={setIsDataAdded}
                    roles={roles && roles?.length>0 && roles.map(role => {return {_id: role._id, name: role.role}})}
                />
            </QuickView>

            <QuickView  className="overflown-popup" ref={updateRef}>
                <UsersCrud.Update
                    updateMain={setIsDataAdded}
                    roles={roles && roles?.length>0 && roles.map(role => {return {_id: role._id, name: role.role}})}
                />
            </QuickView>
            <QuickView ref={deleteRef}><UsersCrud.Delete updateMain={setIsDataAdded}/></QuickView>
            <QuickView ref={activateRef}><UsersCrud.Activate updateMain={setIsDataAdded}/></QuickView>
            <QuickView ref={passwordRef}><UsersCrud.Password updateMain={setIsDataAdded}/></QuickView>
            <QuickView ref={previewRef}><ImageViewer  /></QuickView>

            <PageMeta title={'المستخدمين'} />

            <TableHeader buttons={[
                { title : "إضافة مستخدم" , onclick : () => { createRef.current?.toggle(); } }
            ]} />

            <Signboard {...signboard} />

            <Table data={data} table={table} className={'mt-5'}/>

        </div>
    )
}

