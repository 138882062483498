import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import QuickView from '../../../components/QuickView'
import ApproveReport from './ApproveReport'

function ApprovalsHeader(props) {
    const { setRefresh, type } = props
    const approveRef = useRef()

    return (
        <>
            <QuickView ref={approveRef}><ApproveReport setRefresh={setRefresh} /></QuickView>

            <header className='mb-3 flex justify-between'>

                <h1 className='text-[20px] font-[500]'>   ملخص  {type}  </h1>

                <div className="">
                    <button className='add-btn' onClick={() => approveRef.current.toggle()}> الموافقه على التقرير </button>
                </div>

            </header>
        </>
    )
}

ApprovalsHeader.propTypes = {
    setRefresh: PropTypes.func,
    type: PropTypes.string
}

export default ApprovalsHeader
