import { BiSubdirectoryLeft } from "react-icons/bi";
import { BsSubtract } from "react-icons/bs";
import { AiOutlineClear } from "react-icons/ai";
import { CiSearch } from "react-icons/ci";
import { BiCheck } from "react-icons/bi";
import { BiChevronDown } from "react-icons/bi";
import { useEffect, useState } from 'react';
import { cn } from "../../../helpers/class_name.helpers";
import useDebounce from "../../../hooks/useDebounce";
import useProjects from "../../../hooks/origins/useProjects";

export default function InputDepartments({ onChange = () => {}  , projectId = null }) {


  const [state , setState] = useState(false)
  const [search , setSearch] = useState("")
  const debounced = useDebounce(search)
  const [options , setOptions] = useState([])
  const [selectedIds , setSelectedIds] = useState([])
  const {projects} = useProjects()



  useEffect(() => {
      if(!projects) return 

      const options =  [...projects?.map(p => ({
        value : p._id,
        text : p.name,
        selected : false,
        departments : p.departments.map(dep => ({
          text : dep.name,
          value : dep._id,
          selected : false
        }))
      }))]

      if(projectId){
        return setOptions(options.filter(o => o.value === projectId))
      }


      setOptions(options)

      


  }, [projects])





 
  


  const filtered = options.filter(d => d.text.toLowerCase().includes(debounced.toLowerCase()))



  useEffect(() => {
    const handler = (e) => {
      if(e.target.closest('[data-keep-department-input-open]')) return
      setState(false)
    }

    document.addEventListener('click' , handler)

    return () => {
      document.removeEventListener('click' , handler)
    }
  }, [])


  const setSelected = (departmentId) => {
     setSelectedIds(pre => {
       if(pre.includes(departmentId)){
         return pre.filter(id => id !== departmentId)
       }
       return [...pre , departmentId]
     })
  }



  
  


  const clearSelected = () => {
    setSelectedIds([])
  }


  

  



  useEffect(() => {
    if(onChange)
      onChange( selectedIds )
    
  }, [selectedIds])


  const howManySelected = selectedIds.length

  const isSelected = (id) => {
    return selectedIds.includes(id)
  }


  // console.log({ids, howManySelected})

  return <div className='w-full relative'>

      <header>
        <button data-keep-department-input-open  onClick={() => setState(!state)} 
        className={cn(
          'w-full justify-center  whitespace-nowrap bg-[#fcaa30] text-white  px-3 h-[40px] flex items-center gap-2 rounded-md',
          state && 'bg-[#eb971a]'
        )}>
          <div className="flex items-center text-white gap-1">
            {/* <BiCategory className="text-white fill-white text-[24px]" /> */}

            <BsSubtract className="text-white fill-white text-[24px]" />
               الاقسام  &nbsp;
            {howManySelected > 0 && <>({howManySelected})</>}
          </div>
           <BiChevronDown className={cn(
            "text-white fill-white text-[24px]",
            state && 'rotate-180'
           )}/>

        </button>
      </header>




      <div data-keep-department-input-open  className={cn(
        "absolute w-[300px]  top-[130%] shadow-md rounded-md  z-50 bg-white  overflow-hidden",
        "py-3 flex flex-col",
        state ? 'max-h-[500px]' : 'h-0 p-0',
      )}>

        <header className="px-2 flex justify-between items-center mb-2">
          <h3 className="text-[18px] font-[500] text-gray-500 mb-0">
            الاقسام  ({filtered.length})
          </h3>

          <button 
           onClick={clearSelected}
           className="flex items-center gap-1 hover:text-red-500 group">
              حذف
              <AiOutlineClear className="text-[24px] fill-slate-500 group-hover:fill-red-500" />
              {howManySelected > 0 && <>({howManySelected})</>}
              
          </button>
        </header>
        <div className="px-2 ">
          <div className="bg-[#f7f7f8] rounded-md flex items-center justify-center mb-2">
            <span className="w-[40px]  text-center flex items-center justify-center">
              {/* <BiSearchAlt2 className="text-[24px] fill-slate-500"  /> */}
              <CiSearch className="text-[24px] fill-slate-500" />
            </span>
            <input data-keep-collection-menu-open type="search" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='بحث' 
            className='w-full h-[45px]  text-[16px] text-[#333] outline-none border-none m-0  bg-transparent' />  

          </div>

        </div>

        <div className="flex flex-col gap-2 px-2  overflow-y-scroll hide-scrollbar flex-1">
            {filtered.map((item) => {
              return (
                <div   key={item.value} 
                className={cn(
                  // "w-full cursor-pointer  text-end h-[40px] px-3 text-[16px] text-[#333] outline-none border-none",
                  // "hover:bg-gray-100 rounded-md",
                  // "flex justify-between items-center py-2",

                  " text-end py-1",
                  item.selected && 'bg-gray-100'
                )}>
                    <span className="block mb-1 text-gray-400">{item.text}</span>
                  {
                    item.departments.map((dep) => (
                      <button onClick={() => setSelected(dep.value)}   key={dep.value} 
                      className={cn(
                        "w-full cursor-pointer text-end px-3 py-2 text-[16px] text-[#333] outline-none border-none rounded-md hover:bg-slate-100",
                        "flex items-center justify-between mb-1",
                        isSelected(dep.value) && 'bg-slate-100'
                      )}>
                          <div className="flex items-center relative">
                            <BiSubdirectoryLeft className="text-[24px] fill-slate-400" />
                            {dep.text}
                          </div>

                        {isSelected(dep.value) && <BiCheck className="text-[24px] fill-slate-400" />}
                    </button>
                    ))
                  }


                  
                </div>
              )
            })}
            {
              !filtered.length && (
                <div className="w-full text-end h-[40px] px-3 text-[16px] text-[#333] outline-none border-none">
                    لا توجد نتائج
                </div>
              )
            }
        </div>

      </div>


  </div>




}
