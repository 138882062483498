import { useRef } from "react";
import { useParams } from "react-router-dom";
import { _date, PageMeta, QuickView, Signboard, TableHeader, useFetch } from "../../../../config/imports";
import ReportsCrud from "../reports_crud";
import { reports_api } from "../../../../config/apis";
import Helpers from "../../../../helpers/helpers";
import { TableHeader as ReportHeader } from "../../../../layout/table";
import ReportAccordion from "../components/ReportAccordion";
import ReportMovementDetails from "../components/ReportMovementDetails";
import { categories } from "../reports.shared";

export default function OtherDailyReportForProductionHome(props) {
    const createRef = useRef()

    const { id } = useParams();

    const api = reports_api.home.others.read(id)

    const { data, setData, alert, paginate, setters, setRefresh } = useFetch(api);




    const table = [
        { name: "#", text: "", size: 1 },
        { size: 3, name: "date", text: "التاريخ", custom: d => _date(d.date).dmy() },
        { size: 4, name: "last_updated_by", text: " تعديل بواسطة" },
        { size: 3, name: "created_by", text: " اضافة بواسطة" },
        { size: 2, name: "total_quantity", text: "الكمية", custom: d => Helpers.Number.commaFormat(d?.total_quantity) },
        { size: 3, name: "ar_unit", text: "وحدة القياس" },
        { size: 3, name: "total_price", text: "قمية الاستهلاك", custom: d => Helpers.Number.commaFormat(d?.total_price) },
        { size: 3, name: "carton_cost", text: "تكلفة الكرتون", custom: d => Helpers.Number.commaFormat(d?.carton_cost) },
        { size: 4, name: "notes", text: " ملاحظات" },
        { size: 1, name: "", text: "" },
    ]

    const buttons = [
        {
            title: " اضافة تقرير اخرى  جديد",
            onclick: () => createRef.current.toggle()
        }
    ]


    return (<div className="report-container">
        <PageMeta title={'تقارير الاخرى اليوميه'} />
        <Signboard {...alert} />
        <QuickView ref={createRef}><ReportsCrud.Others.Create setRefresh={setRefresh} /></QuickView>
        <TableHeader buttons={buttons} api={api} setData={setters.data} setLoading={setters.loading} paginate={paginate} />
        <ReportHeader table={table} />
        <ReportAccordion
            MovementDetails={ReportMovementDetails}
            data={data} table={table} categories={categories} />
    </div>
    )
}
