import React, { useEffect, useMemo, useState } from 'react';
import { apis } from '../../../config/apis';
import { createFormHandler } from '../../../helpers/form.helper';
import Signboard from '../../../components/Signboard';
import { EditForm, InputDate, InputNumber, InputSelect, InputText, _date } from '../../../config/imports';
import { useParams } from 'react-router-dom';
import useStockTransferForm from '../hooks/useStockTransferForm';
import useStock from '../useStock';
import useUnits from '../../../hooks/useUnits';
import Helpers from '../../../helpers/helpers';
import useStockDrugs from '../hooks/useStockDrugs';
export default function CreateDrugTransfer(props) {
  const { id } = useParams()
  const { toggle, setRefresh, type, header } = props
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const signboard = { loading, error, success, }
  // const {getUnitName , convertUnit} = useUnits()
  const { Inputs, data, setData, submitDataType, options } = useStockTransferForm(id, type)

  // const  { fetchQuantities , quantities  } =  useStock()




  // console.log({quantities});


  // useEffect(() => {
  //   fetchQuantities({ category :  type , location_id : id })
  // } , [])



  const { Input: InputDrugsType, options: optionsDrugs } = useStockDrugs(type)








  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: type === "number" ? +value : value,
    }));

    if (name === "sub_category") {
      const drug = optionsDrugs.find(d => d.value === value)

      if (!drug) {
        setData((prev) => ({
          ...prev,
          sub_category: null,
          unit: null,
          ar_unit: null
        }))
        return
      }

      setData((prev) => ({
        ...prev,
        sub_category: value,
        unit: drug.unit,
        ar_unit: drug.ar_unit
      }))


    }






  };




  const locationOptions = useMemo(() => {
    return [
      { text: "المستودع", value: "stock" },
      { text: "بيت", value: "home" },
    ]
  }, [])



  const handleFormSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

    const create_props = {
      url: apis.storages.create(),
      data: {
        ...submitDataType[type],
        location_id: id,
        location: "stock"
      },
      setLoading,
      setError,
      setSuccess,
      setInputsToDefault: () => { },
      setRefresh,
      setData,
      FORM_HEADER: `الاضافة`,
    };
    // console.log(create_props.data);
    await createFormHandler(create_props);
  };





  return (
    <>
      <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
        <h3> تحويل  ادويه    </h3>
      </header>

      <Signboard {...signboard} />

      <EditForm visible={true} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange}>

        {/* <InputSelect name="_from" label="من"className="col-6"   options={locationOptions} defaultValue={data._from} />
                {
                  data._from === "stock" ?
                  <Inputs.Stocks className="col-6" name="location_id" title="اختر المستودع"  /> :
                  <Inputs.Homes  className="col-6" name="location_id"  label="اختر البيت"  />
                } */}

        <div className="col-6 mb-[10px] ">
          <InputText disabled={true} className="w-100" name="location_id" title="من" defaultValue={"مستودع"} />
        </div>
        <div className="col-6 mb-[10px]  relative">
          <InputText disabled={true} className={"w-100"} name="location_id" title="الجهه" defaultValue={options?.stocks?.filter(s => s.value === id)[0].text} />

        </div>
        <InputSelect name="_to" className="col-6" label="الى" options={locationOptions} defaultValue={data._to} />
        <div className="col-6 p-0">
          {
            data._to === "home" ?
              <InputSelect handleInputChange={handleInputChange} options={options.homes} name="destination_id" className="col-12" label="اختر البيت" /> :
              <Inputs.Stocks handleInputChange={handleInputChange} name="destination_id" className="col-12" title="اختر المستودع" />
          }
        </div>


        <div className="col-6 p-0">
          <InputDrugsType handleInputChange={handleInputChange} className="col-12" name="sub_category" title="النوع " defaultValue={data.sub_category} />
        </div>
        <>
          <div className='col-2 bottom-1'>
            <label htmlFor="" className="mb-2.5">وحدة القياس</label>
            <div className="border-border border-[2px] flex items-center px-2 h-[40px] rounded-md bg-gray-100">
              {data.ar_unit}
            </div>
          </div>
        </>



        <InputNumber name="consumption" title="الكميه" allowDecimal={false} defaultValue={data.consumption} />



      </EditForm>
    </>
  )
}
