import React from 'react'
import useTargetFilterStore from '../stores/targets-filter.store'
import InputCollectionCenter from '../../../shared/components/targets/InputCollectionCenter'
import InputProjects from '../../../shared/components/projects/InputProjects'
import InputDepartments from '../../../shared/components/departments/InputDepartments'
import InputHomes from '../../../shared/components/homes/InputHomes'
import InputDateRange from '../../../shared/components/date/InputDateRange'

export default function TargetsFilters() {

  const {
      setCollectionsCenterIds,
      setProjectsIds,
      setDepartmentsIds,
      setHomesIds,
      setStartYear,
      setEndYear,
  }  = useTargetFilterStore()

 

  return (
    <div className='h-fit py-2'>
        <div className="flex items-center gap-2">
          <div className="w-fit">
             <InputCollectionCenter onChange={(data)=> setCollectionsCenterIds(data) }   />
            </div> 
          <div className="w-fit">
             <InputProjects onChange={(data) => setProjectsIds(data) } />
            </div> 
          <div className="w-fit">
             <InputDepartments   onChange={(data) => setDepartmentsIds(data) } />
            </div> 
          <div className="w-fit">
             <InputHomes   onChange={(data) => setHomesIds(data) } />
            </div> 
          <div className="w-fit">
             <InputDateRange 
              onChange={(data) => {
                  const startYear = new Date(data.start).getFullYear()
                  const endYear = new Date(data.end).getFullYear()
                   setStartYear(startYear)
                   setEndYear(endYear)
               }}
             />
          </div> 
    
        </div>
    </div>
  )
}
