import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Table } from '../../../layout/table'
import { _date } from '../../../helpers/date.helpers'
import Helpers from '../../../helpers/helpers'
import QuickView from '../../../components/QuickView'
import ApprovalsMovementDetails from '../components/ApprovalsMovementDetails'
import ApprovalsHeader from '../components/ApprovalsHeader'
import ApprovalReturnLink from '../components/ApprovalReturnLink'
import PageMeta from '../../../components/PageMeta'

function LightApprovalDetails(props) {

    const {getUnitName , data , setRefresh , Alert} = props

    const detailsRef = useRef()

    const headerTable =  [
        {size : 3 , name : "date" , text : "التاريخ"  , custom : d => _date(d.date).dmyt12() },
        {size : 4 , name : "last_updated_by" , text : " تعديل بواسطة"  },
        {size : 4 , name : "created_by" , text : " اضافة بواسطة"  },
        {size : 6 , name : "strain_light_duration" , text : "مدة الاضاءة بالكتالوج" },
        {size : 4 , name : "ar_unit" , text : "وحدة القياس" ,  },
    ]


    const bodyTable = [
        { name : "start_date" , text : "تkاريخ بداية الاضاءة" , size : 6  , custom : d => _date(d?.start_date).dmyt12() },
        { name : "end_date" , text : "تاريخ نهاية الاضاءة" , size : 6 , custom : d => _date(d?.end_date).dmyt12() },
        { name : "total_quantity" , text : "مدة  الاضاءة" , custom : d => Helpers.Number.commaFormat( d?.total_quantity )  , size : 4 },
        { name : "ar_unit" , text : "وحدة القياس" , size : 3 , },
        { name : "notes" , text : " ملاحظات"   , size : 6 },
    ]

  return (
    <>
        <PageMeta title={'تفاصيل الموافقة'} />

        <ApprovalReturnLink />

        <QuickView ref={detailsRef}  className="overflown-popup"><ApprovalsMovementDetails getUnitName={getUnitName}   /></QuickView>

        <div className="page my-3">
            
            <ApprovalsHeader setRefresh={setRefresh} type={data[0]?.ar_type}   />
            
            <Alert />

            <Table data={data} table={headerTable} />
            
        </div>

        <div className="page my-3">

            <h1 className='text-[20px] font-[500]'>  التفاصيل  </h1>

            <Alert />

            <Table data={data} table={bodyTable} />

        </div>


    </>
  )
}

LightApprovalDetails.propTypes = {
    data : PropTypes.array,
    getUnitName : PropTypes.func,
    setRefresh : PropTypes.func,
    Alert : PropTypes.any
}

export default LightApprovalDetails
