import { BiChevronDown } from "react-icons/bi";
import React from 'react'
import { cn } from "../../helpers/class_name.helpers";
import { CgSpinner } from "react-icons/cg";


export default function SelectTrigger({ options, selected, setOpen, open , deleteOption , loading}) {

  const selectedOptions = options.filter(opt => selected.includes(opt.value))

  return (
    <div
      className={cn(
        'bg-white rounded-md h-[40px] flex items-center select-none cursor-pointer  px-2 relative py-[3px]',
        "border-[2px] border-[#cdcdcd9a]  gap-2"
      )}
      onClick={() => setOpen(!open)}>


      {loading && (
        <div className="absolute top-0 -right-2 w-full h-full flex items-center justify-end">
          <CgSpinner className="animate-spin fill-gray-300 text-gray-300 text-[25px]" />
        </div>
      )}

      {selectedOptions && !!selectedOptions.length && selectedOptions.map(selectedOption => {
        return <span key={selectedOption.value}
        onClick={(e) =>{
            e.stopPropagation()
            deleteOption(selectedOption.value)
        }}
        className={cn(
          "border  bg-[#f0f0f0] px-2 rounded-md flex items-center justify-center h-full whitespace-nowrap text-[14px]",
          "hover:bg-red-400 hover:text-white"
        )}>
          {selectedOption.label}
        </span>
      })}


      {selectedOptions.length === 0 && <span className="text-gray-400 px-2">اختر من القائمة</span>}

      {!loading && <BiChevronDown className={cn(
        "absolute left-2 text-[20px]",
        "transition-all duration-300 ease-in-out",
        open && "rotate-180"
      )} />}
    </div>
  )
}
