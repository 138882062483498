import { BiTrash } from "react-icons/bi";
import React, { useMemo } from 'react';
import UnitPriceInput from './UnitPriceInput';
import AmountInput from './AmountInput';
import useInvoiceItemsStore from '../stores/invoice-items.store';
import useFetch from "../../../hooks/useFetch";
import { apis } from "../../../config/apis";
import { InputSelect } from "../../../config/imports";

export default function InputDrugsGroup({data , setData}) {

  const {data : drugs } = useFetch(apis.origins.drug.base())


  
  const drugsOptions = useMemo(() => {
    if (!drugs) return []; // renders nothing if data is not available
    return drugs.map( drug => ({
        _id : drug._id , name : drug.name
    }))
} , [drugs])



  const {items , addRow , deleteRow} = useInvoiceItemsStore(state =>({
    items : state.items,
    addRow : state.addItem,
    deleteRow : state.deleteItem
  }))


  const onItemChange = useInvoiceItemsStore(state => state.onItemChange)


  const onDrugChange = (e , item) => {
     onItemChange('sub_category' , e.target.value , item.id)


     if(!e.target.value) {
        onItemChange('unit' , null , item.id)
        onItemChange('ar_unit' , null , item.id)
        return
     }

     const drug = drugs.find( d => d._id === e.target.value)

     if(!drug) return

     const unit = drug.measuringUnit

     const arUnit = drug.ar_unit
     
     onItemChange('unit' , unit , item.id)

     onItemChange('ar_unit' , arUnit , item.id)

    //  onItemChange('unit' , value , item.id)

  }




  return (
  items.map((item)=>(
    <div className='w-full  relative' key={item.id}>
      <div className="row g-3">

       <div className="col-4 ">
          <InputSelect  className={'w-full'} 
           handleInputChange={(e )=> onDrugChange(e , item)}
           defaultValue={data.sub_category} name={'sub_category'} options={ drugsOptions } title={'نوع الدواء'} />
       </div>

       <div className="col-2 ">
         {/* <MeasuringUnitInput data={data} setData={setData}  item={item}  options={measuringUnits}  /> */}
         <label htmlFor="" className="mb-2.5">وحدة القياس</label>
         <div className="border-border border-[2px] flex items-center px-2 h-[40px] rounded-md bg-gray-100">
          {item.ar_unit}
         </div>

       </div>

       <div className="col-2 ">
         <UnitPriceInput data={data} setData={setData}  item={item}  />
       </div>

       <div className="col-2 ">
         <AmountInput data={data} setData={setData} item={item}   /> 
       </div>
       <div className="col-2 ">
        <span className='mb-2 block'>الاجمالي</span>
        <span className=' border-border border-[2px] flex items-center px-2 h-[40px] rounded-md bg-slate-100'>
            {(item.unit_price * item.quantity).toFixed(2)}
        </span>
       </div>

      </div>
        <button 
            onClick={addRow}
            type="button" className='absolute top-10  -right-7 border-border border-[1px] rounded-md p-1 py-0  hover:bg-[#86d993] hover:text-white group'>
              <span className="text-[30px] group-hover:text-white leading-[20px] text-[#fcaa30]">+</span>
            </button>
       {
        items.length > 1 && (
          <>
            <button 
            onClick={()=>deleteRow(item.id)}
            type="button"  className='absolute top-9  -left-7 border-red-200 border-[1px] rounded-md p-1  hover:bg-red-300 hover:text-white group'>
              <BiTrash className="!text-[20px] group-hover:text-red-300 fill-red-500" />
            </button>
          </>
        )
       }
    </div>
  ))
  )
}
