import { useState } from "react";
import {
    createFormHandler,
    EditForm,
    InputNumber,
    InputText,
    InputTextArea,
    PageMeta, setInputsToDefault,
    Signboard
} from "../../../../config/imports";
import { weight_api } from "../../../../config/apis";

export default function CreateWeight(props) {

    const { setMainData, toggle } = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data, setData] = useState({
        name: '',
        weight: '',
        info: '',
    })
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const create_props = {
            url: weight_api.create,
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };



        await createFormHandler(create_props);
    };

    return (
        <>

            <PageMeta title={'إضافة وزن جديد'} originTitle={'الاوزان'} />

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>   إضافة وزن جديد</h3>
            </header>

            <Signboard error={error} success={success} loading={loading} />


            <EditForm
                visible={props.showFooter === undefined ? true : false}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
                <InputText name={'name'} className={'col-6'} handleInputChange={handleInputChange} placeholder={'B'} title={'الرمز'} value={data.name} />
                <InputNumber name={'weight'} className={'col-6'} handleInputChange={handleInputChange} placeholder={'100'} title={'الوزن بالجرام'} value={data.weight} />
                <InputTextArea name={'info'} className={'col-12'} handleInputChange={handleInputChange} placeholder={'0'} title={'معلومات اضافيه'} value={data.info} />
            </EditForm>
        </>

    )
}
