import React, { useState } from 'react'
import useHomeHierarchical from '../../../hooks/useHomeHierarchical'
import { InputSelect } from '../../../config/imports'
import { allOption } from '../shared.approvals'
import { useSearchParams } from 'react-router-dom'

export default function HomesFilter(props) {
    // const {data} = useFetch(apis.homes.homes())
    const [searchParams ] = useSearchParams()
    // const [state , setState] = useState(undefined)
    const state = searchParams.get('home')
    const options = [allOption ,  ...useHomeHierarchical().options.all]

    const handleInputChange = (e) => {
        const { value} = e.target;
        if(props?.setQuery) return props.setQuery(value)
    }






    return <>
    
        <InputSelect  className="w-full" options={options}  title="اختر البيت"  defaultValue={state} handleInputChange={handleInputChange} />
    </>


}
