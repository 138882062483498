import { AiTwotoneStar } from "react-icons/ai";
import { FaStar } from "react-icons/fa";
import React, { useEffect, useState } from 'react';
import { cn } from '../../../helpers/class_name.helpers';
import { createPopup } from "airpop";
import UpdateCollectionCenter from "../forms/UpdateCollectionCenter";
import DeleteCollectionCenter from "../forms/DeleteCollectionCenter";
import ChangeCollectionCenterColor from "../forms/ChangeCollectionCenterColor";
import Icons from "../../../layout/Icons";
import { BiStar } from 'react-icons/bi';
import FavCollectionCenter from './FavCollectionCenter';
import { useCollectionCenterStore } from '../store/collection-center.store';
import { postData } from '../../../config/imports';
import { apis } from '../../../config/apis';
import { showErrorToast } from "../../../stores/toast.store";

export default function CollectionCenterMenu({ data, fav }) {

  const [state, setState] = useState(false)

  const edit = createPopup(<UpdateCollectionCenter />)
  const del = createPopup(<DeleteCollectionCenter />)
  const color = createPopup(<ChangeCollectionCenterColor />)
  const refetch = useCollectionCenterStore(state => state.setRefetch)
  const setLoading = useCollectionCenterStore(state => state.setLoading)
  const loading = useCollectionCenterStore(state => state.loading)


  useEffect(() => {
    if (state) {
      setTimeout(() => {
        setState(false)
      }, 2000)
    }
  }, [state])


  useEffect(() => {
    const handleOutsideClick = (event) => {
      const isPopup = event.target.closest("[data-keep-collection-menu-open]") ? true : false;
      if (!isPopup) return setState(false);
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);


  const toggleFav = async (data) => {

    if (loading) return

    setLoading(true)
    const response = await postData(apis.collectionCenter.fav(), {
      collection_center_id: data._id
    })

    setLoading(false)

    if (response.error) return showErrorToast(response.error)


    refetch()

  }


  return (
    <>
      <button onClick={() => toggleFav(data)} className="absolute left-2 top-2 ">
        {!fav && <BiStar className="fill-white text-white text-[20px]" />}
        {/* { fav && <FaStar  className="fill-white text-white text-[20px]" />} */}
        {fav && <AiTwotoneStar className="fill-white text-white text-[20px]" />}
      </button>

      <button
        data-keep-collection-menu-open
        onClick={() => setState(!state)} className='absolute -top-1 right-3 w-fit h-fit text-white  '>
        <b className="text-white text-[20px]">. . .</b>
      </button>

      <div
        data-keep-collection-menu-open
        className={cn(
          'absolute top-[20%] right-[5%]  w-[90%]  flex items-center justify-center transition-all duration-500 ease-in-out bg-white/90 ',
          "h-0 overflow-hidden rounded-md ",
          state && "h-[70%]"
        )}>

        <div className="w-full h-full flex flex-col  gap-2">
          <button onClick={() => edit(data)} className="w-full    flex items-center gap-2 justify-between px-2  hover:bg-slate-200">
            <p className='mb-0'>تعديل</p>
            <Icons.Edit />
          </button>
          <button onClick={() => del(data)} className="w-full    flex items-center gap-2 justify-between px-2  hover:bg-slate-200">
            <p className='mb-0'>حذف</p>
            <Icons.Delete />
          </button>
          <button onClick={() => color(data)} className="w-full    flex items-center gap-2 justify-between px-2  hover:bg-slate-200">
            <p className='mb-0'>تغيير اللون</p>
            <Icons.Color />
          </button>


        </div>


      </div>

    </>
  )
}
