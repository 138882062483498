import { AiOutlineLoading } from "react-icons/ai"; 
import { BiTrashAlt } from "react-icons/bi";
import { AiOutlinePlus } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { apis, reports_api } from "../../../../../config/apis";
import { _date, InputDate, InputTextArea, useFetch } from "../../../../../config/imports";
import CreateForm from "../../../../../components/crud/CreateForm";
import { create } from "zustand";
import { cn } from "../../../../../helpers/class_name.helpers";
import { id, uuid } from "../../../../../helpers/id.helper";

const defaultSamplesCount = 10

export default function Create(props){

    const {id} = useParams();

    const {setRefresh, toggle} = props;
    const average = useStore(state => state.average())
    const addSample = useStore(state => state.addSample)
    const samples = useStore(state => state.samples)
    const formatted = useStore(state => state.formatted)
    
    
    
    const allHasValue =  samples.every(item => item.weight)
    
    const [state , setState] = useState({
        date : new Date().toISOString(),
    })
    
    const {data , isLoading} = useFetch(apis.reports_api.base  + "/strain-values/" + id + "?date=" + _date(state.date).toZeroUTC())


    
    const createProps = {
        setRefresh,
        setState,
        state,
        api : reports_api.home.weights.create(),
        toggle,
        header : 'اضافة تقرير أوزان  يومي',
        customState : (data) => {
            return {
                ...data,
                home : id,
                date : _date(data.date).toZeroUTC(),
                samples : formatted()
            }
        },
        validation : (data) => {
            if(allHasValue) 
             return { error : null, success : true }
            else 
             return { error : "الرجاء ملء جميع خانات الاوزان", success : null }            
        },
        onSuccess : (data) => {
            setRefresh(uuid())
            if(toggle) toggle()
            useStore.getState().reset()
        }
    }


    return(
       <>
           <CreateForm {...createProps}>
               <InputDate    className="col-4"  name='date'    title='التاريخ'  defaultValue={_date(state.date).forInputDate()} />
               <>
                 <div className="col-4">
                    <span className="relative bottom-2">عمر الطيور</span>
                    <div className= "w-full bg-slate-100 h-[40px] rounded-md flex items-center justify-start  truncate  px-2">
                      {isLoading ? <AiOutlineLoading className="animate-spin" /> : data?.week_age}
                    </div>
                 </div>
                 <div className="col-4">
                    <span className="relative bottom-2">  متوسط وزن الطيور بالجرام  ({samples.length}) </span>
                    <div className= "w-full bg-slate-100 h-[40px] rounded-md flex items-center justify-start  truncate  px-2">
                      {average}
                    </div>
                 </div>
                 <div className="px-2.5 mt-4 relative">

                    <Weights />

                    <button 
                        type="button"
                        onClick={addSample}
                        className={cn(
                            "absolute -left-8 top-1/2 -translate-y-1/2 text-[20px] border-border border-[1px]",
                            "hover:bg-slate-200 bg-slate-100",
                            'p-1 flex items-center justify-center rounded-md'
                        )}>
                        <AiOutlinePlus />
                    </button>

                 </div>
                </>

               <InputTextArea name='notes'   placeholder=' ملاحظات على تقارير الصيانه... '  title='ملاحظات' />
           </CreateForm>
       </>
    )
}


function Weights() {
    const samples = useStore(state => state.samples)
  return (
    <div className="flex items-center border-border border-[1px] w-full h-full overflow-auto hide-scrollbar">
        {
            samples.map((item) => (
                <Weight key={item.id} item={item} />
            ))
        }
    </div>
  )
}
function Weight({ item }) {

    const { id , name } = item
    const value = useStore(state => state.sampleValue(id))
    const setSample = useStore(state => state.setSample)
    const removeSample = useStore(state => state.removeSample)
     
  return (
    <div className=" flex-1 min-w-[160px]  border-border border-l-[1px] w-full h-full relative group">
        <div className="w-full p-2 border-border border-b-[1px] h-fit  items-center flex text-[14px]">
             {name} بالجرام 
        </div>

        <input value={value} type="number" placeholder="150"
            onChange={(e) => setSample({id , weight : +e.target.value , name})}
            className="w-full h-full px-2 m-0  !border-none outline-none focus:border-none bg-transparent"  
        />

        <button type="button" onClick={() => removeSample(id)}
        className="opacity-0 group-hover:!opacity-100  absolute left-1 top-1.5 rounded-sm  text-[18px] hover:bg-red-300 p-1">
            <BiTrashAlt />
        </button>

    </div>
  )
}




const defaultSamples = Array.from({ length: defaultSamplesCount }, (_, i) => i + 1).map((item) => {
    return {weight : '' , name : `وزن الطائر ${item}` , id : id()}
})


const useStore = create((set) => ({
    samples :defaultSamples,
    setSample : (sample) => set(state => {
        const {samples} = state
        const sampleId = sample.id
        const index = samples.findIndex(item => item.id === sampleId)

        if(index > -1){
            samples[index] = sample
        }else{
            samples.push(sample)
        }
        return {samples}
    }),
    sampleValue : (id) => {
        const sample = useStore.getState().samples.find(item => item.id === id)
        if(!sample) return ''
        return sample.weight 
    },

    addSample : () =>{
        const samples = useStore.getState().samples
        const sample = {weight : '' , name : `وزن الطائر ${samples.length + 1}` , id : id()}
        set(state => ({samples : [...state.samples , sample]}))
        
    },
    reset : ()=>{
        set({samples : defaultSamples})
    },

    removeSample : (id) =>{
     const samples = useStore.getState().samples

     if(samples.length > defaultSamplesCount){
        set(state => ({
            samples : state.samples.filter(item => item.id !== id)
        }))
     }else {
        set(state => ({ ...state , samples }))
     }


    },
    formatted : ()=>{
        const samples = useStore.getState().samples
        return samples.map(({id , ...rest}) => rest)
    },
    average : () => {
        const samples = useStore.getState().samples
        const sum = samples.reduce((acc , item) => acc + (+item.weight) , 0)
        return (sum / samples.length).toFixed(2)
    }
}))